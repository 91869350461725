import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class PhysioService {
  getPhysioList() {
    const option = {
      url: `${baseUrl}/physiotherapist`,
    };

    return axios.get(option);
  }

  getCareProviderById(id) {
    const option = {
      url: `${baseUrl}/careprovider/${id}`,
    };

    return axios.get(option);
  }

  updateCare(data) {
    const option = {
      url: `${baseUrl}/careprovider`,
      data,
    };

    return axios.put(option);
  }

  createPhysio(data) {
    const option = {
      url: `${baseUrl}/physiotherapist`,
      data,
    };

    return axios.post(option);
  }

  postPatientLogs(id, data) {
    const option = {
      url: `${baseUrl}/postpatientData/${id}`,
      data,
    };

    return axios.post(option);
  }
}

const physioService = new PhysioService();
export default physioService;
