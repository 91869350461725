import { Grid } from "@mantine/core";
import TableList from "./TableList";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { useContext, useEffect } from "react";
import { routePhysiotherapistDoctorTab } from "../../../constants/constants";
import BreadCamp from "../../../resuable/BreadCamps";

const DoctorOverviewBody = () => {
  const tableDataHeader = ["Sr No.", "Doctor Name", "Email", "Actions"];
  const { setBreadCampList } = useContext(BreadCampContext);

  // const tableRowData = [{}]

  useEffect(() => {
    setBreadCampList([
      { title: "Doctor", route: routePhysiotherapistDoctorTab },
    ]);
  }, []);

  return (
    <Grid>
      <Grid.Col>{/* <BreadCamp /> */}</Grid.Col>
      <Grid.Col>
        <TableList tableHeader={tableDataHeader} />
      </Grid.Col>
    </Grid>
  );
};

export default DoctorOverviewBody;
