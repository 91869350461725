import Recat, { useContext } from 'react';
import RomSession from '../../components/PatientRom/romSession';
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    Grid,
    Space,
    TextInput,
  } from "@mantine/core";
//   import { routePhysioFeaturePatientImageAi } from "../../../../../../constants/constants";
  import { useNavigate, useParams } from "react-router-dom";
  import { useForm } from "@mantine/form";
  import {PatientDailyworkoutContext} from '../../context/PatientDailyWorkoutContext';
  import { StoreRangeOfMotion } from "../../services/pier";


  const PatientRom = () => {
    const {surgerydata}=useContext(PatientDailyworkoutContext)
    const navigate = useNavigate();
    const params = useParams();
    const { doctorId, patientId } = params;
  
     const form = useForm({
      initialValues: {
        rom: "",
      },
      validate: {
        rom: (value) => value ? null : 'Field is required!'
      }
  
    });
  
    const handleClick = async (values) => {
        const apidata = { maxrom: Number(values.rom),patientId:patientId };
        //call the function to send the active motion to the API call
        const apicall = await StoreRangeOfMotion(apidata);
        console.log(apicall);
        if (apicall?.data?.statusCode === 201) {
          //redirect to the guidance page
          navigate(`/${surgerydata[3].route}`);
        } else {
          // show error
        }
      };
    return (
      <Box>
        <Center>
          <Container style={{ display: "block", flexDirection: "row" }}>
          <form onSubmit={form.onSubmit((values) => handleClick(values))}>
            <TextInput
            withAsterisk
            type='number'
              w={"300px"}
              label="Range of motion"
              placeholder=""
              {...form.getInputProps("rom")}
            />
            <Space h={"xl"} />
            <Button
              c={"black"}
              onClick={() =>
                navigate(`/${surgerydata[3].route}`)
              }
            >
              Skip
            </Button>
            &nbsp;&nbsp;
            <Button c={"black"}  type="submit">
              Update
            </Button>
            </form>
          </Container>
        </Center>
      </Box>
    );
  };
  
  export default PatientRom;
  