import Axios from "../../../helper/axiosHelper";
import { BASEURL } from "../../../constants/constants";

const baseUrl = BASEURL;
const axios = new Axios();

class AdminPhysioService {
  createPhysio(formData) {
    console.log(formData);
    const option = {
      url: `${baseUrl}/physiotherapist`,
      data: {
        values: {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          mobile: formData.mobile,
        },
      },
    };

    return axios.post(option);
  }
}

const adminPhysioService = new AdminPhysioService();
export default adminPhysioService;
