import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Text,
  Badge,
  Card,
  Modal,
  Center,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Group,  Title } from "@mantine/core";
import adminService from "../services/adminService";
import { BASEURL } from "../constants/constants";
import loginService from "../services/loginService";

const ExerciseList = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  const [configData, setConfig] = useState([]);
 
  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    try {
      let configData = await adminService.getExercise();
      console.log("get exercise callled config", configData.data);
      setConfig(configData.data);
    } catch (err) {
      console.log(err);
      if (err?.response?.status && err?.response?.status === 401) {
        const path = loginService.checkRoute();
        navigate(path, { replace: true });
      }
    }
  };
  const rows = configData.map((exercise, idx) => (

    <Center key={exercise.exerciseName}>
      <Card
        p={10}
        m={10}
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{ width: "39%", backgroundColor: "#011D2c" }}
      >
        <Card.Section>
          <Image
            src={`${BASEURL}/showimage/images/${exercise?.exerciseImagePath}`}
            height={280}
            alt={exercise.exerciseName}
          />
           {/* <video src={`${BASEURL}/showimage/videos/${exercise?.exerciseVidepPath}`} height={220} alt="hi" controls /> */}
        </Card.Section>
        <Modal opened={opened} onClose={close} title={exercise.exerciseName} centered>
          <video src={`${BASEURL}/showimage/videos/${exercise?.exerciseVideoPath}`} height={220} alt="hi" controls />
        </Modal>
        <Group justify="space-between" mt="md" mb="xs">
          <Text color="#fff" fw={500}>
            {exercise.exerciseName}
          </Text>
          <Badge color="pink">New</Badge>
        </Group>

        <Text size="sm" c="dimmed">
          Keep Doing this exercise to increase range of motion , felxiblity and
          to reduce pain
        </Text>

        <Button onClick={open} color="blue" fullWidth mt="md" radius="md">
          Click to see Demonstration
        </Button>
      </Card>
    </Center>
  ));

  return (
    <>
      <Helmet>
        <title  > Exercises | Truss Health </title>
      </Helmet>
      <Center>
        {" "}
        <Title order={3}  style={{fontFamily:'monospace'}} >List Of All Exercises</Title>
      </Center>

      <Card style={{ backgroundColor: "#b8c3d4" }}>{rows}</Card>
    </>
  );
};

export default ExerciseList;
