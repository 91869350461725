import React from 'react';


import { Title, Text, Button, Container, Group, Center } from '@mantine/core';
import {IconHourglassEmpty} from '@tabler/icons-react';
import classes from './NotFoundTitle.module.scss';

 function NotFoundComp() {
  return (
    <Container className={classes.root}>
     <Center display='block'>
     <div className={classes.label}><IconHourglassEmpty size={60} color='red' /></div>
      <Title className={classes.title}>Patient Data Not Found!</Title>
     </Center>
      {/* <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has
        been moved to another URL.
      </Text> */}
      {/* <Group justify="center">
        <Button variant="subtle" size="md">
          Take me back to home page
        </Button>
      </Group> */}
    </Container>
  );
}

export default NotFoundComp;