
import { useContext, useEffect, useState } from 'react';
import {
    IconUserCheck,
    IconMailOpened,
    IconShieldCheck,
    IconCircleCheck,
} from '@tabler/icons-react';
import { Stepper, rem } from '@mantine/core';
import { useLocation, useParams } from 'react-router-dom';
import {PatientDailyworkoutContext} from '../../context/PatientDailyWorkoutContext';
function Steper() {
    const { steperActive, setSteperActive, surgerydata, setSurgerydata } = useContext(PatientDailyworkoutContext)
    // console.log(surgerydata)
    const params=useParams()
    const {type}=params
    const location = useLocation()
    useEffect(() => {
        // console.log(location)
        if(surgerydata.length>0){
            console.log(surgerydata)
            const filterdata = surgerydata.filter((el) => {
                    return `${type}` === el.stepname
            })
            console.log(filterdata)
            setSteperActive(filterdata[0].step)
        }
        
    }, [surgerydata.length>0])
    useEffect(() => {
        // console.log(location)
        // console.log(surgerydata)
        // const filterdata = surgerydata.filter((el) => {
        //     if (location.pathname === "/patientDashboard/romSession") {
        //         return el.route === surgerydata[2].route
        //     }
        //     else {
        //         return el.route === location.pathname

        //     }
        // })

        // // console.log(filterdata)
        // setSteperActive(filterdata[0].step)
        if(surgerydata.length>0){
            console.log(surgerydata)
            const filterdata = surgerydata.filter((el) => {
                    return `${type}` === el.stepname
            })
            console.log(filterdata)
            setSteperActive(filterdata[0].step)
        }
    }, [location])
    return (
      <>
        {surgerydata.length>0 ?
        <Stepper w={{ xs: "100%", sm: "100%", lg: "80%" }} m='auto'
            //  iconSize={32}
            // size={{xs:'xs',sm:"sm",lg:"xl"}}
            active={steperActive}
            //   onStepClick={setActive}
            completedIcon={<IconCircleCheck
            // style={{ width: rem(18), height: rem(18) }} 
            />
            }
            styles={{
                stepBody: {
                    display: 'none',
                }


                // step:{
                //     display:'block'
                // }

            }}
        >
            {
                surgerydata.map((item, index) => {
                    return (
                        <Stepper.Step
                            icon={<item.icon
                            // style={{ width: rem(18), height: rem(18) }}
                            />}
                            label={`Step ${index + 1}`}
                            description={item.title}
                        >
                            {/* {item.cmp} */}
                        </Stepper.Step>
                    )
                })
            }

            {/* <Stepper.Step
        icon={<IconMailOpened style={{ width: rem(18), height: rem(18) }} />}
        label="Step 2"
        description="Verify email"
      />
      <Stepper.Step
        icon={<IconShieldCheck style={{ width: rem(18), height: rem(18) }} />}
        label="Step 3"
        description="Get full access"
      /> */}
        </Stepper >
        :""}
      </>
    );
}
export default Steper;