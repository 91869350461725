import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextInput,
  Button,
  Skeleton,
  Table,
  Card,
} from "@mantine/core";
import { Group, Space, Stack, Title } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react"; // Importing icons for edit and delete
import { Pagination } from "@mantine/core";
import { filter } from "lodash";
import providerService from "../services/providerService";
import loginService from "../services/loginService";
import { ActionIcon } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconPencil, IconHttpDelete } from "@tabler/icons-react";
const ProviderPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useDebouncedState("", 150);
  // const [page, setPage] = useState(0);
  // const [selected, setSelected] = useState([]);
  // const [order, setOrder] = useState("asc");
  // const [orderBy, setOrderBy] = useState("id");
  // const [filterName, setFilterName] = useState("");
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  const filterData = () => {
    const filteredData = filter(configData, (p) => {
      return p.name.includes(searchQuery) || p.mobile.includes(searchQuery);
    });
    setConfig(filteredData);
  };
  const handleEdit = (e, provider) => {
    try {
      navigate("/providers/edit", {
        state: {
          provider,
        },
        replace: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const addNewConfig = () => {
    navigate("/providers/add", { replace: true });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const [configData, setConfig] = useState([]);
  useEffect(() => {filterData();
  }, [searchQuery]);
  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = async () => {
    try {
      let configData = await providerService.getProviders();
      setConfig(configData.data);
    } catch (err) {
      if (err?.response?.status && err?.response?.status === 401) {
        const path = loginService.checkRoute();
        navigate(path, { replace: true });
      }
    }
  };
  const disableUser = async (e, provider) => {
    try {
      const disableResponse = await providerService.updateProvider(
        provider._id,
        { inActive: true }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const rows = configData.map((provider, index) => (
    <Table.Tr  key={index + 1}>
      <Table.Td c={'black'}   align="left">{index + 1}</Table.Td>
      <Table.Td c={'black'}   >{provider.name}</Table.Td>
      <Table.Td c={'black'}   >{provider.email}</Table.Td>
      <Table.Td>
        <Group>
          <ActionIcon
            id={provider.id}
            onClick={(e) => handleEdit(e, provider)}
            variant="filled"
            aria-label="Settings"
          >
            <IconPencil style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            onClick={(e) => disableUser(e, provider)}
            style={{ backgroundColor: "pink" }}
            variant="filled"
            aria-label="Settings"
            id="1"
          >
            <IconPencil style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            style={{ backgroundColor: "red" }}
            variant="filled"
            aria-label="Settings"
            id="1"
          >
            <IconHttpDelete
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Helmet>
        <title> Providers | Truss Health </title>
      </Helmet>
      <Container>
        <Group justify="flex-end" gap="xl">
          <Title order={3}>Providers</Title>
          <Space w="xl" />
          <Button variant="default" onClick={addNewConfig}>
            Add Providers
          </Button>
        </Group>
      </Container>

      <Group>
        <TextInput
          placeholder="Search...."
          value={searchQuery}
          style={{ margin: "40px 10px" }}
          leftSection={<IconSearch size={16} />}
          onChange={handleSearchChange}
        />
      </Group>
      <Stack>
        <Card style={{ maxWidth: "2090px" }}>
          {" "}
          {/* Set maxWidth to null */}
          <Table fullWidth  >
            <Table.Thead  >
              <Table.Tr>
                <Table.Th>Sr. No.</Table.Th>
                <Table.Th>Provider's Name</Table.Th>
                <Table.Th>Email Address</Table.Th>
                <Table.Th>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody  >
              {rows}
              {configData.length === 0 && (
                <tr>
                  <td colSpan="4">
                    <Skeleton height={50} circle mb="xl" />
                    <Skeleton height={8} radius="xl" />
                    <Skeleton height={8} mt={6} radius="xl" />
                    <Skeleton height={8} mt={6} width="70%" radius="xl" />
                  </td>
                </tr>
              )}
            </Table.Tbody>
          </Table>
        </Card>

        <Pagination total={5} />
      </Stack>
    </>
  );
};

export default ProviderPage;
