

// const PatientSingleSessiondata =() =>{
//     return (
//         <>hfiohfioer</>
//     )
// }
import { useState, useEffect } from 'react';
import { Group, Container, Button, Image, Title, Paper, Text, ThemeIcon, SimpleGrid, Space } from '@mantine/core';
import { IconArrowUpRight, IconArrowDownRight } from '@tabler/icons-react';
import classes from './StatsGridIcons.module.css';
import { useNavigate } from 'react-router-dom';
import PatientDataFetch from '../Hooks/patientDataFetch';
import LoaderPage from '../../../resuable/loder';
import dailyReportService from '../../../services/dailyReportService';
import HookSteper from '../Hooks/HookSteper';
import PatientDetailDashboardolds from '../PatientDetailOverview/PatientDetailOverviewnamw';
export function PatientSingleSessiondata(props) {
    const navigate = useNavigate();
    const { postSurgery } = HookSteper()
    const { loading, list, graphResult, saveonseSessiondata } = PatientDataFetch();
    const data = [
        { title: 'Pain Score', value: `${Number(saveonseSessiondata.painscore).toFixed(2)}`, diff: 34 },
        { title: 'Range Of Motion', value: saveonseSessiondata.rom, diff: -13 },
        { title: 'Risk Level', value: `${Number(saveonseSessiondata.risklevel).toFixed(2)} %`, diff: 18 },
    ];
    const stats = data.map((stat) => {
        const DiffIcon = Number(stat.value) > 40 ? IconArrowUpRight : IconArrowDownRight;

        return (
            <Paper withBorder p="md" radius="md" key={stat.title}>
                <Group justify="apart">
                    <div>
                        <Text
                            variant="gradient"
                            gradient={{ from: 'cyan', to: 'indigo', deg: 160 }}
                            tt="uppercase" fw={700} fz="xs" className={classes.label}>
                            {stat.title}
                        </Text>
                        <Text fw={700} fz="xl">

                            {stat.value}
                        </Text>
                    </div>
                    {/* <ThemeIcon
                        color="gray"
                        variant="light"
                        style={{
                            color: Number(stat.value) > 40 ?  'var(--mantine-color-red-6)': 'var(--mantine-color-teal-6)',
                        }}
                        size={38}
                        radius="md"
                    >
                        {Number(stat.diff) > 0 ?  <IconArrowUpRight size="1.8rem" stroke={1.5} />: <IconArrowDownRight size="1.8rem" stroke={1.5} /> }
                    </ThemeIcon> */}
                </Group>
                <Text c="dimmed" fz="sm" mt="md">
                    <Text component="span" c={stat.diff > 0 ? 'teal' : 'red'} fw={700}>
                        {stat.diff}%
                    </Text>{' '}
                    {stat.diff > 0 ? 'increase' : 'decrease'} compared to last month
                </Text>
            </Paper>
        );
    });
    console.log(saveonseSessiondata)
    // const staimagfe = data.map((stat) => {
    //     const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    //     return (
    //         <Paper withBorder p={1} radius="md" key={stat.title}>
    //             {/* <ShowImage topAngleImage={stat.topAngleImage} /> */}

    //         </Paper>

    //     );
    // });

    return (
        <>
            <Container fluid={true}>
                <Group mb={10} justify='center' >
                    {!props.gobutton ?
                        <Button className={classes.nextclick} radius="md"
                            variant="gradient"
                            gradient={{ from: 'cyan', to: 'violet', deg: 90 }}
                            fz={18}
                            onClick={() => { navigate(postSurgery[0].route) }}
                        >
                            Health assessment
                        </Button>
                        :
                        ""
                        // <Button className={classes.nextclick} radius="md"
                        //     variant="gradient"
                        //     gradient={{ from: 'cyan', to: 'violet', deg: 90 }}
                        //     fz={16}
                        //     onClick={() => { navigate('/patientDashboard/12345') }}
                        // >
                        //     Go on DashBoard
                        // </Button>
                    }
                </Group>
                <div style={{ position: 'relative', top: '-80px' }}>

                    <PatientDetailDashboardolds />
                </div>
            </Container>
            {/* {!loading ?
                <div className={classes.root}>
                    <Group mb={10} justify='right' >
                        {!props.gobutton?
                        <Button className={classes.nextclick} radius="md"
                            variant="gradient"
                            gradient={{ from: 'cyan', to: 'violet', deg: 90 }}
                            fz={18}
                            onClick={() => { navigate(postSurgery[0].route) }}
                        >
                            Start
                        </Button>
                        :
                        <Button className={classes.nextclick} radius="md"
                        variant="gradient"
                        gradient={{ from: 'cyan', to: 'violet', deg: 90 }}
                        fz={16}
                        onClick={() => { navigate('/patientDashboard/12345') }}
                    >
                        Go on DashBoard
                    </Button>
                    }
                    </Group>
                    <SimpleGrid cols={{ base: 1, sm: 3, md: 3, lg: 3 }} justify='right'>{stats}</SimpleGrid>
                    <>
                        {saveonseSessiondata.imageai.length > 0 ?
                            <>
                                <Text size='xl' fw={500} order={2} mt={5} mb={5} variant="gradient"
                                    gradient={{ from: 'cyan', to: 'indigo', deg: 160 }}>Image AI: </Text>
                                <SimpleGrid cols={{ base: 1, sm: 3, md: 3, lg: 5 }}>
                                  {saveonseSessiondata.imageai.map((ex,index)=>{
                                    return (
                                        <Paper withBorder p={1} radius="md" key={index}>
                                        <ShowImage topAngleImage={ex.topAngleImage} />

                                    </Paper>
                                    )
                                  })}
                                 

                                </SimpleGrid>
                            </>
                            : ""}
                    </>


                   


                </div>
                :
                <LoaderPage />

            } */}
        </>
    );
}
const ShowImage = (props) => {
    console.log(props.topAngleImage)
    const [imageUrl, setImageurl] = useState()
    const fetchsignedurl = async (data) => {
        const apicall = await dailyReportService.getSignedUrl({ imageurl: data });
        console.log("apicall 2")

        console.log(apicall)
        if (apicall.status === 200) {
            console.log(apicall)
            // imagestick=apicall.data.url
            if (apicall.data.url) {
                setImageurl(apicall.data.url);

            }
            else {

            }
        } else {
        }
    };
    useEffect(() => {
        console.log("apicall")
        // if (props.topAngleImage) {
        fetchsignedurl(props.topAngleImage)
        // }
    }, [])
    return (
        <>
            <Image src={imageUrl} fallbackSrc="https://placehold.co/600x400?text=Image not found" />
        </>
    )
}

export default PatientSingleSessiondata;

