import { useContext, useEffect, useState } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  keys,
  Grid,
  Button,
} from "@mantine/core";
import {
  IconSignLeft,
  IconCirclePlus,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconPencil,
  IconTrash,
  IconEye,
} from "@tabler/icons-react";

import ExerciseService from "../../../../services/exercise";
import ExerciseTable from "./List/ExerciseTable";

import { coordinatorExerciseDetail } from "../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { navbarContext } from "../../Context/CoordinatorDashboard";
import BreadCamp from "../../../../resuable/BreadCamps";
import { BreadCampContext } from "../../../../context/BreadCampContext";

function SettingExerciseCoordinator() {
  const navigate = useNavigate();
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "Exercise", href: "#" },
    { title: "Exercise Detail", href: "#" },
  ]);
  const { setBreadCampList } = useContext(BreadCampContext);

  const showexerciseclick = (data) => {
    console.log(data);
    navigate(`${coordinatorExerciseDetail}/${data.id}`);
  };

  const FetchPatientList = async () => {
    const apiacll = await ExerciseService.getExercisesList();

    if (apiacll.statusCode === 200) {
      console.log(apiacll);
      const setdata = apiacll.data;
      const filterkeydata = [];
      setdata.forEach((element) => {
        filterkeydata.push({
          id: element._id,
          name: element.exerciseName,
          createDate: element.createdAt,
          status: element.inActive,
          global: element.globalstatus,
        });
      });
      console.log(filterkeydata);
      setList(filterkeydata);
      setSortedData(filterkeydata);
      const rowdata = filterkeydata.map((row, index) => (
        <Table.Tr key={index}>
          <Table.Td>{index + 1}</Table.Td>
          <Table.Td>{row.name}</Table.Td>
          <Table.Td>{row.createDate}</Table.Td>
          <Table.Td>{row.status ? "Active" : "Inactive"}</Table.Td>
          <Table.Td>{row.global}</Table.Td>
          <Table.Td>
            <Group align="">
              {/* <IconPencil size={18} color='#7400b6' /> */}
              <IconEye
                onClick={() => showexerciseclick(row)}
                size={18}
                color="#90EE90"
              />
            </Group>
          </Table.Td>
        </Table.Tr>
      ));
      setRows(rowdata);
    }
  };
  useEffect(() => {
    setBreadCampList([
      {
        title: "Exercise",
        route: "/CoordinatorDashboard/exercise/setting",
      },
    ]);
    setNavActive("Setting Exercise");
    FetchPatientList();
  }, []);
  useEffect(() => {
    const rowdata = sortedData.map((row, index) => (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}</Table.Td>
        <Table.Td>{row.name}</Table.Td>
        <Table.Td>{row.createDate}</Table.Td>
        <Table.Td>{row.status ? "Inactive" : "Active"}</Table.Td>
        <Table.Td>{row.global ? "Yes" : "No"}</Table.Td>
        <Table.Td>
          <Group align="">
            {/* <IconPencil size={18} color='#7400b6' /> */}
            <IconEye
              onClick={() => showexerciseclick(row)}
              size={18}
              color="#90EE90"
            />
          </Group>
        </Table.Td>
      </Table.Tr>
    ));
    setRows(rowdata);
  }, [sortedData]);

  return (
    <Grid>
      <Grid.Col>
        <Group justify="space-between">
          <BreadCamp data={breadcampitems} />

          {/* <Group>
                  <Button fw={600} leftSection={<IconCirclePlus size={20} />} variant="default">
                        Create Exersise
                    </Button>
                    <Button fw={600} leftSection={<IconSignLeft size={20} />} variant="default">
                        Assign Exersise
                    </Button>
                  </Group> */}
        </Group>
      </Grid.Col>
      <Grid.Col>
        <ExerciseTable
          list={list}
          rows={rows}
          sortedData={sortedData}
          setSortedData={setSortedData}
        />
      </Grid.Col>
    </Grid>
  );
}
export default SettingExerciseCoordinator;
