import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Grid, Group, Button } from '@mantine/core';
// import Guidance from "./GuidanceMain";
import PatientDailyworkoutProvider from '../../context/PatientDailyWorkoutContext';
import BreadCamp from "../../resuable/BreadCamps";
import { BreadCampContext } from "../../context/BreadCampContext";
import Steper from './Steper';
const PatientDailyworkout = () => {
    const params = useParams()
    const location =useLocation()
    const navigate=useNavigate()
    const { setBreadCampList } = useContext(BreadCampContext);

    const GotoDashboarduser=()=>{
        navigate(`/physiotherapist-dashboard/doctor-list`)
    }
    useEffect(() => {
        setBreadCampList([
            {
                title: `Dashboard`,
                route: `/physiotherapist-dashboard/doctor-list`,
            },
            {
                title: params.type,
                route: '',
          
            },
        ]);
    }, []);
    useEffect(() => {
        setBreadCampList([
            {
                title: `Dashboard`,
                route: `/physiotherapist-dashboard/doctor-list`,
            },
            {
                title: params.type,
                route: '',
            },
        ]);
    }, [location])

    return (
        <PatientDailyworkoutProvider>
            <Container fluid={true} p={0} >
                <Grid p={0}
                style={{position: "fixed", width: '100%', top: '0px', zIndex: '9999',background:'#01526a'}}
                >
                    <Grid.Col p={10} mt={'1%'} mb={'1%'} 
                    // style={{zIndex:"",position:'fixed',top:'2%',width:'100%', borderBottom: '1px solid lightgrey' }}
                    
                    >
                        <Group display='flex' justify="space-between">
                            <BreadCamp />
                            {/* <Button c='black'>Logout</Button> */}
                        </Group>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col style={{marginTop:'8%'}}>
                        <Steper />
                        <Outlet />
                    </Grid.Col>
                </Grid>
            </Container>
        </PatientDailyworkoutProvider>
    );
};

export default PatientDailyworkout;
