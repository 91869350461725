import { useEffect, useState,useContext } from "react";
import {
    Table,Button,
    Group,
    Grid,
Tooltip
} from "@mantine/core";
import {
    IconEye,IconCirclePlus ,IconPencil,
} from "@tabler/icons-react";
import AdminTable from "./AdminTable";
import BreadCamp from "../../../resuable/BreadCamps";
import {useNavigate } from "react-router-dom";
import {navbarContext} from '../Context/AdminDashboard';
import {BreadCampContext} from '../../../context/BreadCampContext';
import physioService from "../../../services/physioService";
import AddAdmin from "../../AddAdmin";
import PhysioTable from "./PhysioTable";

function PhysioList(props) {
    const { setBreadCampList} = useContext(BreadCampContext);
    const {setNavActive}=useContext(navbarContext)
    const [sortedData, setSortedData] = useState([]);
    const [list, setList] = useState([]);
    const [rows, setRows] = useState([]);
    const [patientListshow, setPatientListshow] = useState(true);
    const [createpatientshow, setCreatepatientshow] = useState(false);
    const navigate = useNavigate();
    const [breadcampitems, setBreadcampitems] = useState([
        { title: "Admin List", href: "#" },
    ]);
    
    const FetchPhysios = async () => {
        const apiacll = await physioService.getPhysioList();

        if (apiacll.statusCode === 200) {
            console.log(apiacll);
            const setdata = apiacll.data;
            const filterkeydata = [];
            setdata.forEach((element) => {
                filterkeydata.push({
                    name: element.name,
                    email: element.email,
                    mobile: element.mobile,
                    id: element._id
                });
            });
            console.log(filterkeydata);
            setList(filterkeydata);
            setSortedData(filterkeydata);
            const rowdata = filterkeydata.map((row, index) => (
                <Table.Tr key={index}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>{row.name}</Table.Td>
                    <Table.Td>{row.email}</Table.Td>
                    <Table.Td>{row.mobile}</Table.Td>
                    <Table.Td>
                        <Group align="">
                            <Tooltip label='Assign Exercise'><IconPencil size={18} color="#90EE90"  /></Tooltip>

                        </Group>
                    </Table.Td>
                </Table.Tr>
            ));
            setRows(rowdata);
        }
    };
    useEffect(() => {
        setBreadCampList([
            { title: "Physiotherapists List", href: "#" },
        ])
        setNavActive('Admins')
        FetchPhysios();
    }, []);
    const handleEdit = (e, provider) => {
        try {
          navigate("/admindashboard/physiotherapist/edit", {
            state: {
              provider,
            },
            replace: true,
          });
        } catch (err) {
          console.log(err);
        }
      };
    useEffect(() => {
        const rowdata = sortedData.map((row, index) => (
            <Table.Tr key={index}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td>{row.email}</Table.Td>
                <Table.Td>{row.mobile}</Table.Td>
                <Table.Td>
                    <Group align="">
                        <Tooltip label='Edit Physiotherapist Detail'><IconPencil onClick={(e) => handleEdit(e, row)} size={18} color="#90EE90" /></Tooltip>
                       

                    </Group>
                </Table.Td>
            </Table.Tr>
        ));
        setRows(rowdata);
    }, [sortedData]);
    const ClickCreatePhysio = () => {
        navigate('/admindashboard/physiotherapist/add')
        setBreadcampitems([
          { title: "DashBoard", href: "#" },
          { title: "Patient List", href: "#" },
          { title: "Create Patient", href: "#" },
        ]);
        setCreatepatientshow(true);
        setPatientListshow(false);
    };
    return (
        <Grid>
            <Grid.Col>
                <Group style={{ justifyContent: "space-between" }}>
                    <BreadCamp data={breadcampitems} />
                    
                        <Button
                            onClick={ClickCreatePhysio}
                            fw={600}
                            leftSection={<IconCirclePlus size={20} />}
                            variant="default"
                        >
                            Create Physiotherapist
                        </Button>
                </Group>
            </Grid.Col>
            <Grid.Col>
                {patientListshow === true ? (
                    <PhysioTable
                        list={list}
                        rows={rows}
                        sortedData={sortedData}
                        setSortedData={setSortedData}
                    />
                ) : (
                    ""
                )}
                {createpatientshow === true ? <AddAdmin /> : ""}
            </Grid.Col>
        </Grid>
    );
}
export default PhysioList;
