import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class ExerciseService {


    getExercisesList() {
        const option = {
          url: `${baseUrl}/exercise`,
        };
    
        return axios.get(option);
      }
    
      createExercises(data) {
        console.log("data is ", data);
        const option = {
          url: `${baseUrl}/exercise`,
          data,
        };
    
        return axios.post(option);
      }

      getExercisesGlobalList() {
        const option = {
          url: `${baseUrl}/exercise/global`,
        };
    
        return axios.get(option);
      }
      getExerciseDetail(id){
        const option = {
          url: `${baseUrl}/exercise/${id}`,
        };
    
        return axios.get(option);
      }
}

const exerciseservice = new ExerciseService();
export default exerciseservice;