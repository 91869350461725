import React from 'react';

import { useEffect, useState } from 'react';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text, Skeleton,
    Center,
    TextInput,
    rem,
    keys, Grid, Button, Flex
} from '@mantine/core';
import NotFoundComp from '../../../resuable/NotFoundComp';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconPencil, IconTrash, IconEye } from '@tabler/icons-react';
import classes from '../../../styles/TableSort.module.css';

function Th({ children, reversed, sorted, onSort, head }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={`${classes.th} ${head}`}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}
const TableData = ({ list, rows, sortedData, setSortedData }) => {
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(list, { sortBy: field, reversed, search }));
    };

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(list, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    return (
        <ScrollArea>
            <Table className='doctorOverview_table' horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed"
            //  withTableBorder  withColumnBorders
            >
                <Table.Tbody>
                    <Table.Tr>
                        <Th head='th1' style={{ width: '10%' }}
                        // sorted={sortBy === 'name'}
                        // reversed={reverseSortDirection}
                        // onSort={() => setSorting('name')}
                        >
                            Sr. No
                        </Th>
                        <Th head='th2'
                        // sorted={sortBy === 'name'}
                        // reversed={reverseSortDirection}
                        // onSort={() => setSorting('name')}
                        >
                            Patient's Name
                        </Th>
                        <Th head='th3'
                            sorted={sortBy === 'name'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('name')}
                        >
                            Date of Surgery
                        </Th>
                        <Th head='th4'
                        // sorted={sortBy === 'email'}
                        // reversed={reverseSortDirection}
                        // onSort={() => setSorting('email')}
                        >
                            Risk Level
                        </Th>
                        <Th head='th5'
                        //   sorted={sortBy === 'company'}
                        //   reversed={reverseSortDirection}
                        //   onSort={() => setSorting('company')}
                        >
                            Action
                        </Th>
                    </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>
                    {sortedData.length > 0 ? (
                        rows
                    ) : (
                        <Table.Tr>
                            <Table.Td colSpan={5}>
                                <Text fz={30} c={'red'} p={30} fw={500} ta="center">
                                  Data Not found!
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
        </ScrollArea>
    )
}

export default TableData;