import React from "react";
import GuidanceSteper from './GuidanceSteper';
const Guidance = () => {
  return (
    <>
      <GuidanceSteper />
    </>
  );
};

export default Guidance;