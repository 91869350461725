import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { Container, Input, Skeleton,Button, TextInput ,Table, Card ,keys,  Center,  UnstyledButton,    rem,Text ,ScrollArea} from '@mantine/core';
import { Group, Space,Stack , Title } from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconPencil, IconTrash, IconEye } from '@tabler/icons-react';
import { Pagination } from '@mantine/core';
import classes from '../../../styles/TableSort.module.css';
import adminService from "../../../services/adminService";
import { filter } from "lodash";
import loginService from "../../../services/loginService"
import { ActionIcon } from '@mantine/core';
import PropTypes from 'prop-types';


import {   IconHttpDelete  } from '@tabler/icons-react';



function Th({ children, reversed, sorted, onSort, head }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={`${classes.th} ${head}`}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}
const PhysioTable = ({list,rows,sortedData,setSortedData}) => {
  const navigate = useNavigate();

  
  
  const addNewConfig = () => {
    navigate("/admindashboard/physio/add", { replace: true });
  };
  const [configData, setConfig] = useState([]);
  
  
  useEffect(() => {
    getAdmins();
  }, []);
 


  const getAdmins = async () => {
    try {
      let configData = await adminService.getAdmins();
      setConfig(configData.data);
    } catch (err) {
      console.log(err);
      if (err?.response?.status && err?.response?.status === 401) {
        const path = loginService.checkRoute();
        navigate(path, { replace: true });
      }
    }
  };

  const disableUser = async(e,provider) => {
    try {
      const disableResponse = await adminService.deleteAdmin(provider._id);
    }
    catch(e)
    {
        console.log(e);
    }
  }

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const setSorting = (field) => {
      const reversed = field === sortBy ? !reverseSortDirection : false;
      setReverseSortDirection(reversed);
      setSortBy(field);
      setSortedData(sortData(list, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event) => {
      const { value } = event.currentTarget;
      setSearch(value);
      setSortedData(sortData(list, { sortBy, reversed: reverseSortDirection, search: value }));
  };



  return (
    <>
     <Helmet>
        <title> Physiotherapists | Truss Health </title>
      </Helmet>
      <ScrollArea>
        <TextInput
            placeholder="Search by any field"
            mb="md"
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
            style={{ width: '30%' }}
        />
        <Table className='cc_table' horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
            <Table.Tbody>
                <Table.Tr>
                    <Th head='th1' >
                        Sr. No
                    </Th>
                    <Th  head='th2'
                        sorted={sortBy === 'name'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('name')}
                    >
Physiotherapists Name
                    </Th>
                    <Th head='th3' >
                        Email Address
                    </Th>
                    <Th  head='th6' >
                        Contact Number
                    </Th>
                    <Th head='th1' >
                        Actions
                    </Th>
                </Table.Tr>
            </Table.Tbody>
            <Table.Tbody>
                {sortedData.length > 0 ? (
                    rows
                ) : (
                    <Table.Tr>
                        <Table.Td colSpan={sortedData.length}>
                            <Text fw={500} td="center">
                                Nothing found
                            </Text>
                        </Table.Td>
                    </Table.Tr>
                )}
            </Table.Tbody>
        </Table>
    </ScrollArea>
    </>
  );
};
PhysioTable.propTypes = {
   
    list:PropTypes.any,rows: PropTypes.any,
    sortedData:PropTypes.any,setSortedData: PropTypes.any
  };
export default PhysioTable;
