import { useContext, useEffect, useState } from 'react';
import patientService from '../../../services/patientService';
import { PatientSteperContext } from '../../../context/PatientActionSteperContext';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
const VerifyPatientInitialType = (postSurgery) => {
    const navigate = useNavigate()
    const { surgerydata, setSurgerydata } = useContext(PatientSteperContext);
    const [loading, setLoading] = useState(true)
    const fetchtype = async () => {
        const responsep = await patientService.getQuestionListbyPatientId()
        console.log(responsep)
        if (responsep.statusCode === 200 && responsep.data) {
            
            const dateOfSurgery = responsep.data.assignquestion[0]?.answer;
            const currentdate = moment()
            const surgeryDate = moment(dateOfSurgery);
            const day = surgeryDate.diff(currentdate, "days");
            const condition =
                day > 0 ? "preSurgery" : "postSurgery";
            if (day === 0) {
                console.log("status 0")
                // setTypeSurgery('dayBeforeSurgery')
                setSurgerydata(dayBeforeSurgery)
                // same day surgery condition
            }
            else if (day > 0) {
                console.log("status 200 preSurgery")
                // post surgery condition

                // setTypeSurgery('preSurgery')
                setSurgerydata(preSurgery)
            }
            else if (day < 0) {
                console.log("status 0 postSurgery")
                // pre surgery condition
                // setTypeSurgery('postSurgery')
                if (surgerydata.length > 0) {
                    setLoading(false)
                }
                else {
                    console.log('update')
                    setSurgerydata(postSurgery)
                    setLoading(false)
                }
                if (location.pathname === '/patientDashboard/last-data') {
                    navigate('/patientDashboard/last-data')
                }
                else {
                    navigate(location.pathname)
                }

            }
        }
        else {
            console.log("patient - form")
            setLoading(false)
            navigate("/patientDashboard/patient-form")
        }
    }
    useEffect(()=>{
        let active=0
        if(active===0){
            fetchtype()
            active=1
        }
        else{

        }
    },[])

    return { loading }
}

export default VerifyPatientInitialType;