import { Container, Grid, Text, Button } from "@mantine/core";
import {useContext} from 'react';
import { useNavigate } from "react-router-dom";
import {PatientDailyworkoutContext} from '../../context/PatientDailyWorkoutContext';
const PatientResult = () => {
  const { surgerydata } = useContext(PatientDailyworkoutContext)
  const navigate = useNavigate();
  return (
    <Container>
      <Grid>
        <Grid.Col mt={"5%"} align="center" style={{ margin: "auto" }}>
          <Text c={"#ffff"}>Thanks Your Step Complete!</Text>
          <Button
            mt={"3%"}
            c={"black"}
            onClick={() => {
              navigate(`/${surgerydata[0].route}`);
            }}
          >
            Go on HomePage
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
export default PatientResult;
