import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { DatePicker } from "@mantine/dates";
import PropTypes from "prop-types";
const CalendarInput = ({ handleChange }) => {
  const [value, setValue] = useState(new Date());
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!firstUpdate.current) {
      handleChange(dayjs(value).format("YYYY-MM-DD"));
    } else {
      firstUpdate.current = false;
    }
  }, [value, handleChange]);

  return (
    <div className="" style={{background:'#ffff',borderRadius:'10px',margin:'',maxWidth:'260px'}}>
      <DatePicker  value={value} onChange={(date) =>setValue(date || new Date())} />
    </div>
  );
};
CalendarInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
};
export default CalendarInput;
