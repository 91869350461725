import { Container, Grid, Text, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import PatientSingleSessiondata from './PatientSingleSessiondata/index';
const PatientResult = () => {
  const navigate = useNavigate();
  return (
    <Container fluid={true}>
      {/* <Grid>
        <Grid.Col mt={"5%"} align="center" style={{ margin: "auto" }}>
          <Text c={"#ffff"}>Thanks Your Step Complete!</Text>
          <Button
            mt={"3%"}
            c={"black"}
            onClick={() => {
              navigate("/patientDashboard/pain-data");
            }}
          >
            Go on HomePage
          </Button>
        </Grid.Col>
      </Grid> */}
      <PatientSingleSessiondata gobutton={true} />
    </Container>
  );
};
export default PatientResult;
