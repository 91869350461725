import React, { useEffect, useState, useContext } from "react";
import BreadCamp from "../../../../resuable/BreadCamps";
import {
  Button,
  Center,
  Container,
  Grid,
  Space,
  TextInput,
  Group,
  Loader,
  MultiSelect,
  Box,
} from "@mantine/core";
import PatientService from "../../../../services/patientService";
import ExerciseService from "../../../../services/exercise";
import { useParams } from "react-router-dom";
import {
  useForm,
  isNotEmpty,
  isEmail,
  isInRange,
  hasLength,
  matches,
} from "@mantine/form";
import { navbarContext } from "../../Context/CoordinatorDashboard";

const PatientAssignExercise = () => {
  console.log("Assign patient exercise");
  const params = useParams();
  const { setNavActive } = useContext(navbarContext);

  const [loading, setLoading] = useState(false);
  const [exercisedata, setExercisedata] = useState();
  const [exerciseallList, setExerciseList] = useState([]);
  const [defaultvalue, setDefaultvalue] = useState([]);
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "Patient List", href: "#" },
    { title: "Patient Exercise", href: "#" },
  ]);
  const form = useForm({
    initialValues: {
      exerciselist: [],
    },
    // validate: {
    //     name: hasLength({ min: 2, max: 10 }),
    //     email: isEmail(),
    //     // favoriteColor: matches(/^#([0-9a-f]{3}){1,2}$/),
    //     // password: isInRange({ min: 18, max: 99 }),
    //   },
  });
  const fetchallexercise = async () => {
    const apicall = await ExerciseService.getExercisesList();
    if (apicall.statusCode === 200) {
      console.log(apicall);
      const setdata = apicall.data;
      const filterkeydata = [];
      setdata.forEach((element) => {
        if (element.globalstatus === false) {
          filterkeydata.push({
            label: element.exerciseName,
            value: element._id,
          });
        }
      });
      setExerciseList(filterkeydata);
      console.log(filterkeydata);
    }
  };
  const PatientExercise = async () => {
    const apicall = await PatientService.getExerciseByPatient(params.patientid);
    console.log(apicall);
    if (apicall.statusCode === 200) {
      if (apicall?.data?.length > 0) {
        const valuearray = [];
        apicall.data.forEach((el) => {
          el.label = el.exerciseName;
          el.value = el._id;
          valuearray.push(el._id);
        });
        form.setFieldValue("exerciselist", valuearray);
        setDefaultvalue(valuearray);
        setExercisedata(apicall.data);
      } else {
        // show error or initate assign expercise
      }
    } else {
      // show error or initate assign expercise
    }
  };
  const updatePatientExercise = async (values) => {
    setLoading(true);
    console.log(values);
    const array = [];
    values.exerciselist.forEach((el) => {
      array.push(el);
    });
    const apidata = {
      patientId: params.patientid,
      exerciseIdList: array,
    };
    console.log(apidata);
    const apicall = await PatientService.createPatientExercise(apidata);
    console.log(apicall);
    if (apicall.status === 201) {
      /// show update message
      PatientExercise();
      setLoading(false);
    } else {
      // error message show
      setLoading(false);
    }
  };
  useEffect(() => {
    setNavActive("Patients");
    fetchallexercise();
    PatientExercise();
  }, []);

  return (
    <Grid>
      <Grid.Col>
        <Group style={{ justifyContent: "space-between" }}>
          <BreadCamp data={breadcampitems} />
        </Group>
      </Grid.Col>
      <Grid.Col>
        <Center maw={"40%"} w={"40%"} m="auto">
          <Box
            maw={"100%"}
            w={"100%"}
            component="form"
            onSubmit={form.onSubmit((values) => {
              updatePatientExercise(values);
            })}
          >
            {/* <TextInput label='Patient Name' disabled value='Raju Kumar' /> */}
            {exercisedata && exerciseallList.length > 0 ? (
              <MultiSelect
                withAsterisk
                label="Select Exercise"
                placeholder="Pick value"
                data={exerciseallList}
                //  defaultValue={defaultvalue}
                {...form.getInputProps("exerciselist")}
                searchable
                size="lg"
                nothingFoundMessage="Nothing found..."
              />
            ) : (
              <MultiSelect
                withAsterisk
                label="Select Exercise"
                placeholder="Pick value"
                data={exerciseallList}
                //  defaultValue={defaultvalue}
                {...form.getInputProps("exerciselist")}
                searchable
                size="lg"
                nothingFoundMessage="Nothing found..."
              />
            )}
            <Space h={"lg"} />
            <Group d="flex" justify="right" width="20%">
              <Button
                type="submit"
                c={"#ffff"}
                bg={"#21A18E"}
                style={{ width: "40%" }}
              >
                {loading === true ? (
                  <Loader size={20} />
                ) : exercisedata ? (
                  "UPDATE EXERCISE"
                ) : (
                  "CREATE EXERCISE"
                )}
              </Button>
            </Group>
          </Box>
        </Center>
      </Grid.Col>
    </Grid>
  );
};

export default PatientAssignExercise;
