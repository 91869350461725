import React from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Stack, Image,Text } from '@mantine/core';
import useCameraAccess from "../../hooks/useCameraAccess";
import { Title } from 'chart.js';
import { useNavigate } from 'react-router-dom';

const CameraAccessModal = (props) => {
    const { enableCamera} = useCameraAccess();
    const navigate=useNavigate()
    const { opened, open, close } = props
    //  const [opened, { open, close }] = useDisclosure(false);
    const ApproveClick=()=>{
        enableCamera()
        navigate('/patientDashboard/romSession')
    }
    return (
        <>
            <Modal opened={opened} onClose={close} centered>
                <Text size='xl' align='center'>Please approve access to the camera</Text>
                <Image width={250} height={250} src='/assets/cameraLogo.png' alt='camera' />
                <Stack
                    bg="var(--mantine-color-body)"
                >
                    <Button variant="default" size='lg' radius='xl' onClick={close}>Do not approve</Button>
                    <Button onClick={ApproveClick} variant="default" size='lg' radius='xl'>Approve</Button>
                </Stack>
            </Modal>
        </>
    )
}
export default CameraAccessModal;