import React, { useEffect, useState, useContext } from "react";
import useCameraAccess from "../../hooks/useCameraAccess";
import {
  Container,
  Grid,
  Text,
  Button,
  Center,
  Box,
  Space,
  Group,
  ActionIcon,
  Modal,
  Title, Divider
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { enableDetection } from "../../utills/detectionSlice";
import OpenCam from "./openCam";
import { modals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { StoreRangeOfMotion } from "../../services/pier";
import { BreadCampContext } from "../../context/BreadCampContext";
import emitter from "../../eventEmitter";
import VoiceCommandContext from "./VoiceCommandContext";
import HookSteper from "../../pages/PatientDashBoard/Hooks/HookSteper";
const RomSession = () => {
  const { setBreadCampList } = useContext(BreadCampContext);
  const {postSurgery}=HookSteper()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isCamLoaded, disableCamera } = useCameraAccess();
  const [textBar, setTextBar] = useState(false);
  const [buttonSwitch, setButtonSwitch] = useState("Ready");
  const [showDialog, setShowDialog] = useState(0);
  const currentROM = useSelector((state) => state.angleDetected.currentROM);
  const [isCountdownModalVisible, setIsCountdownModalVisible] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  useEffect(() => {
    setBreadCampList([
      { title: "Patient Guidance", route: "/patientDashboard/guidance" },
      { title: "Range Of Session" },
    ]);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const modal = document.getElementById('countdownModal');
      const modalIsVisible = !!(modal && getComputedStyle(modal).display !== 'none');
      setIsCountdownModalVisible(modalIsVisible);
      
    }, 1000); // Check every second

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  window.addEventListener('popstate', function(event) {
    const modals = document.getElementById('landmarkVisibilityModal');
    if (modals) {
        modals.remove(); // or modals.style.display = 'none'; to hide it
    }
});

const handleClick = () => {
    if (buttonSwitch === "Ready") {
      dispatch(enableDetection());
      setButtonSwitch("Capture Done");
      setTextBar(true);
    } else if (buttonSwitch === "Capture Done") {
      dispatch(enableDetection());
      console.log("before dialog");
      openModal();
      setShowDialog(1);
      // closeCam={disableCamera}
      disableCamera();
    }
  };
  if (isCamLoaded) {
    // console.log("Load model", poseLandMarker, isCamLoaded);
  } else {
    console.log("camera disabled");
  }
  const handleAccept = async () => {
    const modal = document.getElementById('landmarkVisibilityModal');
    if (modal) {
    modal.remove();
    }
    const apidata = { maxrom: currentROM };
    if (apidata.maxrom === null || apidata.maxrom === '') {
      modal.remove();
      navigate(postSurgery[5].route);
    }
   // console.log("debugiing",apidata)
    const apicall = await StoreRangeOfMotion(apidata);
    console.log(apicall);
    if (apicall?.data?.statusCode === 201) {

      const modal = document.getElementById('landmarkVisibilityModal');
      if (modal) {
          modal.remove();
      }
      navigate(postSurgery[5].route);
     // window.location.reload();
      
    } else {
      if (modal) {
        modal.remove();
        navigate(postSurgery[5].route);
    }
    }
  };
    const openModal = () => modals.openConfirmModal({
        //title: 'Range of motion collected',
  
        title: <div style={{
          textAlign: 'center',  
          fontSize: '18px', 
          color: '#333',
          fontWeight: 'bold', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
      }}>Range of motion collected</div>,
        zIndex:'100000000',
        children: (
          <Text size="sm">
            Please click on "Accept capture" to proceed further or perform Range of motion again."<br></br><br></br>
            <b>Note: Doing it again will reset current ROM readings.</b>
          </Text>
        ),
        labels: { confirm: 'Accept Capture', cancel: 'Do it again' },
        confirmProps: { style: { color: 'white' , background:'linear-gradient(90deg, rgba(0,123,255,1) 0%, rgba(0,65,145,1) 100%)'}},
        cancelProps : {style: { color: 'white' , background:'linear-gradient(90deg, rgba(0,123,255,1) 0%, rgba(0,65,145,1) 100%)'}},
        onCancel: () => {
            window.location.reload();
            navigate("/guidance");
        },
        onConfirm: () => {console.log("rom value",currentROM), handleAccept()},
      });

      const handleCaptureDone = () => {
        const captureButton = document.getElementById('enable-capture');
        if (captureButton) {
          captureButton.click();
        } else {
          console.error('Capture button not found');
        }
      };


      const customListStyle = {
        paddingLeft: 0,
        listStyleType: 'none',
        margin: 0,
        textAlign: 'left'  // Align text left within the centered Text component
    };

    const customListItemStyle = {
        marginBottom: '4px', // Space between list items
        textIndent: '-1em', // Negative indent for bullet points
        paddingLeft: '1em'  // Padding left to align text after bullet point
    };

    return (
      <VoiceCommandContext.Provider value={{ handleDone: handleCaptureDone  }}>
        
        <Container fluid={true} h='100vh'>
            <Grid>
                <Grid.Col span={12} h='11vh'>
                    <Center style={{ height: '100%', width: '100%' }}>
                        <Box align='center'>
                            <Text p={5} fw={600} fs={35} size='xl' c="white"> Range of Motion</Text>
                        </Box>
                    </Center>
                </Grid.Col>
                <Grid.Col h ='1vh' align="center">
                    {textBar && (
                        <Text c = "white">The Value displayed will be the maximum angle collected during the session.</Text>
                    )}
                </Grid.Col> 
                <Grid.Col h='10vh' align="center">
                    {!textBar && (
                      <>
                        <Button style = {{ color: 'white' , background:'linear-gradient(90deg, rgba(0,123,255,1) 0%, rgba(0,65,145,1) 100%)'}}onClick={() => setModalOpened(true)}>Instructions</Button>
                        
                <Modal 
                className="instruct-ROM"
                style={{color:'white'}}   
                opened={modalOpened}
                withCloseButton={false}
                onClose={() => setModalOpened(false)}
                title={<Title order={3} align="center" style={{ color: '#008494' }}>Follow these steps to initiate the ROM session:</Title>}
                centered
                size="lg"
                overlayProps={{
                  backgroundOpacity: 0.55,
                  blur: 33,
        
                }}
            >
              <br></br>
                <Text align="center" size="sm" style={{ lineHeight: 1.6 }}>
                    After clicking on <b>Ready</b>, you will be prompted with the option to start the capture directly or use a timer.
                    The timer allows for 10 seconds preparation, which can be <b>paused</b>, <b>played</b>, and <b>reset</b>. Use the commands:
                    <ul style={customListStyle}>
                      <br></br>
                        <li style={customListItemStyle}><b>"Start Capture"</b> - gives you time to align yourself and start manually.</li>
                        <li style={customListItemStyle}><b>"Pause"</b> - pauses the timer.</li>
                        <li style={customListItemStyle}><b>"Reset"</b> - resets the timer back to 10 seconds.</li>
                        <li style={customListItemStyle}><b>"Done"</b> - ends the capture.</li>
                    </ul>
                    <br></br>
                    <div
                    style={{
                      backgroundColor: 'rgba(0, 139, 139, 0.5)',
                      padding:'5px',
                      borderRadius:'10px',
                    
                    }}
                    >
                    <b style={{color:'white'}}>PLEASE AVOID USING RELOAD OR BACK BUTTON. USE DO IT AGAIN BUTTON AT THE END OF THE SESSION TO RESET</b>
                    </div>
                </Text>
                <Divider my="lg" />
                <Group position="center">
                    <Button variant="outline" color="blue" onClick={() => setModalOpened(false)}>Got it</Button>
                </Group>
            </Modal>
                        </>
                    )}
                    <br></br>
                    {textBar && typeof currentROM === 'number' && (
                       
                        <Center>
                            
                            <ActionIcon radius='xl' size="input-sm" variant="default" aria-label="Action Icon">
                                {currentROM}
                            </ActionIcon>
                        </Center>
                        
                    )}  
                </Grid.Col>
                 {/* Camera Display Row */}
                 <Grid.Col span={12} h='45vh' align="center" mb={3} style={{ width: '100%', height: "100%" }}>
                    <Center style={{ height: '100%', width: '100%' }}>
                        <Box align='center' style={{ height: '100%' }}>
                            {isCamLoaded && <OpenCam displayCam={isCamLoaded} />}
                        </Box>
                    </Center>
                </Grid.Col>
                <Grid.Col h='30vh' mt={5} align='center'>
                {!isCountdownModalVisible && (
                    <Button
                    style={{zIndex:'1000000',background: 'linear-gradient(90deg, rgba(0,123,255,1) 0%, rgba(0,65,145,1) 100%)',
                    color: 'white', // Ensure text color is white for better readability
                    border: 'none'}}
                        id="enable-capture"
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        {buttonSwitch}
                    </Button>
                    )}
                </Grid.Col>
            </Grid>

        </Container>
        </VoiceCommandContext.Provider>
        
        
    )
}

export default RomSession;