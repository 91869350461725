import { useState } from "react";
import { Container, Grid } from "@mantine/core";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBarLinksGroup/NavBar";
import AdminHeader from "./HeaderModule/AdminHeader";
import { navbarContext } from "./Context/AdminDashboard"

const AdminDashboard = () => {
  const [navActive, setNavActive] = useState("Coordinator");
  return (
    <navbarContext.Provider value={{ navActive, setNavActive }}>
      <Container
        //  hiddenFrom="sm"
        fluid={true}
        px={0}
      >
        <Grid style={{ minHeight: "10vh" }} px={0}>
          <Grid.Col px={0} pt={0} pb={0} style={{ height: "10vh" }}>
            <div
              style={{
                height: "10vh",
                position: "fixed",
                width: "100%",
                zIndex: 2,
              }}
            >
              {/* <DoctorHeader /> */}
              <AdminHeader />
            </div>
          </Grid.Col>
        </Grid>
        <Grid
        // visibleFrom="sm"
        >
          <Grid.Col
            //    hiddenFrom="sm"
            span={2}
            order={{ base: 2, xs: 0, sm: 0, lg: 2, xl: 2, md: 2 }}
            //  sm={0} xs={0} xl={2} md={2} lg={2} pt={0}
            style={{ borderRight: "1px solid #B3F9FF", minHeight: "90vh" }}
          >
            <NavBar />
          </Grid.Col>
          <Grid.Col
            span={10}
            order={{ base: 10, xs: 12, sm: 10, lg: 10, xl: 10, md: 10 }}
            //  sm={12} xs={12} xl={10} md={10}lg={10}
            style={{ height: "100%" }}
          >
            <Outlet />
          </Grid.Col>
        </Grid>
      </Container>
    </navbarContext.Provider>
  );
};

export default AdminDashboard;
