import { AppShell, Flex, Group, Image } from "@mantine/core";
import Arrow from "../../constants/Logo/arrow-left.svg";
import "./ImageAIHeader.scss";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const PatientHeader = ({ children, data }) => {
  const navigate = useNavigate();
  return (
    <div>
      <AppShell withBorder={false} header={{ height: 50 }}>
        <AppShell.Header style={{ backgroundColor: "#01526A" }}>
          <Flex
            justify="space-between"
            align="center"
            style={{ padding: "5px 20px", height: "100%" }}
          >
            <Image
              onClick={() => {
                navigate("/guidance", { replace: true });
              }}
              src={Arrow}
              alt="back-arrow"
            />
            <Group
              h="100%"
              px="md"
              justify="center"
              align="center"
              flex={"1 0 0"}
            >
              {data}
            </Group>
          </Flex>
        </AppShell.Header>
        <AppShell.Main w={"100%"}>{children}</AppShell.Main>
      </AppShell>
    </div>
  );
};

export default PatientHeader;

PatientHeader.propTypes = {
  data: PropTypes.string,
};
