import { Flex, Burger, Button, Avatar ,Group,Text, Menu , rem ,useMantineColorScheme, useComputedColorScheme, AppShell } from '@mantine/core';
import PropTypes from 'prop-types';
import Trussimg from "./company-name.png"
import { useState } from 'react';
import { MantineLogo } from '@mantinex/mantine-logo';
import loginService from '../../../services/loginService';
import { useMantineTheme } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import {useEffect} from 'react'
import {
  IconUserFilled,
  IconMail,
  IconTrash,
} from '@tabler/icons-react';
const Header = ({ toggleMobile, mobileOpened, desktopOpened, toggleDesktop }) => {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const theme = useMantineTheme()
  const navigate = useNavigate();
  const [Account , setAccount] = useState([])
  const handleLogout = () => {
    const userType = loginService.getUserType("userType");
    console.log("userType is ",userType);
    const path =
    userType === "doctor" ? "/" :
    userType === "admin" ? "/adminlogin" :
    userType === "coordinator" ? "/coordinatorlogin" :
    userType === "physio" ? "/physiologin" :
    userType === "patient" ? "/patientlogin" :
    "/";
    console.log("path  is ",path);

        loginService.dologout();
   navigate(path, { replace: true });
    
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };
  const [opened, setOpened] = useState(false);
  const loadData = () => {
    const data = loginService.getparamsFromCookie("jwt_session");
    const seed = Math.floor(Math.random() * 5000);
    if (data) {
      const accDetails = {};
      accDetails.userName = loginService.getparamsFromCookie("username");
      accDetails.email = loginService.getparamsFromCookie("email");
      accDetails.photoURL = loginService.getparamsFromCookie("avatar");
      if (!accDetails.photoURL) {
        accDetails.photoURL = `https://api.dicebear.com/7.x/avataaars/svg?seed=${seed}}`;
        loginService.setAvatar(accDetails.photoURL);
      }

      setAccount(accDetails);
    }
  };


  return (
    <AppShell.Header style={{backgroundColor : theme.colors.myblue[0]}} >

      <Flex justify="space-between" align="center" style={{ padding: '10px 20px' }}>
        <Group h="100%" px="md">
          <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm"  size="sm" />
          <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
          <img src={Trussimg} style={{width: '130px' , height:'30px'}} alt='hi'/>

            </Group>
       
        <Menu   shadow="md" width={140}>
      <Menu.Target>
      <Avatar src="avatar.png" alt="it's me" />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Profile</Menu.Label>
        <Menu.Item leftSection={<IconUserFilled style={{ width: rem(14), height: rem(14) }} />}>
        {Account.userName}
        </Menu.Item>
       
        <Menu.Item
          leftSection={<IconMail style={{ width: rem(14), height: rem(14) }} />}
          rightSection={
            <Text size="xs" c="dimmed">
             {Account.email}
            </Text>
          }
        >
          Search
        </Menu.Item>

        <Menu.Divider />

        <Menu.Label>Danger zone</Menu.Label>
        <Menu.Item
          color="red"
         onClick={handleLogout}   leftSection={<IconTrash style={{ width: rem(14), height: rem(14) }} />}
        >
          Log Out
        </Menu.Item>
        </Menu.Dropdown>
    </Menu>


      </Flex>
    </AppShell.Header>
  );
};

Header.propTypes = {
  toggleMobile: PropTypes.func.isRequired,
  mobileOpened: PropTypes.bool.isRequired,
  desktopOpened: PropTypes.bool.isRequired,
  toggleDesktop: PropTypes.func.isRequired,
};

export default Header;
