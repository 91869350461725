
import { useState } from 'react';
import {
    Button,
    Center,
    Container,
    Grid,
    Space,
    TextInput, Group, Loader
} from "@mantine/core";
import { useLocation } from "react-router-dom";
import { PasswordInput } from "@mantine/core";
// import BreadCamp from "../../../resuable/BreadCamps";
import BreadCamp from "../../../resuable/BreadCamps";
import { useForm, isNotEmpty, isEmail, isInRange, hasLength, matches } from '@mantine/form';
// this component part of clinical cordinator
const AddEditPatient = () => {
    const location = useLocation();
    const dataCheck = location.state;
    const [loading, setLoading] = useState(false)
    const [breadcampitems, setBreadcampitems] = useState([
        { title: "DashBoard", href: "#" },
        { title: "Add Patient", href: "#" },
    ]);
    const form = useForm({
        initialValues: {
            name: "",
            email: "",
            contact: "",
            password: "",
        },
        // validate: {
        //     name: hasLength({ min: 2, max: 10 }),
        //     email: isEmail(),
        //     // favoriteColor: matches(/^#([0-9a-f]{3}){1,2}$/),
        //     // password: isInRange({ min: 18, max: 99 }),
        //   },
    });
    const GetDoctorsList=()=>{
        
    }

    const AddPatient = (values) => {
        const data = { email: values.email, name: values.name, password: values.password, mobileNumber: values.contact,doctorId:"" }

    };

    return (
        <Grid>
            <Grid.Col>
                <Group style={{ justifyContent: "space-between" }}>
                    <BreadCamp data={breadcampitems} />
                </Group>
            </Grid.Col>
            <Grid.Col>
                <Center component="form" onSubmit={form.onSubmit((values) => { console.log(values) })}>
                    <Space h={"md"}>
                        <Grid>
                            <Grid.Col span={6} sm={12}>
                                <TextInput label="Patient Name" placeholder='Enter Patient Name' {...form.getInputProps("name")} />
                            </Grid.Col>
                            <Grid.Col span={6} sm={12}>
                                <TextInput label="Email" placeholder='Enter Your Email' {...form.getInputProps("email")} />
                            </Grid.Col>
                            <Grid.Col span={6} sm={12} >
                                <TextInput label="Contact" placeholder='Enter Contact Number' {...form.getInputProps("contact")} />
                            </Grid.Col>
                            <Grid.Col span={6} sm={12}>
                                <PasswordInput label="Password Reset" placeholder='Enter Password' {...form.getInputProps('password')} />
                            </Grid.Col>
                        </Grid>
                        <Space h={"lg"} />
                        <Group d='flex' justify='right' width='20%' >

                            <Button type='submit' c={"#ffff"} bg={"#21A18E"} style={{ width: '20%' }}>
                                {loading === true ? <Loader size={20} /> : "  ADD PATIENT"}
                            </Button>
                        </Group>
                    </Space>
                </Center>
            </Grid.Col>
        </Grid>
    );
};

export default AddEditPatient;
