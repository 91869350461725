import { Box, Button, Flex, Space, Table } from "@mantine/core";
import propTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { routeCoordinatorDoctorTab } from "../../../constants/constants";
import BreadCamp from "../../../resuable/BreadCamps";
import { navbarContext } from "../Context/CoordinatorDashboard";
import coordinatorUsersService from "../../../services/CoordinatorUsersService";
import { useNavigate, useParams } from "react-router-dom";

const PatientListOverview = () => {
  const tableDataHeader = ["Sr No.", "Patient Name", "Email","Mobile Number"];
  const { setBreadCampList } = useContext(BreadCampContext);
  const { setNavActive } = useContext(navbarContext);
  const [patientList, setPatientList] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const { doctorId } = params;

  console.log("doctorId ", doctorId);

  const getPatientList = async () => {
    const { data } = await coordinatorUsersService.getPatientList(doctorId);
    setPatientList(data);
  };

  useEffect(() => {
    console.log("Testing");
    getPatientList();
  }, [doctorId]);

  useEffect(() => {
    setBreadCampList([
      {
        title: "Doctor",
        route: routeCoordinatorDoctorTab,
      },
      { title: "Patient List" },
    ]);
    setNavActive("Doctor");
  }, [setBreadCampList]);

  const rows = patientList.map((element, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{idx + 1}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.email}</Table.Td>
      <Table.Td>{element.mobile}</Table.Td>

      {/* <Table.Td>{element.severity ?? "High"}</Table.Td> */}
    </Table.Tr>
  ));

  const handleClick = () => {
    navigate(`/CoordinatorDashboard/add-patient/${doctorId}`);
  };

  return (
    <>
      <Flex style={{ alignItems: "center", justifyContent: "space-between" }}>
        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "7px",
            paddingBottom: "-10px",
          }}
        >
          <BreadCamp />
        </div>
        <div
          style={{
            paddingTop: "25px",
            paddingRight: "15px",
            paddingBottom: "-10px",
          }}
        >
          <Button onClick={() => handleClick()} style={{ background: "black" }}>
            Create New Patient
          </Button>
        </div>
      </Flex>

      <Box
        style={{
          height: "70vh",
          overflow: "auto",
          margin: "20px",
          // border: "2px solid white",
        }}
      >
        <Space h={"md"} />
        <Table
          style={{ width: "100%" }}
          striped
          //   highlightOnHover
          //   highlightOnHoverColor={"black"}
          withColumnBorders
          stripedColor={"#00526A"}
          stickyHeader
          stickyHeaderOffset={-1}
        >
          <Table.Thead style={{ background: "black" }}>
            <Table.Tr>
              {tableDataHeader.map((items) => {
                return (
                  <Table.Th c={"white"} key={items}>
                    {items}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody style={{ overflowY: "auto" }}>{rows}</Table.Tbody>
        </Table>
      </Box>
    </>
  );
};

export default PatientListOverview;
