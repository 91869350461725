import React, { useEffect, useState, useContext } from "react";
import {
  ThemeIcon,
  Button,
  Image,
  Modal,
  Progress,
  Grid,
  Text,
  Group,
  Badge,
  Paper,
  rem,
} from "@mantine/core";
import { IconSwimming } from "@tabler/icons-react";
import classes from "./ExerciseDetail.module.css";
import BreadCamp from "../../../../../resuable/BreadCamps";
import ExerciseService from "../../../../../services/exercise";
import { useParams } from "react-router-dom";
import { BASEURL } from "../../../../../constants/constants";
import { useDisclosure } from "@mantine/hooks";
import { navbarContext } from "../../../Context/CoordinatorDashboard";

const ExerciseDetail = () => {
  const { setNavActive } = useContext(navbarContext);
  const params = useParams();
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "Exercise", href: "#" },
    { title: "Exercise Detail", href: "#" },
  ]);
  const [opened, { open, close }] = useDisclosure(false);
  const [exerciseData, setExerciseData] = useState();
  const GetExerciseDetail = async () => {
    console.log(params);
    const apicall = await ExerciseService.getExerciseDetail(params.exerciseid);
    console.log(apicall);
    if (apicall.statusCode === 200) {
      setExerciseData(apicall.data);
    } else {
      // error
    }
  };
  useEffect(() => {
    setNavActive("Setting Exercise");
    GetExerciseDetail();
  }, []);
  return (
    <>
      <Grid>
        <Grid.Col>
          <Group style={{ justifyContent: "space-between" }}>
            <BreadCamp data={breadcampitems} />
          </Group>
        </Grid.Col>
        {exerciseData ? (
          <Grid.Col>
            <Paper radius="md" withBorder className={classes.card} mt={20}>
              <ThemeIcon className={classes.icon} size={60} radius={60}>
                <IconSwimming
                  style={{ width: rem(32), height: rem(32) }}
                  stroke={1.5}
                />
              </ThemeIcon>

              <Text ta="center" fw={700} className={classes.title}>
                {exerciseData.exerciseName}
              </Text>

              <Group justify="space-between" mt="xs">
                <Image
                  src={`${BASEURL}/showimage/images/${exerciseData.exerciseImagePath}`}
                />
              </Group>
              <Group justify="space-between" mt="md">
                <Button size="" c={"black"} onClick={open}>
                  Play Exercise
                </Button>
              </Group>
            </Paper>
          </Grid.Col>
        ) : (
          ""
        )}
      </Grid>
      {exerciseData ? (
        <Modal
          opened={opened}
          onClose={close}
          title={exerciseData.exerciseName}
          centered
        >
          <video
            src={`${BASEURL}/showimage/videos/${exerciseData.exerciseVideoPath}`}
            controls
          >
            {/* <source src={`${BASEURL}/showimage/videos/${exerciseData.exerciseVideoPath}`} type="video/mp4" /> */}
          </video>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default ExerciseDetail;
