import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Container, Image, Title } from "@mantine/core";
import "./AdminLogin.scss";
import LoginForm from "../sections/auth/login/LoginForm.jsx"
import { Grid } from "@mantine/core";
const OverlayContainer = styled.div`
  position: relative;
`;

const OverlayImage = styled(Image)`
  position: absolute;
  top: 50%; /* Move to vertical center */
  left: 50%; /* Move to horizontal center */
  transform: translate(-50%, -50%); /* Center the image */
  width: 70%;
  height: 70%;
`;
const OverlayImageMobile = styled(Image)`
  position: absolute;
  left: 50%; /* Move to horizontal center */
  transform: translate(-50%, -50%); /* Center the image */
  width: 70%;
  height: 70%;
`;
const OverlayImage2 = styled(Image)`
  position: absolute;
  top: 75%; /* Move to vertical center */
  left: 50%; /* Move to horizontal center */
  transform: translate(-50%, -50%); /* Center the image */
  width: 70%;
  height: 70%;
`;
const OverlayContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function PhysioLogin() {
  return (
    <>
     <Helmet>
        <title> Physiotherapist Login | Truss Health </title>
      </Helmet>
      <Container p={0} fluid={true} className="main-container">
        <Grid p={0}>
          <Grid.Col visibleFrom="sm" span={4}>
            <OverlayContainer>
              <OverlayImage
                className="overlay-text"
                h="auto"
                radius="md"
                src="/assets/TrussLogo-blue.png"
                alt="hello"
              />
              <OverlayImage2
                h="auto"
                radius="md"
                src="/assets/company-name.png"
                alt="hello"
              />
              <Image
                h="auto"
                radius="md"
                src="/assets/left-right-design.svg"
                alt="hello"
              />
            </OverlayContainer>
          </Grid.Col>
          {/* This below component is visble in Desktop Mode */}
          <Grid.Col
            visibleFrom="sm"
            fluid
            span={7}
            className="hello"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Title visibleFrom="sm">Physiotherapist Login</Title>
            <Title order={6}>Sign in</Title>
            <LoginForm userType="physio" />
          </Grid.Col>
                    {/* This below component is visble in Mobile Mode */}

                    <Grid.Col
      hiddenFrom="sm"
      fluid
      span={12}
      className="hello"
    >
      <OverlayContainerMobile>
        <Image
          h="auto"
          radius="md"
          src="/assets/left-right-design.svg"
          alt="hello"
        />
        <OverlayContent>
        <OverlayImageMobile
              
                h="200"
                w="auto"
                radius="md"
                src="/assets/TrussLogo-blue.png"
                alt="hello"
              />
          <Title hiddenFrom="sm">Physiotherapist Login</Title>
          <LoginForm userType="physio" />
        </OverlayContent>
      </OverlayContainerMobile>
    </Grid.Col>
        </Grid>
      </Container>
    </>
  );
}
