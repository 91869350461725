import { useEffect, useState, useContext } from "react";
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  keys,
  Grid,
  Button,
  Tooltip,
} from "@mantine/core";
import {
  IconSelector,
  IconCirclePlus,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconPencil,
  IconTrash,
  IconEye,
} from "@tabler/icons-react";
import PatientService from "../../../services/patientService";
import TableData from "./TableData";
import BreadCamp from "../../../resuable/BreadCamps";
import { Link, useNavigate } from "react-router-dom";
import { navbarContext } from "../context/DoctorDashboard";
import { PatientDetailoverview } from "../../../constants/constants";
import dailyReportService from "../../../services/dailyReportService";
import moment from "moment";
import { BreadCampContext } from "../../../context/BreadCampContext";
import LoaderPage from '../../../resuable/loder';
function OverViewDoctor(props) {
  const { setBreadCampList } = useContext(BreadCampContext);
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [patientListshow, setPatientListshow] = useState(true);
  const [createpatientshow, setCreatepatientshow] = useState(false);
  const navigate = useNavigate();
  const [loding,setLoading]=useState(true)

  const [breadcampitems, setBreadcampitems] = useState([
    // { title: "DashBoard", href: "#" },
    { title: "Overview", href: "#" },
  ]);
  const FetchPatientList = async () => {
    const apiacll = await dailyReportService.getDailyReport();
    console.log(apiacll);
    if (apiacll.statusCode === 200) {
      const setdata = apiacll.data.filter((elc) => {
        return elc.initialHealth.length > 0;
      });
      const filterkeydata = [];
      setdata.forEach((element) => {
        const date = element?.initialHealth[0].assignquestion.filter((ex) => {
          return ex.type === "calendar";
        });

        filterkeydata.push({
          name: element.name,
          dateofsurgery: date ? date[0].answer : "",
          patientid: element._id,
          patientname: element.name,
          riskdata:element?.riskreport["risk-score"]
        });
      });
      filterkeydata.sort(function(a, b) {
        return parseFloat(b.riskdata) - parseFloat(a.riskdata);
    });
    console.log(filterkeydata)

      setList(filterkeydata);
      setSortedData(filterkeydata);
      const rowdata = filterkeydata.map((row, index) => (
        <Table.Tr key={index}>
          <Table.Td>{index + 1}</Table.Td>
          <Table.Td>{row.name}</Table.Td>
          <Table.Td>
            {moment(row.dateofsurgery, "YYYY-MM-DD").format("MMM Do YY")}
          </Table.Td>
          <Table.Td>{Number(row.riskdata).toFixed(2)} %</Table.Td>
          <Table.Td>
            <Group align="">
              <Tooltip label="view Patient Detail">
                <IconEye
                  onClick={() => {
                    navigate(
                      `${PatientDetailoverview}/${row.patientname}/${row.patientid}`
                    );
                  }}
                  size={18}
                  color="#90EE90"
                />
              </Tooltip>
            </Group>
          </Table.Td>
        </Table.Tr>
      ));

      setRows(rowdata);
      setLoading(false)
    }
    else{
      setLoading(false)
    }
  };
  useEffect(() => {
    setBreadCampList([{ title: "Dashboard" }]);
    setNavActive("Dashboard");
    FetchPatientList();
  }, []);

  const dataObject = {
    key1: "value1",
    key2: "value2",
  };

  // useEffect(() => {
  //   const rowdata = sortedData.map((row, index) => (
  //     <Table.Tr key={index}>
  //       <Table.Td>{index + 1}</Table.Td>
  //       <Table.Td>{row.name}</Table.Td>
  //       <Table.Td>
  //         {moment(row.dateofsurgery, "YYYY-MM-DD").format("MMM Do YY")}
  //       </Table.Td>
  //       <Table.Td>{Number(row.riskdata).toFixed(2)} %</Table.Td>
  //       <Table.Td
  //         c={"#d1ffbd"}
  //         style={{ cursor: "pointer" }}
  //         onClick={() => {
  //           navigate(
  //             `${PatientDetailoverview}/${row.patientname}/${row.patientid}`
  //           );
  //         }}
  //       >
  //         View Detail
  //       </Table.Td>
  //     </Table.Tr>
  //   ));
  //   setRows(rowdata);
  // }, [sortedData]);
  const ClickCreatePatient = () => {
    navigate("/doctorDashboard/add-patient");
  };
  return (
    <>
    <Grid>
      <Grid.Col>
        <Group style={{ justifyContent: "space-between" }}>
          <BreadCamp />
        </Group>
      </Grid.Col>
      {loding===false ?
      <Grid.Col>
        {patientListshow === true ? (
          <TableData
            list={list}
            rows={rows}
            sortedData={sortedData}
            setSortedData={setSortedData}
          />
        ) : (
          ""
        )}
      </Grid.Col>
      :
      <Grid.Col>
        <LoaderPage />
      </Grid.Col>
      }
    </Grid>
    </>
  );
}
export default OverViewDoctor;
