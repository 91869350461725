import CalendarInput from "./input-types/CalendarInput";
import RadioGroup from "./input-types/RadioGroup";
import TextInput from "./input-types/TextInput";
import YesNo from "./input-types/YesNo";
import PropTypes from "prop-types";

const UserInput = ({
  type = "calendar",
  number,
  questionData,
  setUserAnswers,
  options = [],
}) => {
  const userInputType = {
    calendar: <CalendarInput handleChange={handleChange} />,
    text: <TextInput handleChange={handleChange} />,
    "radio-group": <RadioGroup options={options} handleChange={handleChange} />,
    "yes-no": <YesNo options={options} handleChange={handleChange} />,
  };
  function handleChange(data) {
    questionData.answer = data || null;

      setUserAnswers((prev) => {
        prev[number] = questionData;
        return prev;
      });
  }

  return <div className="truss-user-input">{userInputType[type]}</div>;
};


export default UserInput;
