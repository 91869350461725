import { ScrollArea } from "@mantine/core";

import { LinksGroup } from "./NavbarLinksGroup";
import classes from "./NavbarNested.module.css";
import { ClinicalCoordinatorDashboard } from "../../../constants/constants";
import { useLocation } from "react-router-dom";

// const mockdata = [
//     { label: "Dashboard", icon: IconGauge },
//     { label: "Patients", icon: IconGauge },

//     {
//         label: "Exercise",
//         icon: IconNotes,
//         initiallyOpened: true,
//         links: [
//             { label: "Global Seeting Exercise", link: "/" },
//             { label: "Global Exercise", link: "/" },
//             { label: "Assign Exericse Patients", link: "/" },
//         ],
//     }
// ];
const NavBar = () => {
  const location = useLocation();
  const links = ClinicalCoordinatorDashboard.map((item) => (
    <LinksGroup {...item} key={item.label} location={location} />
  ));
  return (
    <nav className={classes?.navbar}>
      <ScrollArea className={classes?.links}>
        <div className={classes?.linksInner}>{links}</div>
      </ScrollArea>
    </nav>
  );
};
export default NavBar;
