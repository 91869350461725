import React, { useEffect, useState,useContext } from 'react';
import { ThemeIcon, Button, Image, Modal, Progress, Grid, Text, Group, Badge, Paper, rem } from '@mantine/core';
import { IconSwimming } from '@tabler/icons-react';
import classes from './ExerciseDetail.module.css';
import BreadCamp from "../../../../../resuable/BreadCamps";
import ExerciseService from '../../../../../services/exercise';
import { useParams } from 'react-router-dom';
import { BASEURL } from '../../../../../constants/constants';
import { useDisclosure } from '@mantine/hooks';
import {navbarContext} from '../../../context/DoctorDashboard';
import {BreadCampContext} from '../../../../../context/BreadCampContext';
import {seetingExerciseRoute} from '../../../../../constants/constants';
import {DoctorDashboardPatientlistRoute} from '../../../../../constants/constants';

const ExerciseDetail = () => {
    const {setNavActive}=useContext(navbarContext)
    const { setBreadCampList} = useContext(BreadCampContext);

    const params = useParams()
    const [breadcampitems, setBreadcampitems] = useState([
        { title: "Exercise", href: "#" },
        { title: "Exercise Detail", href: "#" },
    ]);
    const [opened, { open, close }] = useDisclosure(false);
    const [exerciseData, setExerciseData] = useState()

    const exerciseMediaMapping = {
        "Ankle Pumps": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Ankle-Pumps.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Ankle-Pumps.mp4"
        },
        "Bridging": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/bridging.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Bridging.mp4"
        },
        "Chair Pushups": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Chair-Ups.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Chair-Push-Ups.mp4"
        },
        "Gluteal Squeezes": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Gluteal-Squeezes.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Gluteal-Squeezes.mp4"
        },
        "Hamstring Set": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Hamstring-set.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Hamstring-Set.mp4"
        },
        "Heel Slides": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Heel-slides.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Heel-Slides.mp4"
        },
        "Hip Abduction": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Hip-abduction.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Hip-Abduction.mp4"
        },
        "Knee Extension": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Knee-extension.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Knee-Extension.mp4"
        },
        "Long arc Quads": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Long-arc-quads.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Long_Arc_Quads.mp4"
        },
        "Quad Sets": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Quad-sets.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Quad-Sets.mp4"
        },
        "Sitting Knee Extension": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Sitting-knee-extension.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Sitting-Knee-Extension.mp4"
        },
        "Sitting knee Flexion": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Sitting-knee-flexion.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Sitting-Knee-Flexion.mp4"
        },
        "Straight leg raises": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Straight-leg-raises.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Straight_Leg_Raises.mp4"
        },
        "Terminal knee extension": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Terminal-knee-extension.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Terminal-Knee-Extension.mp4"
        }, 
        "Toe up": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Toe-up.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Toe-up.mp4"
        },
        "Knee Slides": {
          imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Knee-slides.png",
          videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Knee-Slides.mp4"
        },
        
      };




    const GetExerciseDetail = async () => {
        console.log(params)
        const apicall = await ExerciseService.getExerciseDetail(params.exerciseid)
        console.log(apicall)
        if (apicall.statusCode === 200) {
            setExerciseData(apicall.data)
        }
        else {
            // error
        }
    }
    useEffect(() => {
        setBreadCampList([
         { title: "Patient List", route: DoctorDashboardPatientlistRoute },
        { title: "Exercise" },
        ])
        // setNavActive('Setting Exercise')
        GetExerciseDetail()
    }, [])
    return (
        <>
            <Grid>
                <Grid.Col>
                    <Group style={{ justifyContent: "space-between" }}>
                        <BreadCamp data={breadcampitems} />
                    </Group>
                </Grid.Col>
                {exerciseData ?
                    <Grid.Col>
                        <Paper w='350' radius="md" withBorder className={classes.card} mt={20}>
                            <ThemeIcon className={classes.icon} size={60} radius={60}>
                                <IconSwimming style={{ width: rem(32), height: rem(32) }} stroke={1.5} />
                            </ThemeIcon>

                            <Text ta="center" fw={700} className={classes.title}>
                                {exerciseData.exerciseName}
                            </Text>


                            <Group justify="space-between" mt="xs">
                                <Image 
                                src={exerciseMediaMapping[exerciseData.exerciseName]?.imagePath} 
                                height={"100%"}
                            
                                />
                            </Group>
                            <Group justify="space-between" mt="md">
                                <Button size="" c={'black'} onClick={open}>Play Exercise</Button>
                            </Group>
                        </Paper>
                    </Grid.Col>
                    : ""}
            </Grid>
            {exerciseData ?
                <Modal opened={opened} onClose={close} title={exerciseData.exerciseName} centered>
                    <video src={exerciseMediaMapping[exerciseData.exerciseName]?.videoPath} controls />
                        {/* <source src={`${BASEURL}/showimage/videos/${exerciseData.exerciseVideoPath}`} type="video/mp4" /> */}
                    
                </Modal>
                : ""}
        </>

    )
}

export default ExerciseDetail;