import React from "react";
import {Title} from '@mantine/core';
const Question = ({ number, question }) => {
  return (
    <Title size='md' mb={5} className="" style={{display:'flex !important'}} >
      <span  style={{color:'#B3F9FF'}}>{number}</span>&nbsp;&nbsp;
      <span  style={{color:'#B3F9FF'}}>{question}</span>
    </Title>
  );
};

export default Question;
