import { Box, Table, Tooltip, Button, Space } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import propTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BreadCampContext } from "../../../context/BreadCampContext";
import {
  PhysioLanding,
  routePhysiotherapistDoctorTab,
} from "../../../constants/constants";
import BreadCamp from "../../../resuable/BreadCamps";
import coordinatorUsersService from "../../../services/CoordinatorUsersService";
import PhysioPatientListOverview from "./PhysioPatientListOverview";

const PhysioTableList = ({ tableHeader,list }) => {
  const navigate = useNavigate();

  const [viewPatients, setViewPatients] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const { setBreadCampList } = useContext(BreadCampContext);


  useEffect(() => {
    setBreadCampList([
      {
        title: "Doctor",
        route: PhysioLanding + routePhysiotherapistDoctorTab,
      },
    ]);
  }, [setBreadCampList]);

  const getDoctorList = async () => {
    //API call to get the doctor list
    // const data = await coordinatorUsersService.getDoctorList();
    // setDoctorList(data.data);
  };

  useEffect(() => {
    //function call to get doctor list
    // getDoctorList();
  }, []);

  const handleClick = (e) => {
    navigate(`${e.id}`);
    setViewPatients(true);
  };

  const rows = list.map((element, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{idx + 1}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.email}</Table.Td>
      {/* <Table.Td>{element.mass}</Table.Td> */}
      <Table.Td>
        <Tooltip label="View All Patient Detail">
          <IconEye
            onClick={() => {
              handleClick(element);
            }}
            size={18}
            color="#90EE90"
          />
        </Tooltip>
       
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      {viewPatients === false ? (
        <>
          <BreadCamp />
          {/* <Space h={"md"} /> */}

          <Box
            style={{
              height: "70vh",
              overflow: "auto",
              margin: "20px",
              // border: "2px solid white",
            }}
          >
            {/* {viewPatients ? (
        <PatientListOverview handleClick={toggleView} />
      ) : ( */}
            <Table
              style={{ width: "100%" }}
              striped
              //   highlightOnHover
              //   highlightOnHoverColor={"black"}
              withColumnBorders
              stripedColor={"#00526A"}
              stickyHeader
              stickyHeaderOffset={-1}
            >
              <Table.Thead style={{ background: "black" }}>
                <Table.Tr>
                  {tableHeader.map((items) => {
                    return (
                      <Table.Th c={"white"} key={items}>
                        {items}
                      </Table.Th>
                    );
                  })}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody style={{ overflowY: "auto" }}>{rows}</Table.Tbody>
            </Table>
            {/* )} */}
          </Box>
        </>
      ) : (
        <PhysioPatientListOverview />
      )}
    </>
  );
};

export default PhysioTableList;
PhysioTableList.propTypes = {
  tableHeader: propTypes.array.isRequired,
};
