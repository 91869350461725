import {
  Box,
  Button,
  Center,
  Group,
  Image,
  Loader,
  Space,
  Stepper,
  Text,
  Grid,
} from "@mantine/core";
import { useState,useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import ImageCard from "./ImageCard";

//uncomment adter adding API route in the pier.js file
import { StoreImageAI } from "../../services/pier";
import {PatientDailyworkoutContext} from '../../context/PatientDailyWorkoutContext';

const ImageBody = () => {
  const [active, setActive] = useState(0);
  const { surgerydata } = useContext(PatientDailyworkoutContext)

  // DO NOT DELETE :  the following code is needed when we have all the three screens
  // const [submitText, setSubmitText] = useState("Next step");

  //go back to the dashboard
  const navigate = useNavigate();
  const params=useParams();
  const {patientId}=params;
  //to store in react state to send to the API
  const [dataImgdigital, setDataImgdigital] = useState("");
  const [dataImgSideAngle, setDataImgSideAngle] = useState("");
  const [dataImgthermal, setDataImgthermal] = useState("");
  const [saveStatus, setSavestatus] = useState(false);
  const [saveimageData, setSaveimageData] = useState([]);
  const [displayText, setDisplayText] = useState(0);
  const [resUnkown, setResUnkown] = useState(0);
  const [resString, setResString] = useState(0);

  console.log("resUnkown ", resUnkown);

  //to switch to next steps for stepper
  const nextStep = async () => {
    setActive((current) => (current < 3 ? current + 1 : current));
    // DO NOT DELETE :  the following code is needed when we have all the three screens
    // if (active == 1) {
    //   setSubmitText("Submit");
    // }
    // if (active === 2) {
    //   // navigate("/guidance");
    //   const res = {
    //     topAngleImage: dataImgdigital,
    //     sideAngleImage: dataImgSideAngle,
    //     thermalImage: dataImgthermal,
    //   };
    //   //function call for post request
    //   const apicall = await StoreImageAI(res);
    //   if (apicall.status === 201) {
    //     setTimeout(() => {
    //       setSavestatus(true);
    //       setSaveimageData([
    //         { imageurl: apicall?.data?.sideAngleImageurl },
    //         { imageurl: apicall?.data?.thermalImageurl },
    //         { imageurl: apicall?.data?.topAngleImageurl },
    //       ]);
    //     }, 2000);
    //     //  navigate("/guidance")
    //   } else {
    //     // show error notification
    //   }
    // }

    const res = {
      topAngleImage: dataImgdigital,
      sideAngleImage: dataImgSideAngle,
      thermalImage: dataImgthermal,
      patientId:patientId
    };

    setDisplayText(1);

    //function call for post request
    try {
      setSavestatus(true);
      const apicall = await StoreImageAI(res);
      console.log("checkin");
      console.log("apicall ", apicall);
      if (apicall.status === 201) {
        console.log("test ");
        setTimeout(() => {
          setSavestatus(true);
          setSaveimageData([
            { imageurl: apicall?.data?.sideAngleImageurl },
            { imageurl: apicall?.data?.thermalImageurl },
            { imageurl: apicall?.data?.topAngleImageurl },
          ]);
        }, 2000);
        console.log("Image AI successful");
        //  navigate("/guidance")
      } else {
        console.log("API failed to send response");
        // show error notification
      }
    } catch {
      console.log("inside catch");
      setSaveimageData([]);
      setResString(1);
      setSavestatus(true);
    }
  };

  // DO NOT DELETE :  the following code is needed when we have all the three screens
  //to switch to previous steps for stepper
  // const prevStep = () =>
  //   setActive((current) => (current > 0 ? current - 1 : current));

  const LabelText = [
    {
      title: "Angle - Top Angle Image",
      desc: "Please keep your best to align your leg within the blue line",
    },
    {
      title: "Angle - Side Angle Image",
      desc: "Please keep your best to align your leg within the blue line",
    },
    {
      title: "Angle - Thermal Image",
      desc: "Please keep your best to align your leg within the blue line",
    },
  ];

  return (
    <>
      <Helmet>Image AI</Helmet>
      <Center>
        {saveStatus === true ? (
          <Box align="center">
            <Space h={"md"} />
            <Text align="center" c={"#ffff"}>
              Predicted Results
            </Text>
            <Space h={"md"} />
            {resString && (
              <Text c={"white"}>
                Invalid Image uploaded or File not supported
              </Text>
            )}
            <Space h="md" />
            <Grid w={"100%"}>
              <Grid.Col span={{ md: 6, lg: 6, sm: 12 }}>
                <div>
                  <Image src={dataImgdigital} radius="md" />
                </div>
              </Grid.Col>
              <Grid.Col span={{ md: 6, lg: 6, sm: 12 }}>
                {saveimageData.length > 0 ? (
                  <>
                    {saveimageData.map((el, index) => {
                      return (
                        <div key={index}>
                          {el.imageurl ? (
                            <Image src={el.imageurl} radius="md" />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <Center
                    h={"100%"}
                    // style={{ minHeight: '200px', minWidth: '150px' }}
                  >
                    <Text c={"#ffff"}>Prediction In Progress</Text>
                    <Space w={"lg"} />
                    <Loader size={30} />
                  </Center>
                )}
              </Grid.Col>
            </Grid>
            {/* <Group style={{display:'flex',justifyContent:'space-between'}}>
              <div>
                <Image src={dataImgdigital} radius='md' />
              </div>

              {saveimageData.length > 50 ?
                <>
                  {saveimageData.map((el, index) => {
                    return (
                      <div key={index}>
                        {el.imageurl ? <Image src={el.imageurl} radius='md' /> : ""}
                      </div>
                    );
                  })}
                </>
                :
                <Center style={{minHeight:'200px',minWidth:'150px'}}>
                  <Space w={"lg"} />
                  <Loader size={30} />
                </Center>
              }
            </Group> */}

            {resUnkown === 0 && (
              <Center>
                <Text c={"#ffff"}>No images uploaded </Text>
              </Center>
            )}
            {/* </SimpleGrid> */}
            <Space h="xl" />
            <Button
              w={"20%"}
              c={"black"}
              align="center"
              onClick={() => {
                navigate(`/${surgerydata[4].route}`)
              }}
            >
              Next Steps
            </Button>
          </Box>
        ) : (
          <Box>
            <Space h={"md"} />
            <Center>
              <Text c={"#ffff"}>Upload your photo</Text>
            </Center>
            <Center>
              <Text c={"#ffff"}>Follow the instructions below:</Text>
            </Center>
            {/* <Space h={"md"} /> */}
            {/* <Space h={"md"} /> */}
            <Stepper active={active} onStepClick={setActive}>
              <Stepper.Step
                c={"#ffff"}
                label="Complete the following and click on submit"
              >
                <ImageCard
                  LabelInfo={LabelText[active]}
                  // setDataImg={set}
                  dataImg={dataImgdigital}
                  storeImgData={setDataImgdigital}
                  resUpdate={setResUnkown}
                />
              </Stepper.Step>
              <Stepper.Completed></Stepper.Completed>
            </Stepper>
            {displayText === 0 && (
              <Group justify="center" mt="xl">
                <Button c={"black"} 
               onClick={nextStep}
                // onClick={() => navigate(`/${surgerydata[4].route}`)}
                >
                  Submit
                </Button>
              </Group>
            )}
            {/* {displayText === 1 && (
              <Center>
                <Text c={"#ffff"}>...Loading Result</Text>
                <Space w={"lg"} />
                <Loader size={30} />
              </Center>
            )} */}
          </Box>
        )}
      </Center>
    </>
  );
};

export default ImageBody;
