export const questions = {
  p1: [
    { title: "1. How often do you experience knee pain?" },
    {
      q1: 0,
    },
    
  ],
  p2: [
    { title: "2. Walking on a flat surface?" },
    {
      q2: 0,
    },

  ],
  p3: [
    { title: "3. Going up or down stairs" },
    {
      q3: 0,
    },
  ],
  p4: [
    { title: "4. Sitting or lying?" },
    {
      q4: 0,
    },
  ],
  p5: [
    { title: "5. Rising from sitting?" },
    {
      q5: 0,
    },
  ],
  p6: [
    { title: "6. Standing?" },
    {
      q6: 0,
    },
  ],
  p7: [
    { title: "7. Getting in/out of a car?" },
    {
      q7: 0,
    },
  ],
  p8: [
    { title: "8. Twisting/pivoting on your injured knee?" },
    {
      q8: 0,
    },
  ],
  p9: [
    { title: "9. How often are you aware of your knee problem" },
    {
      q9: 0,
    },
  ],
  p10: [
    {
      title:
        "10. Have you modified your life style to avoid potentially damaging activities to your knee?",
    },
    {
      q10: 0,
    },
  ],
  p11: [
    {
      title:
        "11. How much are you troubled with lack of confidence in your knee?",
    },
    {
      q11: 0,
    },
  ],
  p12: [
    { title: "12. In general how much difficulty do you have with your knee?" },
    {
      q12: 0,
    },
  ],
};
