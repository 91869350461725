import React, { useState, createContext, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import patientService from '../services/patientService';
import { IconGauge, IconHome, IconSeparator, IconHospital, IconFileAnalytics, IconLayersIntersect, IconMessageUp, IconCarGarage, IconNurse, IconUser, IconCookie, IconLock, IconMessage2, IconStretching, IconActivity, IconHelp, IconUserQuestion, IconPhotoScan, IconAccessPoint } from '@tabler/icons-react';
import PatientPain from '../pages/PatientDailyworkout/PatientPain';
import PatientExercise from '../pages/PatientDailyworkout/PatientExercise';
import PatientRom from '../pages/PatientDailyworkout/PatientRom';
import ImageAIPage from '../pages/ImageAIPage';
import PatientFinalResult from '../pages/PatientDailyworkout/PatientResult';
import moment from 'moment';
export const PatientDailyworkoutContext = createContext();
const PatientDailyworkoutProvider = ({ children }) => {
    const params = useParams()
    console.log(params)
    const navigate = useNavigate()
    // const preSurgery = [
    //     {
    //         icon: IconStretching,
    //         title: "Exercises",
    //         bg: "#AFE0FFFF",
    //         color: "#333",
    //         cmp: <ExercisesContent />,
    //         content: `<div>
    //       <h2 className="subtitle">Recommended exercises everyday 15 days before surgery</h2>
    //       <div className="pointers">
    //         <ul>
    //           <li>Chair Push up <img src="image-placeholder-url" alt="Chair Push up" /></li>
    //           <li>Long Arc Quad <img src="image-placeholder-url" alt="Long Arc Quad" /></li>
    //           <li>Straight Legs Risers <img src="image-placeholder-url" alt="Long Arc Quad" /></li>
    //         </ul>
    //       </div>
    //     </div>`,
    //     },
    //     {
    //         icon: IconNurse,
    //         title: "Medication Guidelines",
    //         bg: "#ECF0C8FF",
    //         color: "#333",
    //         cmp: <MedicationGuidelines />,
    //         content:
    //             "Before you come to the hospital for your surgery you will need Provide a list of any medications you are on to your physician so they may evaluate them and take any action if necessary",
    //     },
    //     {
    //         icon: IconNurse,
    //         title: "Eating Guidelines",
    //         bg: "#EDCDB4FF",
    //         color: "#333",
    //         cmp: <EatingGuidelines />,
    //         content:
    //             "Eat a well-balanced diet with plenty of fiber(such as bran) NO SOLID FOOD AFTER MIDNIGHT BEFORE SURGERY, ONLY WATER",
    //     },
    //     {
    //         icon: IconCarGarage,
    //         title: "Preparing Your Home",
    //         bg: "#C7E4DFFF",
    //         color: "#333",
    //         cmp: <PreparingYourHomeBefore />,
    //         content: "",
    //     },
    //     {
    //         icon: IconLayersIntersect,
    //         title: "Preventing Complications",
    //         bg: "#D7E9DBFF",
    //         color: "#333",
    //         cmp: <PreventingComplications />,
    //         content: "",
    //     },
    //     {
    //         icon: IconFileAnalytics,
    //         title: "Other Tips",
    //         bg: "lightgray",
    //         color: "#333",
    //         cmp: <OtherTips />,
    //         content: "",
    //     },
    // ];
    // const dayBeforeSurgery = [
    //     {
    //         icon: IconHospital,
    //         title: "What to Bring to the Hospital",
    //         bg: "lightgray",
    //         color: "#333",
    //         content: "",
    //         cmp: <WhatToBringToTheHospital />
    //     },
    //     {
    //         icon: IconSeparator,
    //         title: "Prepare Your Self",
    //         bg: "lightgray",
    //         color: "#333",
    //         content: "",
    //         cmp: <PrepareYourSelf />
    //     },
    //     { icon: IconHome, title: "Ride", bg: "lightgray", color: "#333", content: "", cmp: <PrepareYourSelf /> },
    //     {
    //         icon: IconHome,
    //         title: "Preparing Your Home",
    //         bg: "#C7E4DFFF",
    //         color: "#333",
    //         content: "",
    //         cmp: <PreparingYourHomePre />
    //     },
    //     {
    //         icon: IconHome,
    //         title: "What to buy to be ready for surgery",
    //         bg: "lightgray",
    //         color: "#333",
    //         content: "",
    //         cmp: <WhatToBuyForSurgery />
    //     },
    //     {
    //         icon: IconHome,
    //         title: "Other Tips",
    //         bg: "lightgray",
    //         color: "#333",
    //         content: "",
    //         cmp: <OtherTips />
    //     },
    // ];
    const postSurgery = [
        {
            icon: IconGauge,
            title: 'Pain',
            route: `patientdata/physiotherapist/doctor/${params.doctorId}/Nitesh1/${params.patientId}/pain/pain`,
            cmp: <PatientPain />,
            step: 1,
            stepname: 'pain',
            description:
                'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
        },
        // {
        //   icon: IconUser,
        //   title: 'Tracker',
        //   route:"",
        //   description:
        //     'People say it can run at the same speed as lightning striking, Its icy body is so cold, it will not melt even if it is immersed in magma',
        // },
        // {
        //   icon: IconAccessPoint,
        //   title: 'Community Access',
        //   route:"",
        //   description:
        //     'They’re popular, but they’re rare. Trainers who show them off recklessly may be targeted by thieves',
        // },
        // {
        //   icon: IconMessageUp,
        //   title: 'Message your Doctor',
        //   route:"",
        //   description:
        //     'Although it still can’t fly, its jumping power is outstanding, in Alola the mushrooms on Paras don’t grow up quite right',
        // },
        {
            icon: IconStretching,
            title: 'Exercise list',
            route: `patientdata/physiotherapist/doctor/${params.doctorId}/Nitesh1/${params.patientId}/view-exercise/view-exercise`,

            // route: '/patientDashboard/view-exercise',
            cmp: <PatientExercise />,
            step: 2,
            stepname: "view-exercise",
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
        {
            icon: IconActivity,
            title: 'Range of motion',
            route: `patientdata/physiotherapist/doctor/${params.doctorId}/Nitesh1/${params.patientId}/romInfo/romInfo`,

            //    route: '/patientDashboard/romInfo',
            cmp: <PatientRom />,
            step: 3,
            stepname: "romInfo",
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
        // {
        //   icon: IconHelp,
        //   title: 'Help',
        //   route:"",
        //   description:
        //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        // },
        // {
        //   icon: IconUserQuestion,
        //   title: 'Frequently Asked Questions',
        //   route:"",
        //   description:
        //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        // },
        {
            icon: IconPhotoScan,
            title: 'ImageAI',
            route: `patientdata/physiotherapist/doctor/${params.doctorId}/Nitesh1/${params.patientId}/ImageAI/ImageAI`,

            //    route: '/patientDashboard/ImageAI',
            cmp: <ImageAIPage />,
            step: 4,
            stepname: "ImageAI",
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
        {
            icon: IconPhotoScan,
            title: 'Patient Result',
            route: `patientdata/physiotherapist/doctor/${params.doctorId}/Nitesh1/${params.patientId}/result/result`,
            cmp: <PatientFinalResult />,
            step: 5,
            stepname: "result",
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
    ]

    const [steperActive, setSteperActive] = useState(1)
    const [surgerydata, setSurgerydata] = useState([])
    const fetchtype = async () => {
        const responsep = await patientService.getQuestionListbyPatientId(params.patientId)
        if (responsep.statusCode === 200 && responsep.data) {
            const dateOfSurgery = responsep.data.assignquestion[0]?.answer;
            const currentdate = moment()
            const surgeryDate = moment(dateOfSurgery);
            const day = surgeryDate.diff(currentdate, "days");
            const condition =
                day > 0 ? "preSurgery" : "postSurgery";
            if (day === 0) {
                // setTypeSurgery('dayBeforeSurgery')
                setSurgerydata(dayBeforeSurgery)
                // same day surgery condition
            }
            else if (day > 0) {
                // post surgery condition

                // setTypeSurgery('preSurgery')
                setSurgerydata(preSurgery)
            }
            else if (day < 0) {
                // pre surgery condition
                // setTypeSurgery('postSurgery')
                if (surgerydata.length > 0) {

                }
                else {
                    setSurgerydata(postSurgery)
                }
                // navigate(postSurgery[0].route)
            }
        }
        else {
            navigate("/patientDashboard/patient-form")
        }
    }
    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchtype()
        }, 1000)
        return () => {
            clearTimeout(timerId)
        }

    }, [])
    return (
        <PatientDailyworkoutContext.Provider value={{ surgerydata, steperActive, setSteperActive }}>
            {children}
        </PatientDailyworkoutContext.Provider>
    )
};

export default PatientDailyworkoutProvider;
