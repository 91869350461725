import React, { useState, useEffect, useContext } from 'react';
import { Container, Group,Text, Modal, Grid, Image, Center, Title, Box, Button, Space } from '@mantine/core';
import CameraAccessModal from '../components/ROM/CameraAccessModal';
import { useDisclosure } from '@mantine/hooks';
import { BreadCampContext } from '../context/BreadCampContext';
import './RomPage.scss';
import { useNavigate } from 'react-router-dom';
import HookSteper from './PatientDashBoard/Hooks/HookSteper';
import Plyr from "plyr-react";
import "plyr-react/plyr.css"
const RomPage = () => {
    const navigate = useNavigate();
    const { postSurgery } = HookSteper()

    const { setBreadCampList } = useContext(BreadCampContext);
    const [opened, { open, close }] = useDisclosure(false);
    const [noTransitionOpened, setNoTransitionOpened] = useState(false);
    const videoSrc = {
        type: "video",
        sources: [
            {
                src: "https://storage.googleapis.com/exercise-repo/demo-data-temp/left_knee%202.mp4",
                // provider: "vimeo"
            }
        ]
    };
    useEffect(() => {
        setBreadCampList([
            { title: "Patient Guidance", route: "/patientDashboard/guidance" },
            { title: "Range Of Motion", },
        ])
    }, [])
    return (
        <>
            <Container fluid={true} className='rompage_container'
            //  style={{ backgroundImage: `URL('/assets/left-right-design.svg')`, minHeight: "100vh", backgroundRepeat: "no-repeat" }}
            >
                <Grid p={0} style={{ height: '100%', width: '100%' }}>
                    <Grid.Col p={0} span={12} style={{ minHeight: '80vh', width: '100%' }}>
                        <Center style={{ height: '100%', width: '100%' }}>
                            <Box align='center'>
                                <Group>    
                                    <a style={{color:'#4c6ef5',fontSize:"20px",cursor:'pointer'}} onClick={() => setNoTransitionOpened(true)}>Click here....</a><Text c={'#b3f9ff'}>for a demo of Range of Motion</Text>
                                </Group>
                                <Image className='truss_name' src='/assets/company-name.png' />

                                <Space h="md" />
                                <Image className='truss_logo' src='/assets/TrussLogo-blue.png' />
                                <Space h="md" />
                                <Title c={'#b3f9ff'}>Range of Motion</Title>
                                <Space h="md" />
                                <Button onClick={open} size='xl' variant="filled" c="black" radius="xl" onClickCapture={() => navigate(postSurgery[5].route)} >Skip Step</Button>&nbsp;&nbsp;
                                <Button onClick={open} size='xl' variant="filled" c="black" radius="xl">Start Session</Button>
                            </Box>
                        </Center>
                    </Grid.Col>
                </Grid>
            </Container>
            <CameraAccessModal opened={opened} open={open} close={close} />
            <Modal
                opened={noTransitionOpened}
                onClose={() => setNoTransitionOpened(false)}
                centered>
                <Plyr source={videoSrc} />
            </Modal>
        </>
    )
}
export default RomPage;