import {
  Button,
  Center,
  Container,
  Grid,
  Space,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import coordinatorUsersService from "../../../services/CoordinatorUsersService";

const AddNewPatient = () => {
  const navigate = useNavigate();
  const [loading, { toggle }] = useDisclosure();
  const { doctorId } = useParams();

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      password: "",
    },

    validate: {
      name: (value) => (value ? null : "Patient name is required"),
      email: (value) =>
        /^\S+@\S+\.\S+$/.test(value)
          ? null
          : "Invalid email eg. someone@example.com",
      contact: (value) =>
        /^\d{10}$/.test(value) ? null : "Contact must be 10 digits",
      password: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!])[A-Za-z\d@$!]{6,}$/.test(value)
          ? null
          : "Password must be at least 6 characters, include an uppercase letter, a lowercase letter, and one of the following symbols: @, $, !",
    },
  });

  const handleClick = async (e) => {
    e.preventDefault();
    form.validate();

    if (form.isValid()) {
      toggle(true);
      const formData = {
        name: form.values.name,
        email: form.values.email,
        mobileNumber: form.values.contact,
        password: form.values.password,
        doctorId: doctorId,
      };

      await coordinatorUsersService.createPatient(formData);
      toggle(false);
      navigate(`/CoordinatorDashboard/doctorlist/${doctorId}`);
    }
  };

  return (
    <Container my={"30"}>
      <Center>
        <Space h={"md"}>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Patient Name"
                required
                {...form.getInputProps("name")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Email"
                required
                {...form.getInputProps("email")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Contact"
                required
                {...form.getInputProps("contact")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Password"
                required
                {...form.getInputProps("password")}
              />
            </Grid.Col>
          </Grid>
          <Space h={"lg"} />
          <Center>
            <Button
              loading={loading}
              onClick={handleClick}
              style={{ background: "#21A18E", color: "white" }}
            >
              Create
            </Button>
          </Center>
        </Space>
      </Center>
    </Container>
  );
};

export default AddNewPatient;
