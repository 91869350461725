import { useEffect, useState } from "react";

const useCameraAccess = () => {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isCamLoaded, setIsCamLoaded] = useState(true);
  const [isErrorLoadingCam, setIsErroLoadingCam] = useState(false);

  const enableCamera = async () => {
    let cam: MediaStream | null = null;
    try {
      cam = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setIsCamLoaded(true);
      setStream(cam);
      setIsErroLoadingCam(false);
    } catch (error) {
      setStream(null);
      setIsCamLoaded(false);
      setIsErroLoadingCam(true);
    }
  };

  const disableCamera = async () => {
    let cam: MediaStream | null = null;
    try {
      cam = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: false,
      });
      setIsCamLoaded(true);
      setStream(cam);
      setIsErroLoadingCam(false);
    } catch (error) {
      setStream(null);
      setIsCamLoaded(false);
      setIsErroLoadingCam(true);
    }
  };

  useEffect(() => {
    return function () {
      console.log({ stream });
      if (stream) {
        stream.getTracks().forEach((track) => {
          console.log(track);
          setIsCamLoaded(false); //added
          track.stop();
        });
      }
    };
  }, [stream]);

  return {
    stream,
    isCamLoaded,
    isError: isErrorLoadingCam,
    enableCamera,
    disableCamera,
  };
};

export default useCameraAccess;
