import React, { useState, createContext,useContext } from 'react'


 export const PatientSteperContext = createContext();
const PatientSteperProvider = ({children}) => {
    const [steperActive, setSteperActive] = useState(1)
    const [surgerydata,setSurgerydata]=useState([])

    return (
        <PatientSteperContext.Provider value={{ steperActive, setSteperActive,surgerydata,setSurgerydata }}>
            {children}
        </PatientSteperContext.Provider>
    )
};

export default PatientSteperProvider;
