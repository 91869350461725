import React from 'react';
import { Box, Loader, Center } from '@mantine/core';

const LoaderPage = () => {
    return (
        <>
            <Center h='100vh'>
            <Box>   <Loader size={42} /></Box>
            </Center>

        </>
    )
}

export default LoaderPage;