import { AppShell, NavLink } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMantineTheme } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import loginService from '../../../services/loginService';

const docNav = [
  {
    title: "Dashboard",
    path: "/patients/list",
  },
  {
    title: "Patients",
    path: "/patients/list",
  },
  {
    title: "Global Settings Exercises",
    path: "/exercises/create",
  },
  {
    title: "Global  Exercises",
    path: "/exercises/list",
  },
  
  {
    title: "Assign  Exercises for Patients",
    path: "/exercises/addpatients",
  },
];

const adminNav = [
  {
    title: "Providers",
    path: "/providers/list",
  },
  {
    title: "Additional Admin",
    path: "/admin/list",
  },
  {
    title: "Clinical Coordinator",
    path: "/coordinator/list",
  },
  {
    title: "Physiotherapist",
    path: "/physio/list",
  },
  {
    title: "Global Settings Exercises",
    path: "/exercises/create",
  },
  {
    title: "Global  Exercises",
    path: "/exercises/list",
  },
  
  {
    title: "Assign  Exercises for Patients",
    path: "/exercises/addpatients",
  },
  
];

const cordNav= [
  {
    title: "Doctor List",
    path: "/providers/list",
  },
  {
    title: "Patient List ",
    path: "/patient/list",
  },
  {
    title: "Global Settings Exercises",
    path: "/exercises/create",
  },
  {
    title: "Global  Exercises",
    path: "/exercises/list",
  },
  {
    title: "Assign  Exercises for Patients",
    path: "/exercise/addpatients",
  },
]

const Navbar = () => {
  const userType = loginService.getUserType();
  const navConfig = userType === "admin" ? adminNav : docNav;
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <AppShell.Navbar p="md" style={{ gap: '10px', backgroundColor: theme.colors.myblue[0] }}>
      {navConfig.map(navItem => (
        <div key={navItem.title} style={{ display: 'flex', alignItems: 'center' }}>
          <IconUser />
          <NavLink
            label={navItem.title}
            onClick={() => navigate(navItem.path)}
            style={{ margin: '3px' }}
          />
        </div>
      ))}
    </AppShell.Navbar>
  );
};

export default Navbar;
