import { Group, Paper, SimpleGrid, Text } from "@mantine/core";
import {
  IconUserPlus,
  IconDiscount2,
  IconReceipt2,
  IconCoin,
  IconArrowUpRight,
  IconArrowDownRight,
} from "@tabler/icons-react";
import classes from "./StatsGrid.module.scss";
import moment from "moment";
import { useState } from "react";
const icons = {
  user: IconUserPlus,
  discount: IconDiscount2,
  receipt: IconReceipt2,
  coin: IconCoin,
};

const data = [{ title: "Revenue", icon: "receipt", value: "13,456", diff: 34 }];

function StatsGrid({ riskType, surgerydate }) {
  console.log("riskType Check", riskType);
  let BoxColor = "";
  if (riskType === "Moderate Risk") {
    BoxColor = "yellow";
  } else if (riskType === "High Risk") {
    BoxColor = "red";
  } else {
    BoxColor = "green";
  }

  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <>
        <Group>
        {surgerydate ?
          <Group display='block' align="end">
            <Text display="flex" size="lg" c="#b3f9ff">
            Today's Date: {moment(new Date()).format("MMMM Do YYYY")}
            </Text>
            <Text display="flex" size="lg" c="#b3f9ff">
              Surgery Date: {moment(surgerydate).format("MMMM Do YYYY")}
            </Text>
          </Group>
          : ""}
          <Paper
            style={{ backgroundColor: BoxColor }}
            withBorder
            p="md"
            radius="md"
            key={stat.title}
          >
            <Group justify="space-between">
              <Text size="xs" c="dimmed" className={classes.title}>
                Patient Risk Level:
              </Text>
            </Group>
            <Text mt={3} className={classes.value}>
              {riskType ? riskType : ""}
            </Text>
          </Paper>
        </Group>
      </>
    );
  });
  return (
    <div className={classes.root}>
      <SimpleGrid cols={{ base: 1, xs: 1, md: 1 }}>{stats}</SimpleGrid>
    </div>
  );
}

export default StatsGrid;
