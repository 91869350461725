import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class AdminService {
    getAdmins() {
        const option = {
          url: `${baseUrl}/getadminlist`,
        };
    
        return axios.get(option);
      }
      createAdmin(data) {
        console.log("data in admin call is");
        const option = {
          url: `${baseUrl}/createadmin`,
          data,
        };
    
        return axios.post(option);
      }
      deleteAdmin(id) {
        console.log("id is ",id);
        const option = {
          url: `${baseUrl}/admin/${id}`,
          data: { id },
        };
    
        return axios.delete(option);
      }
      getExercise() {
        console.log("get exercise callled");
        const option = {
          url: `${baseUrl}/exercise/global`,
        };
    
        return axios.get(option);
      }
}





const adminService = new AdminService();
export default adminService;