import { Box, Group, Space, Table, Tooltip,Grid } from "@mantine/core";
import CoordinatorPhysioDoc from "./CoordinatorPhysioDoc";
import PhysioService from '../../../services/physioService';
import { useEffect,useContext,useState } from "react";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { coPhysioLanding } from "../../../constants/constants";
import { IconCirclePlus, IconEye } from "@tabler/icons-react";
import ProviderService from '../../../services/providerService';
import { useNavigate } from "react-router";
const CoPhysiotherapistDashboard = () => {
  const navigate=useNavigate()
  const { setBreadCampList } = useContext(BreadCampContext);
  const tableDataHeader = ["Sr No.", "Doctor Name", "Email", "Operations"];
  const [elementsDoctor,setElementsDoctor]=useState([]);
  const [rows,setRows]=useState(null);
  const handleViewPhysio = (e) => {
    // setViewPhysio(true);
    navigate(`${e.id}/assigned-physio-list`);
  };
  const handleClick = (e) => {
    navigate(`/CoordinatorDashboard/physio-list/${e._id}`);
  };
  const FetchPhysioList=async()=>{
    const apicall= await ProviderService.getProviders()
    console.log(apicall)
    if(apicall.statusCode===200){
      const rowss = apicall.data.map((element, idx) => {
        return (
          <Table.Tr key={element.email}>
            <Table.Td>{idx + 1}</Table.Td>
            <Table.Td> {element.name}</Table.Td>
            <Table.Td>{element.email}</Table.Td>
            <Table.Td style={{ margin: "20px" }}>
              <Group>
                <Tooltip label="Assign Physio">
                  <IconCirclePlus
                    onClick={() => handleClick(element )}
                    size={15}
                    color="#90EE90"
                  />
                </Tooltip>
    
                <Tooltip label="View Physio">
                  <IconEye
                    size={15}
                    color="#90EE90"
                    onClick={() => {
                      handleViewPhysio(element);
                    }}
                  />
                </Tooltip>
              </Group>
            </Table.Td>
          </Table.Tr>
        );
      });
      setRows(rowss)
      setElementsDoctor(apicall.data)
    }
    else{

    }
  }
  useEffect(()=>{
    FetchPhysioList()
    setBreadCampList([
      {
        title: "Doctor List",
        route: coPhysioLanding,
      },
    ]);
  },[])
  
  return (
    <Grid>
      <Grid.Col>
        <CoordinatorPhysioDoc elementsDoctor={elementsDoctor} rows={rows} tableHead={tableDataHeader} />
      </Grid.Col>
    </Grid>
  );
};

export default CoPhysiotherapistDashboard;
