import { Group, Code, ScrollArea, rem, Container, Grid } from "@mantine/core";
import {
    IconNotes,
    IconCalendarStats,
    IconGauge,
    IconPresentationAnalytics,
    IconFileAnalytics,
    IconAdjustments,
    IconLock,
} from "@tabler/icons-react";
import { LinksGroup } from "./NavbarLinksGroup";
import classes from "./NavbarNested.module.css";
import {DoctorDashboardmobileview} from '../../../constants/constants';
import { useParams,useLocation } from 'react-router-dom';

// const mockdata = [
//     { label: "Dashboard", icon: IconGauge },
//     { label: "Patients", icon: IconGauge },

//     {
//         label: "Exercise",
//         icon: IconNotes,
//         initiallyOpened: true,
//         links: [
//             { label: "Global Seeting Exercise", link: "/" },
//             { label: "Global Exercise", link: "/" },
//             { label: "Assign Exericse Patients", link: "/" },
//         ],
//     }
// ];
const NavBar = () => {
    const location=useLocation()
    const links = DoctorDashboardmobileview.map((item) => (
        <LinksGroup {...item} key={item.label} location={location} />
    ));
    return (
        <nav className={classes.navbar}>
            <ScrollArea className={classes.links}>
                <div className={classes.linksInner}>{links}</div>
            </ScrollArea>
        </nav>
    )
};
export default NavBar;