import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { Container, Input, Skeleton,Button, TextInput ,Table, Card } from '@mantine/core';
import { Group, Space,Stack , Title } from '@mantine/core';
import { IconSearch, IconEdit, IconTrash } from '@tabler/icons-react'; // Importing icons for edit and delete
import { Pagination } from '@mantine/core';
import adminService from "../services/adminService";
import { filter } from "lodash";
import loginService from "../services/loginService"
import { ActionIcon } from '@mantine/core';


import { IconPencil , IconHttpDelete  } from '@tabler/icons-react';
const AdminPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] =  useState('');
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const filterData = (query) => {
    if(query.length == 1)
    setConfig(configData)
    else{
      const filteredData = filter(configData, (p) => {
        return p.name.includes(query) || p.mobile.includes(query);
        
      });
      setConfig(filteredData);
    }

  };
  
  const addNewConfig = () => {
    navigate("/admin/add", { replace: true });
  };
  const [configData, setConfig] = useState([]);
  
  
  useEffect(() => {
    getAdmins();
  }, []);
 

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
    filterData(event.target.value);
  };
  const getAdmins = async () => {
    try {
      let configData = await adminService.getAdmins();
      setConfig(configData.data);
    } catch (err) {
      console.log(err);
      if (err?.response?.status && err?.response?.status === 401) {
        const path = loginService.checkRoute();
        navigate(path, { replace: true });
      }
    }
  };

  const disableUser = async(e,provider) => {
    try {
      const disableResponse = await adminService.deleteAdmin(provider._id);
    }
    catch(e)
    {
        console.log(e);
    }
  }
  const rows = configData.map((provider, index) => (
   
    <Table.Tr key={index+1} >
      <Table.Td  c={'black'}align="left" >{index+1}</Table.Td>
      <Table.Td c={'black'}>{provider.name}</Table.Td>
      <Table.Td c={'black'}>{provider.email}</Table.Td>
      <Table.Td c={'black'}>{provider.mobile}</Table.Td>

      <Table.Td> 
      
      <Group>    
    <ActionIcon onClick={(e) => disableUser(e, provider)} style={{backgroundColor: "pink"}} variant="filled" aria-label="Settings" id="1">
      <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
    </ActionIcon>
    <ActionIcon  style={{backgroundColor: "red"}} variant="filled" aria-label="Settings" id="1">
      <IconHttpDelete style={{ width: '70%', height: '70%' }} stroke={1.5} />
    </ActionIcon>
    </Group>
      </Table.Td>
   
    </Table.Tr>
  ));

  return (
    <>
     <Helmet>
        <title> Admins | Truss Health </title>
      </Helmet>
      <Container>
        <Group justify="flex-end" gap="xl">
          <Title order={3}>Admins</Title>
          <Space w="xl" />
          <Button variant="default"   onClick={addNewConfig} >Add Admins</Button>
        </Group>
      </Container>

      <Group>
        
        <TextInput
        placeholder="Search..."
      value={searchQuery}
      style={{ margin: '40px 10px' }}
      leftSection={<IconSearch size={16} />}
      onChange={handleSearchChange}
    />
      </Group>
      <Stack>
      <Card style={{ maxWidth: '2090px' }}> {/* Set maxWidth to null */}
    
        <Table fullwidth>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Sr. No</Table.Th>
              <Table.Th>Admin's Name</Table.Th>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Contact Number</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}
          {configData.length === 0 && (
    <tr>
      <td colSpan="4">
        <Skeleton height={50} circle mb="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </td>
    </tr>
  )}</Table.Tbody>
        </Table>
      </Card>
      
      <Pagination total={5} />
      </Stack>
    </>
  );
};

export default AdminPage;
