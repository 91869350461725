import { updateAngle } from "../../utills/angleDetectedSlice";
import { useState, useRef, useEffect  , useContext} from "react";
import Webcam from "react-webcam";
import {
  PoseLandmarker,
  FilesetResolver,
  DrawingUtils,
} from "@mediapipe/tasks-vision";
import { useDispatch, useSelector } from "react-redux";
import LoadModel from "./LoadModel";
import goniometerImageSrc from '../../assets/gonio.png';
import { Modal, Button, Text } from '@mantine/core';
import emitter from "../../eventEmitter";
import VoiceCommandContext from './VoiceCommandContext';

const OpenCam = (prop) => {
  const { displayCam } = prop;
  const enableDetection = useSelector((store) => store.detect.isCaptureStart);
  console.log("enableDetection ", enableDetection);

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [poseLandMarker, setPoseLandMarker] = useState(null);
  const maxAngleRef = useRef(0);
  const minAngleRef = useRef(0);
  const isFirstDetection = useRef(true);
  const countdownModalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const intervalRef = useRef(null);
  const { handleDone } = useContext(VoiceCommandContext);


  const goniometerImageRef = useRef(null);

  //updating the store
  const dispatch = useDispatch();

  //const minAngleUpdate = useSelector((store) => store.angleDetected.isMin);
  //console.log(minAngleUpdate);

  let runningMode = "VIDEO";
  const videoHeight = "480px";
  const videoWidth = "480px";


  const startDetection = () => {
    if (!webcamRef.current || !poseLandMarker) return;
    

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    const drawingUtils = new DrawingUtils(canvasCtx);
    let lastAnimationFrameTime = 0;
    let lastVideoTime = -1;
    // Initialize smoothed positions as null
  let smoothedLeft = null;
  let smoothedRight = null;
    
  const alpha = 0.3; 
  const exponentialSmoothing = (previous, current) => {
    if (!previous) return current;
    return current.map((point, index) => ({
      x: alpha * point.x + (1 - alpha) * (previous[index] ? previous[index].x : point.x),
      y: alpha * point.y + (1 - alpha) * (previous[index] ? previous[index].y : point.y),
      z: alpha * point.z + (1 - alpha) * (previous[index] ? previous[index].z : point.z),
    }));
  };

  let lastVisibilityCheckTime = 0;
  const visibilityCheckInterval = 5000; // Check every 5 seconds
  let visibilityModalShown = false;
  
  function isSetVisiblySufficient(landmarks, requiredIndices, visibilityThreshold = 0.9) {
    if (!Array.isArray(landmarks) || landmarks.length === 0) {
      //console.error("Invalid or empty landmarks array passed to isSetVisiblySufficient");
      return false; // Or handle this scenario appropriately
    }
  
    const visibleLandmarks = requiredIndices.map(index => landmarks[index]).filter(lm => lm && lm.visibility >= visibilityThreshold);
    return visibleLandmarks.length / requiredIndices.length >= 0.8;
  }
  
  
  function showVisibilityModal(show) {
    let modal = document.getElementById('landmarkVisibilityModal');
    if (!modal) {
      modal = document.createElement('div');
      modal.id = 'landmarkVisibilityModal';
      modal.style.display = 'none'; // Initially hidden
      modal.style.position = 'fixed';
      modal.style.left = '0';
      modal.style.top = '0';
      modal.style.width = '100%';
      modal.style.height = '100%';
      modal.style.backgroundColor = 'rgba(0,0,0,0.5)';
      modal.style.display = 'flex';
     // modal.style.justifyContent = 'center';
     // modal.style.alignItems = 'center';
      modal.style.zIndex = '1000'; // Ensure it's on top
  
      const modalContent = document.createElement('div');
      modalContent.style.backgroundColor = 'rgba(19, 97, 99, 0.5)';
      modalContent.style.margin = 'auto';
      modalContent.style.top = '10%'
      modalContent.style.padding = '20px';
      modalContent.style.border = '1px solid #888';
      modalContent.style.width = 'auto';
      modalContent.style.display = 'flex';
     // modalContent.style.justifyContent = 'center';
      modalContent.style.alignItems = 'center';
      modalContent.style.borderRadius = '20px';
  
      const modalText = document.createElement('p');
      modalText.innerText = 'Please make sure Shoulder , Hip , Knee and ankle are visible in the frame';
      modalText.style.color = '#ffffff'; 
      modalContent.appendChild(modalText);
  
      modal.appendChild(modalContent);
      document.body.appendChild(modal);
    }
  
    // Update the visibility of the modal based on the 'show' parameter
    modal.style.display = show ? 'flex' : 'none';
    visibilityModalShown = show;
    
  }
    const renderLoop = (currentTime) => {
      const deltaTime = currentTime - lastAnimationFrameTime;
      if (deltaTime < 1000 / 30) { 
        requestAnimationFrame(renderLoop);
        return;
      }
      lastAnimationFrameTime = currentTime;

      const video = webcamRef.current.video;
      //const video = document.getElementById("webcam");
      if (!video || video.readyState !== 4) {
        requestAnimationFrame(renderLoop);
        return;
      }

      if (video.currentTime === lastVideoTime) {
        requestAnimationFrame(renderLoop);
        return;
      }
      lastVideoTime = video.currentTime;

      poseLandMarker.detectForVideo(video, currentTime, (result) => {
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        if (!result || result.length === 0) {
          requestAnimationFrame(renderLoop);
          return;
        }
                let left = [];
                let right = [];

                const b = result.landmarks;
                const landmarks = result.landmarks[0]; 
                //console.log(landmarks)

                const leftIndices = [23, 25, 27];
                const rightIndices = [24, 26, 28];
                const requiredLandmarksRight = [0, 12, 24, 26, 28]; // Right side
                const requiredLandmarksLeft = [0, 11, 23, 25, 27]; // Left side
                
                const isRightSufficientlyVisible = isSetVisiblySufficient(landmarks, requiredLandmarksRight);
                const isLeftSufficientlyVisible = isSetVisiblySufficient(landmarks, requiredLandmarksLeft);
                
                if ((isRightSufficientlyVisible || isLeftSufficientlyVisible) && visibilityModalShown) {
                  showVisibilityModal(false);
                  lastVisibilityCheckTime = currentTime;
                } else if (!(isRightSufficientlyVisible || isLeftSufficientlyVisible)) {
                  showVisibilityModal(true);
                  lastVisibilityCheckTime = currentTime; // Update the last check time
                  requestAnimationFrame(renderLoop);
                  return;
                }
                
             
                if (b.length > 0) {
                  left = b[0].filter((x, idx) => leftIndices.includes(idx));
                  right = b[0].filter((x, idx) => rightIndices.includes(idx));
                }
                if (left.length === 0 && right.length === 0) {
                  requestAnimationFrame(renderLoop);
                  return;
                }
                
                smoothedLeft = exponentialSmoothing(smoothedLeft, left);
                smoothedRight = exponentialSmoothing(smoothedRight, right);

                const averageZLeft = smoothedLeft.reduce((acc, curr) => acc + curr.z, 0) / smoothedLeft.length;
                const averageZRight = smoothedRight.reduce((acc, curr) => acc + curr.z, 0) / smoothedRight.length;
                let legToDraw = averageZLeft < averageZRight ? smoothedLeft : smoothedRight;
                
                //const averageZLeft = left.reduce((acc, curr) => acc + curr.z, 0) / left.length;
                //const averageZRight = right.reduce((acc, curr) => acc + curr.z, 0) / right.length;
                //let legToDraw = averageZLeft < averageZRight ? left : right;
                let pt1 = legToDraw[0]; // Hip
                let pt2 = legToDraw[1]; // Knee
                let pt3 = legToDraw[2]; // Ankle

                function dist(p1, p2) {
                  return Math.sqrt(
                    Math.pow(p1.x - p2.x, 2) +
                      Math.pow(p1.y - p2.y, 2) +
                      Math.pow(p1.z - p2.z, 2)
                  );
                }

                function radians_to_degrees(radians) {
                  return radians * (180 / Math.PI);
                }

                function find_angle(a, b, c) {
                  const ab = dist(a, b);
                  const bc = dist(b, c);
                  const ac = dist(a, c);

                  const angle =
                    (Math.pow(ab, 2) + Math.pow(bc, 2) - Math.pow(ac, 2)) /
                    (2 * ab * bc);
                  return radians_to_degrees(Math.acos(angle));
                }
                function calculateDistance(point1, point2) {
                  const dx = point1.x - point2.x;
                  const dy = point1.y - point2.y;
                  return Math.sqrt(dx * dx + dy * dy);
                }
                

                function calculateKneeAngle(pt1, pt2, pt3) {
                  const vectorThigh = {x: pt2.x - pt1.x, y: pt2.y - pt1.y, z: pt2.z - pt1.z};
                  const vectorCalf = {x: pt3.x - pt2.x, y: pt3.y - pt2.y, z: pt3.z - pt2.z};
                  const dotProduct = vectorThigh.x * vectorCalf.x + vectorThigh.y * vectorCalf.y + vectorThigh.z * vectorCalf.z;
                  const magnitudeThigh = Math.sqrt(vectorThigh.x ** 2 + vectorThigh.y ** 2 + vectorThigh.z ** 2);
                  const magnitudeCalf = Math.sqrt(vectorCalf.x ** 2 + vectorCalf.y ** 2 + vectorCalf.z ** 2);

                  const angleRadians = Math.acos(dotProduct / (magnitudeThigh * magnitudeCalf));
                  const angleDegrees = radians_to_degrees(angleRadians);

                  return angleDegrees;
                }

                let angle_c = calculateKneeAngle(pt1, pt2, pt3);
               // let angle_c = find_angle(pt1, pt2, pt3);

              { /* if (angle_c >= 0 && angle_c <= 180) {
                const currentROM = angle_c; 
                
                // Dispatch to angledetected.js
                dispatch(updateAngle({ currentROM }));
                //console.log(currentROM)
              }
              */
            }
            if (angle_c >= 0 && angle_c <= 180) {
              const currentROM = angle_c;
              if (isFirstDetection.current) {
                minAngleRef.current = Math.round(currentROM);
                maxAngleRef.current = 0;
                console.log(minAngleRef)
                isFirstDetection.current = false; // Update flag to indicate the first angle has been captured
              } else {
                if (currentROM < minAngleRef.current) {
                // For subsequent detections, only update if a new lower angle is detected
                minAngleRef.current = Math.round(currentROM);
              }
               const adjustedROM = currentROM - minAngleRef.current;
               //console.log(adjustedROM);
              if (adjustedROM > maxAngleRef.current) {
                //console.log("Previous max", maxAngleRef.current);
               // console.log("Current", currentROM);
                maxAngleRef.current = Math.round(adjustedROM);
              }
            }
            //dispatch(updateAngle({ currentROM }));
            //dispatch(updateAngle({ currentROM: maxAngleRef.current.toFixed(2) }));
            dispatch(updateAngle({ currentROM: Number(maxAngleRef.current.toFixed(2)) }));

            const scaleX = canvasRef.current.width;
            const scaleY = canvasRef.current.height;
            const textX = pt2.x * scaleX;
            const textY = Math.max(pt2.y * scaleY, 20) - 10; 
            //console.log(`Drawing Max Angle: ${maxAngleRef.current.toFixed(2)} at (${textX}, ${textY})`);
            canvasCtx.fillStyle = "white";
            canvasCtx.font = "16px Arial";
            canvasCtx.fillText(` ${Number(maxAngleRef.current.toFixed(2))}`, textX, textY);
            if (goniometerImageRef.current) {
              const kneeX = pt2.x * scaleX;
              const kneeY = pt2.y * scaleY;
              const maxSize = 50; 
              const naturalWidth = goniometerImageRef.current.naturalWidth;
              const naturalHeight = goniometerImageRef.current.naturalHeight;
              const aspectRatio = naturalWidth / naturalHeight;
              let imageWidth, imageHeight;
              if (naturalWidth > naturalHeight) {
                  // Image is wider than it is tall
                  imageWidth = maxSize;
                  imageHeight = maxSize / aspectRatio;
              } else {
                  // Image is taller than it is wide, or square
                  imageHeight = maxSize;
                  imageWidth = maxSize * aspectRatio;
              }
              const adjustedX = kneeX - imageWidth / 2;
              const adjustedY = kneeY - imageHeight / 2;
          
              canvasCtx.drawImage(goniometerImageRef.current, adjustedX, adjustedY, imageWidth, imageHeight);
          }
          
            }
            
{/*
                drawingUtils.drawLandmarks(legToDraw, {
                  radius: (data) => DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1),
                  color: 'red', 
                });
              */  }
                
                drawingUtils.drawConnectors(legToDraw, PoseLandmarker.POSE_CONNECTIONS, {
                  radius: 2,
                  lineWidth:1, 
                  color: 'green', 
                });
              
                canvasCtx.restore();
              });
              
              requestAnimationFrame(renderLoop);
            };
        
            requestAnimationFrame(renderLoop);
          };

        useEffect(() => {
          if (enableDetection && poseLandMarker) {
            console.log("Countdown to detection starting...");
            let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognition = new SpeechRecognition();
            recognition.continuous = true; 
            recognition.lang = 'en-US'; 

            const commands = {
              //'start capture': () => startDetection(),
              'start capture': () => {
                startDetection();
                document.getElementById('countdownModal').style.display = "none"; 
              },
              'reset': () => resetTimer(), 
              'stop': () => pauseTimer(), 
              'done': () => handleDone(),
            }

            recognition.start();

                  recognition.onresult = (event) => { 
                    
                    const lastResult = event.results[event.resultIndex];
                    const command = lastResult[0].transcript.trim().toLowerCase();
                    console.log("Heard:", command); 

                    if (commands[command]) {
                      commands[command]();
                      console.log(command);
                    } else {
                      console.log(`Command "${command}" not recognized.`);
                    }
                  };

          recognition.onerror = (event) => {
           // console.error('Speech recognition error', event.error);
          };

          recognition.onend = () => {
            recognition.start();
          };
                  
            let countdownValue = 10; 
            let paused = false;
            let intervalId;
        
            const updateCountdown = () => {
              if (!paused) {
                countdownValue -= 1;
                document.getElementById('countdown').textContent = countdownValue;
            
                const circle = document.getElementById('countdownCircle').querySelector('path');
                const percentage = (countdownValue / 10) * 100;
                circle.style.strokeDasharray = `${percentage}, 100`;
            
                if (countdownValue <= 0) {
                  clearInterval(intervalId);
                  intervalId = null;
                  document.getElementById('countdownModal').style.display = "none";
                  console.log("Detection starting...");
                  startDetection();
                }
              }
            };
            
        
            // Function to initialize event listeners
            const initializeEventListeners = () => {
              const startTimerButton = document.getElementById('startTimerButton');
              const pauseTimerButton = document.getElementById('pauseTimerButton');
              const resetTimerButton = document.getElementById('resetTimerButton');
              const startCaptureButton = document.getElementById('startCaptureButton');
              const countdownContainer = document.getElementById('countdownContainer');
        
              startTimerButton.addEventListener('click', () => {
                countdownContainer.style.display = "block"; // Show the countdown
                startTimerButton.style.display = "none"; // Hide start button
                pauseTimerButton.style.display = "block"; // Show pause button
                resetTimerButton.style.display = "block"; // Show reset button
                intervalId = setInterval(updateCountdown, 1000); // Start countdown
              });
              
        
              pauseTimerButton.addEventListener('click', () => {
                paused = !paused; // Toggle paused state
                pauseTimerButton.textContent = paused ? "Resume Timer" : "Pause Timer";
              });
        
              resetTimerButton.addEventListener('click', () => {
                countdownValue = 10; // Reset countdown
                document.getElementById('countdown').textContent = countdownValue;
              
                // Calculate and reset the circular bar to full
                const circle = document.getElementById('countdownCircle').querySelector('path');
                circle.style.strokeDasharray = `100, 100`;
              
                if (paused) {
                  paused = false;
                  pauseTimerButton.textContent = "Pause Timer";
                }
                // Clear any existing interval to prevent multiple intervals running
                if (intervalId) {
                  clearInterval(intervalId);
                }
                // Add a one-second delay before starting the countdown
                setTimeout(() => {
                  intervalId = setInterval(updateCountdown, 1000); // Restart countdown with delay
                }, 1000);
              });
              
        
              startCaptureButton.addEventListener('click', () => {
                document.getElementById('countdownModal').style.display = "none";
                startDetection();
              });
            };
            const resetTimer = () => {
              countdownValue = 10; // Reset to initial value or whatever your starting value is
              document.getElementById('countdown').textContent = countdownValue;
              // Reset the stroke-dasharray to full
              const circle = document.getElementById('countdownCircle').querySelector('path');
              circle.style.strokeDasharray = `100, 100`;
              // Restart countdown if paused or stopped
              if (paused) paused = false; // Ensure timer is not paused
              if (!intervalId) { // If interval is not set, restart it
                intervalId = setInterval(updateCountdown, 1000);
              }
              console.log("Timer reset");
            };
            
            const pauseTimer = () => {
              paused = !paused; // Toggle pause state
              console.log(`Timer ${paused ? "paused" : "resumed"}`);
            };
            const adjustModalSizeAndPosition = () => {
              const modal = document.getElementById('countdownModal');
              if (modal && canvasRef.current) {
                const rect = canvasRef.current.getBoundingClientRect();
                const scrollTop = window.scrollY || document.documentElement.scrollTop;
                const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
                modal.style.top = `${rect.top + scrollTop}px`; // Adjust for vertical scroll
                modal.style.left = `${rect.left + scrollLeft}px`; // Adjust for horizontal scroll
                modal.style.width = `${rect.width}px`;
                modal.style.height = `${rect.height}px`;
              }
            };
            

               // Debounce function to limit how often the resize function is called
               const debounce = (func, wait) => {
                let timeout;
  
                return function executedFunction(...args) {
                  const later = () => {
                    clearTimeout(timeout);
                    func(...args);
                  };
  
                  clearTimeout(timeout);
                  timeout = setTimeout(later, wait);
                };
              };
  
              const debouncedAdjustModal = debounce(adjustModalSizeAndPosition, 100);
  
              // Add resize event listener to adjust modal on window resize
              window.addEventListener('scroll', adjustModalSizeAndPosition);
              window.addEventListener('resize', adjustModalSizeAndPosition);
             // window.addEventListener('resize', debouncedAdjustModal);


            const createModalContent = () => {
              if (!document.getElementById('countdownModal')) {
                const modal = document.createElement("div");
                modal.id = "countdownModal";
                const canvas = canvasRef.current;
                const rect = canvas.getBoundingClientRect();
                modal.style.cssText = `
                    position: absolute;
                    top: ${rect.top}px;
                    left: ${rect.left}px;
                    z-index: 1000;
                    padding: 20px 30px;
                    background: rgba(178, 242, 187, 0.1); 
                    justify-content: space-between;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    text-align: center;
                    height: ${rect.height}px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width:${rect.width}px;
                    justify-content: space-between;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);`;

                    modal.innerHTML = `
                    <div style="flex-grow: 1; display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;">
                        <div id="countdownContainer" style="position: relative; width: 150px; height: 150px; border-radius: 50%; background-color: rgba(0,0,0,0.1); display: flex; justify-content: center; align-items: center; margin-top:40px">
                            <svg id="countdownCircle" viewBox="0 0 36 36" style="transform: rotate(-90deg); width: 100%; height: 100%;">
                                <path d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831 15.9155 15.9155 0 0 1 0 -31.831" stroke="#4caf50" stroke-width="1" fill="none" stroke-dasharray="100, 100"></path>
                            </svg>
                            <span id="countdown" style="font-size: 40px; color: white; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">10</span>
                        </div>
                        <div style="display: flex; justify-content: space-around; width: 100%; margin-top: auto; margin-bottom: 5px;">
                            <button id="startTimerButton" style="flex: 1; background-color: rgba(23,81,126,0.5); color: white; border: none; padding: 10px 20px; border-radius: 5px; margin: 0 5px;">Start Timer</button>
                            <button id="pauseTimerButton" style="flex: 1; display:none; background-color: rgba(23,81,126,0.5); color: white; border: none; padding: 10px 20px; border-radius: 5px; margin: 0 5px;">Hold Timer</button>
                            <button id="resetTimerButton" style="flex: 1; display:none; background-color: rgba(23,81,126,0.5); color: white; border: none; padding: 10px 20px; border-radius: 5px; margin: 0 5px;">Reset Timer</button>
                            <button id="startCaptureButton" style="flex: 1; background-color: rgba(23,81,126,0.5); color: white; border: none; padding: 10px 20px; border-radius: 5px; margin: 0 5px;">Start Capture</button>
                        </div>
                    </div>
                `;
                
                
                document.body.appendChild(modal);

                initializeEventListeners(); // Initialize buttons' event listeners
              }
            };
        
            // Create modal and initialize everything
            createModalContent();
            
        
            return () => {
            //  recognition.stop();
             // recognition.onend = null; 

             if (recognition) {
              console.log("stopping voice..........")
              recognition.stop();  
              recognition.onend = null;  
              recognition.current = null;
            }
        
              if (intervalId) {
                clearInterval(intervalId);
              }
              const modal = document.getElementById('countdownModal');
              if (modal) {
                modal.remove();
              }
              window.removeEventListener('resize', debouncedAdjustModal); 
              window.removeEventListener('scroll', adjustModalSizeAndPosition);
              //window.location.reload();
              //console.log("Resources cleaned up!");
              //window.removeEventListener('resize', adjustModalSizeAndPosition);
            };
          }
        }, [enableDetection, poseLandMarker]);
        
        
          useEffect(() => {
            const img = new Image();
            img.onload = () => {
              goniometerImageRef.current = img;
              console.log('Goniometer image loaded');
            };
            // Use the imported src
            img.src = goniometerImageSrc;
          }, []);


  if (displayCam) {
    return (
      <div id="start"  style={{width:'100%',height:'100%',position:"relative"}}
      >
        {poseLandMarker === null && (
          <LoadModel setPoseLandMarker={setPoseLandMarker} />
        )}
        <Webcam
          id="webcam"
          onUserMedia={(stream) => {
            if (stream.active) {
            }
          }}
          ref={webcamRef}
          style={{
            textAlign: "center",
            zIndex: 9,
          }}
          className="box-border m-2 p-2"
        ></Webcam>
        <canvas
          id="output_canvas"
          ref={canvasRef}
          className="box-border m-2 p-2"
          style={{
            position: "absolute",
            marginRight: "auto",
            marginLeft: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zIndex: 8,
            height:'100%',
            width:"100%",
          }}
        ></canvas>
      </div>
    );
  }
};

export default OpenCam;
