import React, { useEffect, useState } from "react";

const YesNo = ({ options, handleChange }) => {
  const [listObj, setListObj] = useState(options);

  function setKeyTo(key, value) {
    setListObj({ ...listObj, [key]: value });
  }

  useEffect(() => {
    handleChange(listObj);
  }, [listObj]);

  return (
    <div className="yesno-inputs">
      {Object.entries(listObj)?.map(([key, val], i) => {
        return (
          <div key={i} onClick={() => setKeyTo(key, !val)}>
            <p style={{color:"#ffff"}}>{key}</p>
            <button className={`btn ${listObj[key] ? "active" : ""}`}>
              Yes
            </button>
            <button className={`btn ${listObj[key] ? "" : "active"}`}>
              No
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default YesNo;
