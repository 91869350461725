import { FilesetResolver, PoseLandmarker } from "@mediapipe/tasks-vision";
import { useEffect, useState } from "react";

const LoadModel = (props) => {
  const { setPoseLandMarker } = props;
  let runningMode = "VIDEO";

  const loadVision = async () => {
    console.log("start of loads");
    const vision = await FilesetResolver.forVisionTasks(
      // path/to/wasm/root
      // "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
      "/assets/models/wasm"
    );

    const poseLandmarkerVar = await PoseLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: "/assets/models/pose_landmarker_lite.task",
        
      },
      SMOOTH_LANDMARKS:true,
      runningMode: runningMode,
      numPoses: 1,
      minDetectionConfidence: 0.8,
      minTrackingConfidence:0.8,
      minPoseDetectionConfidence:0.8,
      minPosePresenceConfidence:0.8,
      //result_callback:ResultListener
    });

    setPoseLandMarker(poseLandmarkerVar);
  };

  useEffect(() => {
    loadVision();
  }, []);

  return <></>;
};

export default LoadModel;