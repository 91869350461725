import {
  Button,
  Center,
  FileInput,
  Group,
  Paper,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { IconPhoto, IconVideo } from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import exerciseservice from "../services/exercise";

const Exercise = () => {
  const icon = (
    <IconPhoto style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );
  const iconv = (
    <IconVideo style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );
  const [exerciseName, setExerciseName] = useState("");
  const photoRef = useRef(null);
  const videoRef = useRef(null);

  const handleUpload = async () => {
    if (!exerciseName || !photoRef.current || !videoRef.current) {
      alert("Please fill in all fields");
      return;
    }

    // Check if files are selected
    if (!photoRef.current.files.length || !videoRef.current.files.length) {
      alert("Please select a photo and a video.");
      return;
    }

    const formData = new FormData();
    formData.append("image", photoRef.current.files[0]); // Access the first file
    formData.append("video", videoRef.current.files[0]); // Access the first file
    formData.append("name", exerciseName);

    try {
      await exerciseservice.createExercises(formData);
      alert("Exercise created successfully!");
      // Optionally, redirect or perform any other action after successful upload
    } catch (error) {
      console.error("Error creating exercise:", error);
      alert("Failed to create exercise. Please try again.");
    }
  };

  return (
    <Center>
      <Stack>
        <Paper
          withBorder
          shadow="lg"
          radius={"md"}
          p={"xl"}
          bg="white"
          w="100vh"
          h="60vh"
        >
          <Group>
            <Center>
              {" "}
              <Text c="black">
                Create Exercise by Uploading video and Image{" "}
              </Text>
            </Center>

            <Paper
              radius={"md"}
              p={"xl"}
              w={"100%"}
              withBorder
              variant="dashed"
            >
              <TextInput
                value={exerciseName}
                onChange={(event) => setExerciseName(event.currentTarget.value)}
                placeholder="Enter Exercise Name"
                required
                styles={{
                  label: { color: "blue" },
                  inner: { fontSize: 20 },
                }}
              />
              <FileInput
                ref={photoRef}
                leftSection={icon}
                accept="image/*"
                label="Upload Photo For Exercise"
                placeholder="Attach Photo For Exercise Here"
                leftSectionPointerEvents="none"
                required="true"
                styles={{
                  label: { color: "blue" },
                  inner: { fontSize: 20 },
                }}
              />
              <FileInput
                ref={videoRef}
                leftSection={iconv}
                label="Upload Video For Exercise"
                placeholder="Attach Video For Exercise Here"
                rightSectionPointerEvents="none"
                mt="md"
                accept=".mov,.mp4"
                required
                styles={{
                  label: { color: "blue" },
                  inner: { fontSize: 20 },
                }}
              />
            </Paper>
            <Center></Center>
            <Button>Cancel</Button>
            <Button onClick={handleUpload}>Upload</Button>
          </Group>
        </Paper>
      </Stack>
    </Center>
  );
};

export default Exercise;
