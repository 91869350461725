import { Button, MantineProvider, createTheme, MantineThemeProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "./App.scss";

import RouterCmp from "./routes";
import classes from "./styles/button.module.css";
import { Provider } from "react-redux";
import store from "./utills/store";
import { myblue } from "./themes/palette";
import { ModalsProvider } from '@mantine/modals';
import '@mantine/carousel/styles.css';
const theme = createTheme({
  components: {
    Button: Button.extend({
      classNames: classes,
    }),
    TextInput: {
      styles: {
        height: 2000,
      },
    },
  },
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },

  colors: {
    myblue,
  },
});

function App() {
  return (
    <Provider store={store}>
        {/* <MantineThemeProvider> */}
        <MantineProvider theme={theme}>
          <ModalsProvider>
            <RouterCmp />
          </ModalsProvider>
        </MantineProvider>
      {/* </MantineThemeProvider> */}


    </Provider >

  );
}

export default App;
