import React, { useEffect, useState } from 'react';
import { LineChart } from '@mantine/charts';
import moment from 'moment';
const ViewPainGraph=(props)=>{
    const {graphData}=props
    console.log(graphData)
    const [data,setData]=useState([])
    useEffect(()=>{
      if(graphData.length>0){
        const array=[]
        graphData.forEach((de)=>{
          if(de.paindata.length>0){
            de.paindata.forEach((es)=>{
              array.push({date:moment(es.createdAt).format('DD/MM/YY'),score:es.score? es.score :0 })
          })
          }
        })
        setData(array)
        console.log(array)
    }
    },[])
     const datas = [
        // {
        //   date: 'Mar 22',
        //   score: 93.45,
        //   angleDifference: 86.35,
        //   angleAtRest: 0,
        // },
        // {
        //     date: 'Mar 22',
        //     activeMotion: 100.45,
        //     angleDifference: 186.35,
        //     angleAtRest: 0,
        //   },
        // {
        //   date: 'Mar 23',
        //   activeMotion: 2756,
        //   angleDifference: 2103,
        //   angleAtRest: 2402,
        // },
        // {
        //   date: 'Mar 24',
        //   Apples: 3322,
        //   Oranges: 986,
        //   Tomatoes: 1821,
        // },
        // {
        //   date: 'Mar 25',
        //   Apples: 3470,
        //   Oranges: 2108,
        //   Tomatoes: 2809,
        // },
        // {
        //   date: 'Mar 26',
        //   Apples: 3129,
        //   Oranges: 1726,
        //   Tomatoes: 2290,
        // },
      ];
    return (
        <>
        {data.length>0 ?
        <LineChart
        h={150}
        data={data}
        dataKey="date"
        tickLine="xy"
        yAxisProps={{ tickMargin: 15, orientation: 'right' }}
        xAxisProps={{ tickMargin: 15, orientation: 'top' }}
        series={[
          { name: 'score', color: 'indigo.6' },
          // { name: 'angleDifference', color: 'red' },
          // { name: 'angleAtRest', color: 'teal.6' },
        ]}
      />
      :""}
        </>
    )
}

export default ViewPainGraph;