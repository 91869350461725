import { Fragment } from "react";
import DashboardLayout from "./layouts/dashboard/DashboardLayout/DashBoardLayout";
import AddAdmin from "./pages/AddAdmin";
import AddEditCoordinator from "./pages/AddEditCoordinator";
import AddEditProvider from "./pages/AddEditProvider";
import AdminLoginPage from "./pages/AdminLogin";
// import CoordinatorLogin from "./pages/CoordinatorLogin";
import CoordinatorPage from "./pages/CoordinatorPage";
import Home2 from "./pages/Home2";
import Page404 from "./pages/Page404";
import PatientLogin from "./pages/PatientLogin";
import ProviderLogin from "./pages/ProviderLogin";
import AdminPage from "./pages/adminPage";
import ProviderPage from "./pages/providerPage";
import UserPage from "./pages/userPage";
// import AddEditPatient from "./pages/AddEditPatient";
import AddEditPatient from "./pages/DoctorDashBoard/List/CreatePatient";
import PatientPage from "./pages/PatientPage";
import PhysioLogin from "./pages/PhysioLogin";
import PhysioPage from "./pages/PhysioPage";
import AddEditPhysio from "./pages/AddEditPhysio";
import ImageAIPage from "./pages/ImageAIPage";
import PatientForm from "./pages/patient/PatientForm";
import Guidance from "./pages/PatientDashBoard/Guidance";
import Exercise from "./pages/Exercise";
import PatientPain from "./pages/PatientPain";
import ExerciseList from "./pages/ExerciseList";
import AddexercisePatient from "./pages/patient/AddexercisePatient";
import PatientExercises from "./pages/patient/PatientExercise";
import RomPage from "./pages/RomPage";
import RomSession from "./components/ROM/romSession";
import PatientDashBoard from "./pages/PatientDashBoard/PatientDashBoard";
import DoctorDashboard from "./pages/DoctorDashBoard/DoctorDashBoard";
import PatientList from "./pages/DoctorDashBoard/List/PatientList";
import SettingExercise from "./pages/DoctorDashBoard/Exercise/SeetingExercise/SettingExercise";
import EditFile from "./components/CrudForm/EditFile";
import ExerciseDetail from "./pages/DoctorDashBoard/Exercise/SeetingExercise/ExerciseDetail/ExerciseDetail";
import PatientAssignExercise from "./pages/DoctorDashBoard/Exercise/PatientAssignExercise/PatientAssignExercise";
import OverViewDoctor from "./pages/DoctorDashBoard/Overview/OverView";
import PatientDetailOverview from "./pages/DoctorDashBoard/Overview/PatientDetailOverview/PatientDetailOverview";
import ThermalPage from "./pages/ThermalPage";
// ----------------------------------------------------------------------
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import CoordindatorDashboard from "./pages/CoordinatorDashboard/CoordindatorDashboard";
import CoordinatorLogin from "./pages/CoordinatorDashboard/CoordinatorLogin";
import OverViewCoordinator from "./pages/CoordinatorDashboard/Overview/OverViewCoordinator";
import CoordinatorPatientList from "./pages/CoordinatorDashboard/List/PatientList";
import CoordinatorAssignExercise from "./pages/CoordinatorDashboard/Exercise/PatientAssignExercise/PatientAssignExercise";
import PatientDetailOverviewCoordinator from "./pages/CoordinatorDashboard/Overview/PatientDetailOverview/PatientDetailOverview";
import CoordinatorAddEditPatient from "./pages/CoordinatorDashboard/List/CreatePatient";
import SettingExerciseCoordinator from "./pages/CoordinatorDashboard/Exercise/SettingExercise/SettingExercise";
import DoctorOverviewBody from "./pages/CoordinatorDashboard/DoctorOverview/DoctorOverviewBody";
import {
  PhysioLanding,
  coordinatorExerciseDetail,
  physioGuidanceDefault,
  routePhysioFeaturePatientImageAi,
  routePhysioFeaturePatientPain,
  routePhysioFeaturePatientRangeOfMotion,
  routePhysioPatientOverview,
  routePhysiotherapistDoctorTab,
} from "./constants/constants";
import CoordinatorViewExerciseDetails from "./pages/CoordinatorDashboard/Exercise/SettingExercise/ExerciseDetail/ExerciseDetail";
import PatientListOverviewCo from "./pages/CoordinatorDashboard/DoctorOverview/PatientListOverview";
import AddEditPatientCoordinator from "./pages/CoordinatorDashboard/List/CreatePatient";
import AddNewPatient from "./pages/CoordinatorDashboard/DoctorOverview/AddNewPatient";
import PatientResult from "./pages/PatientDashBoard/PatientResult";

//physiotherapist
import PhysiotherapistLogin from "./pages/PhysiotherapistDashboard/PhysiotherapistLogin";
import PhysiotherapistDashboard from "./pages/PhysiotherapistDashboard/PhysiotherapistDashboard";
import PhysioDoctorOverviewBody from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PhysioDoctorOverviewBody";
import PhysioPatientListOverview from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PhysioPatientListOverview";
import PatientDataOverview from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PatientDataOverview/PatientDataOverview";
import CoPhysiotherapistDashboard from "./pages/CoordinatorDashboard/PhysiotherapistOverview/CoPhysiotherapistDashboard";
import CoPhysioList from "./pages/CoordinatorDashboard/PhysiotherapistOverview/CoPhysioList";
import CoViewPhysio from "./pages/CoordinatorDashboard/PhysiotherapistOverview/CoViewPhysio";
import GuidanceMain from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PatientDataCollection/GuidanceMain";

// import PatientResult from "./pages/PatientDashBoard/PatientResult";
import AdminList from "./pages/AdminDashboard/List/AdminList";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import ProviderList from "./pages/AdminDashboard/List/ProviderList";
import CoordinatorList from "./pages/AdminDashboard/List/CoordinatorList";
import PhysioList from "./pages/AdminDashboard/List/PhysioList";
import PatientDetailDashboard from "./pages/PatientDashBoard/PatientDetailOverview/PatientDetailOverview";
import PainBody from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PatientDataCollection/ServiceFunctionality/Pain/PainBody";
import RangeOfMotionForm from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PatientDataCollection/ServiceFunctionality/PhysioRangeOfMotion/RangeOfMotionForm";
import PhysioPatientDataMain from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PatientDataCollection/PhysioPatientDataMain";
import ImageAIPagePhysio from "./pages/PhysiotherapistDashboard/DoctorOverviewBody/PatientDataCollection/ImageAI/ImageAIPagePhysio";

import PatientDailyworkout from "./pages/PatientDailyworkout/index";
import PatientPainUI from "./pages/PatientDailyworkout/PatientPain";
import PatientExercise from "./pages/PatientDailyworkout/PatientExercise";
import PatientRom from "./pages/PatientDailyworkout/PatientRom";
import PatientImageAI from "./pages/PatientDailyworkout/PatientImageAI";
import PatientFinalResult from "./pages/PatientDailyworkout/PatientResult";
import PageNotFound from "./pages/PageNotFound";

import PatientSingleSessiondata from './pages/PatientDashBoard/PatientSingleSessiondata/index';
const RouterCmp = () => {
  // const routes = useRoutes([
  //   {
  //     path: "/",
  //     element: <ProviderLogin />,
  //   },
  //   {
  //     path: "/login",
  //     element: <Home2 />,
  //   },
  //   {
  //     path: "/register",
  //     element: <Home2 />,
  //   },
  //   {
  //     path: "/adminlogin",
  //     element: <AdminLoginPage />,
  //   },

  //   {
  //     path: "/coordinatorlogin",
  //     element: <CoordinatorLogin />,
  //   },
  //   {
  //     path: "/physiologin",
  //     element: <PhysioLogin />,
  //   },
  //   {
  //     path: "/physiologin",
  //     element: <PhysioLogin />,
  //   },
  //   {
  //     path: "/patientlogin",
  //     element: <PatientLogin />,
  //   },
  //   {
  //     path: "/imageAI",
  //     element: <ImageAIPage />,
  //   },
  //   {
  //     path: "/pain-data",
  //     element: <PatientPain />,
  //   },
  //   {
  //     path: "patient-form",
  //     element: <PatientForm />,
  //   }
  //   ,
  //   // {
  //   //   path: "guidance",
  //   //   element: <Guidance />,
  //   // },
  //   {
  //     path: "/romInfo",
  //     element: <RomPage />,
  //   },
  //   {
  //     path: "/romSession",
  //     element: <RomSession />,
  //   },
  //   {
  //     path: "exercise-list",
  //     element: <ExerciseList />,
  //   },
  //   {
  //     path: "view-exercise",
  //     element: <PatientExercises />
  //   },
  //   {
  //     path: "/coordinator",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="coordinator/list" />, index: true },
  //       { path: "list", element: <CoordinatorPage /> },
  //       { path: "add", element: <AddEditCoordinator /> },
  //       { path: "edit", element: <AddEditCoordinator /> },
  //       { path: "404", element: <Page404 /> }, // Corrected path for 404 page
  //       { path: "*", element: <Navigate to="/providers/404" /> },
  //     ],
  //   },
  //   {
  //     path: "/physio",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="physio/list" />, index: true },
  //       { path: "list", element: <PhysioPage /> },
  //       { path: "add", element: <AddEditPhysio /> },
  //       { path: "edit", element: <AddEditPhysio /> },
  //       { path: "404", element: <Page404 /> }, // Corrected path for 404 page
  //       { path: "*", element: <Navigate to="/providers/404" /> },
  //     ],
  //   },

  //   {
  //     path: "/providers",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="providers/list" />, index: true },
  //       { path: "list", element: <ProviderPage /> },
  //       { path: "add", element: <AddEditProvider /> },
  //       { path: "edit", element: <AddEditProvider /> },
  //       { path: "404", element: <Page404 /> }, // Corrected path for 404 page
  //       { path: "*", element: <Navigate to="/providers/404" /> },
  //     ],
  //   },
  //   {
  //     path: "/admin",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="admin/list" />, index: true },
  //       { path: "list", element: <AdminPage /> },
  //       { path: "add", element: <AddAdmin /> },
  //       { path: "edit", element: <AddEditProvider /> },
  //       { path: "404", element: <Page404 /> }, // Corrected path for 404 page
  //       { path: "*", element: <Navigate to="/providers/404" /> },
  //     ],
  //   },
  //   {
  //     path: "/users",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="users/list" />, index: true },
  //       { path: "list", element: <UserPage /> },
  //       { path: "add", element: <AdminLoginPage /> },
  //       { path: "edit", element: <ProviderPage /> },
  //       { path: "404", element: <Navigate to="/404" /> }, // Corrected path for 404 page
  //       { path: "*", element: <Navigate to="/users/404" /> },
  //     ],
  //   },
  //   {
  //     path: "/patientDashboard",
  //     element: <PatientDashBoard />,
  //     children: [
  //       // { element: <Navigate to="patientDashboard/guidance" />, index: true },
  //       { path: "/patientDashboard/guidance", element: <Guidance /> },
  //       { path: "add", element: <AdminLoginPage /> },
  //       { path: "edit", element: <ProviderPage /> },
  //       { path: "404", element: <Navigate to="/404" /> }, // Corrected path for 404 page
  //       { path: "*", element: <Navigate to="/users/404" /> },
  //     ],
  //   },
  //   {
  //     path: "/exercises",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="exercises/create" />, index: true },
  //       { path: "create", element: <Exercise /> },
  //       { path: "list", element: <ExerciseList /> },
  //       { path: "addpatients", element: <AddexercisePatient /> },
  //     ],
  //   },

  //   {
  //     path: "/patients",
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="/patients/list" />, index: true },
  //       { path: "list", element: <PatientPage /> },
  //       { path: "add", element: <AddEditPatient /> },
  //       { path: "edit", element: <AddEditPatient /> },
  //       { path: "404", element: <Page404 /> },
  //       { path: "*", element: <Navigate to="/404" /> },
  //     ],
  //   },

  //   {
  //     path: "/layout",
  //     element: <DashboardLayout />,
  //   },
  //   { path: "404", element: <Page404 /> }, // Corrected path for 404 page
  // ]);
  return (
    // <Fragment>
    <Router>
      <Routes>
        {/* <Route path='/admin' element={<DashboardLayout/>} */}
        <Route path="/adminlogin" element={<AdminLoginPage />} />
        <Route path="/" element={<ProviderLogin />} />
        <Route path="*" element={<PageNotFound />} />

        <Route path="/" element={<DashboardLayout />}>
          {/* admin login route */}
          <Route path="/admin/list" element={<AdminPage />} />
          <Route path="/admin/add" element={<AddAdmin />} />
          <Route path="/providers/list" element={<ProviderPage />} />
          <Route path="/providers/add" element={<AddEditProvider />} />
          <Route path="/providers/edit" element={<AddEditProvider />} />
          <Route path="/coordinator/list" element={<CoordinatorPage />} />
          <Route path="/coordinator/add" element={<AddEditCoordinator />} />
          <Route path="/coordinator/edit" element={<AddEditCoordinator />} />
          <Route path="/physio/list" element={<PhysioPage />} />
          <Route path="/physio/add" element={<AddEditPhysio />} />
          {/* route to add new exercises by admin */}
          <Route path="/exercises/create" element={<Exercise />} />
          {/* below route display all exercises */}
          <Route path="/exercises/list" element={<ExerciseList />} />
          {/* below route display all exercises */}

          <Route
            path="/exercises/addpatients"
            element={<AddexercisePatient />}
          />
        </Route>
        {/* admin login route */}
        <Route
          exact
          path="/patientDashboard/:patientId"
          element={<PatientDetailDashboard />}
        />
        <Route path="/patientlogin" element={<PatientLogin />} />
        <Route path="/patientDashboard" element={<PatientDashBoard />}>
        <Route
            exact
            path="/patientDashboard/last-data"
            element={<PatientSingleSessiondata />}
          />
          <Route
            exact
            path="/patientDashboard/patient-form"
            element={<PatientForm />}
          />
          <Route
            exact
            path="/patientDashboard/guidance"
            element={<Guidance />}
          />
          <Route
            exact
            path="/patientDashboard/pain-data"
            element={<PatientPain />}
          />
          <Route exact path="/patientDashboard/romInfo" element={<RomPage />} />
          <Route
            exact
            path="/patientDashboard/romSession"
            element={<RomSession />}
          />
          <Route
            exact
            path="/patientDashboard/view-exercise"
            element={<PatientExercises />}
          />
          <Route
            exact
            path="/patientDashboard/imageAI"
            element={<ImageAIPage />}
          />
          <Route
            exact
            path="/patientDashboard/ThermalAI"
            element={<ThermalPage />}
          />
          <Route
            exact
            path="/patientDashboard/result"
            element={<PatientResult />}
          />
        </Route>
        <Route path="/doctordashboard" element={<DoctorDashboard />}>
          <Route
            exact
            path="/doctordashboard/overview"
            element={<OverViewDoctor />}
          />
          <Route
            exact
            path="/doctordashboard/overview/:patientname/:patientid"
            element={<PatientDetailOverview />}
          />
          <Route
            exact
            path="/doctordashboard/patientlist"
            element={<PatientList />}
          />
          <Route
            exact
            path="/doctordashboard/exercise/setting"
            element={<SettingExercise />}
          />
          <Route
            exact
            path="/doctordashboard/add-patient"
            element={<AddEditPatient />}
          />
          <Route
            exact
            path="/doctordashboard/edit-patient-information"
            element={<EditFile />}
          />
          <Route
            exact
            path="/doctordashboard/exercise/:exerciseid"
            element={<ExerciseDetail />}
          />
          <Route
            exact
            path="/doctordashboard/assignexercise/:patientid"
            element={<PatientAssignExercise />}
          />
          <Route
            exact
            path="/doctordashboard/patientlist"
            element={<PatientList />}
          />

          {/* <Route exact path='/doctordashboard/patientlist' element={<PatientList/>} /> */}

          {/* <Route exact path='/patientDashboard/pain-data' element={<PatientPain/>} />
            <Route exact path='/patientDashboard/romInfo' element={<RomPage/>} />
            <Route exact path='/patientDashboard/romSession' element={<RomSession/>} />
            <Route exact path='/patientDashboard/view-exercise' element={<PatientExercises/>} />
            <Route exact path='/patientDashboard/imageAI' element={<ImageAIPage/>} /> */}
        </Route>
        {/* coordinator routes  */}
        <Route path="/coordinatorlogin" element={<CoordinatorLogin />} />
        <Route path="/CoordinatorDashboard" element={<CoordindatorDashboard />}>
          <Route
            exact
            path="/CoordinatorDashboard/overview"
            element={<OverViewCoordinator />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/add-patient"
            element={<AddEditPatientCoordinator />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/overview/:patientname/:patientid"
            element={<PatientDetailOverviewCoordinator />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/patientlist"
            element={<CoordinatorPatientList />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/doctorlist"
            element={<DoctorOverviewBody />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/physio-list"
            element={<CoPhysiotherapistDashboard />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/physio-list/:doctorId"
            element={<CoPhysioList />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/physio-list/:doctorId/assigned-physio-list"
            element={<CoViewPhysio />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/doctorlist/:doctorId"
            element={<PatientListOverviewCo />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/exercise/setting"
            element={<SettingExerciseCoordinator />}
          />
          <Route
            exact
            path="/CoordinatorDashboard/add-patient"
            element={<CoordinatorAddEditPatient />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/edit-patient-information"
            element={<EditFile />}
          />

          <Route
            exact
            path={coordinatorExerciseDetail + "/:exerciseid"}
            element={<CoordinatorViewExerciseDetails />}
          />

          <Route
            exact
            path="/CoordinatorDashboard/assignexercise/:doctorId"
            element={<CoordinatorAssignExercise />}
          />
          <Route
            exact
            path="/CoordinatorDashboard/add-patient/:doctorId"
            element={<AddNewPatient />}
          />

          {/* replace the below with coordinator list file */}
        </Route>
        {/*  physiotherapist */}

        <Route
          path="/physiotherapist-login"
          element={<PhysiotherapistLogin />}
        />
        <Route path={PhysioLanding} element={<PhysiotherapistDashboard />}>
          <Route
            exact
            path={PhysioLanding + "/" + routePhysiotherapistDoctorTab}
            element={<PhysioDoctorOverviewBody />}
          />

          <Route
            exact
            path={PhysioLanding + routePhysiotherapistDoctorTab + "/:doctorId"}
            element={<PhysioPatientListOverview />}
          />
          <Route
            exact
            path={PhysioLanding + "/" + routePhysioPatientOverview}
            element={<PatientDataOverview />}
          />

          {/* <Route
            exact
            path={physioGuidanceDefault}
            element={<PhysioPatientDataMain />}
          >
            <Route
              exact
              path={routePhysioFeaturePatientPain}
              element={<PainBody />}
            />
            <Route
              exact
              path={routePhysioFeaturePatientRangeOfMotion}
              element={<RangeOfMotionForm />}
            />
            <Route
              exact
              path={routePhysioFeaturePatientImageAi}
              element={<ImageAIPagePhysio />}
            />
          </Route> */}

          {/* <Route
            exact
            path={PhysioLanding + routePhysioFeaturePatientRangeOfMotion}
            element={<RangeOfMotionForm />}
          /> */}
        </Route>
        {/*  physiotherapist */}

        {/* <Route path="/patientDashboard" element={<PatientDashBoard />}> */}
        <Route path="/admindashboard" element={<AdminDashboard />}>
          <Route
            exact
            path="/admindashboard/providers"
            element={<ProviderList />}
          />
          <Route
            exact
            path="/admindashboard/providers/add"
            element={<AddEditProvider />}
          />
          <Route
            exact
            path="/admindashboard/providers/edit"
            element={<AddEditProvider />}
          />

          <Route
            exact
            path="/admindashboard/coordinators"
            element={<CoordinatorList />}
          />
          <Route
            exact
            path="/admindashboard/coordinators/add"
            element={<AddEditCoordinator />}
          />
          <Route
            exact
            path="/admindashboard/coordinators/edit"
            element={<AddEditCoordinator />}
          />
          <Route exact path="/admindashboard/admins" element={<AdminList />} />
          <Route
            exact
            path="/admindashboard/admins/add"
            element={<AddAdmin />}
          />

          <Route
            exact
            path="/admindashboard/physiotherapist"
            element={<PhysioList />}
          />
          <Route
            exact
            path="/admindashboard/physiotherapist/add"
            element={<AddEditPhysio />}
          />
          <Route
            exact
            path="/admindashboard/physiotherapist/edit"
            element={<AddEditPhysio />}
          />
          <Route
            exact
            path="/admindashboard/exercise"
            element={<PhysioList />}
          />
        </Route>

        <Route path="/patientdata" element={<PatientDailyworkout />}>
          {/* //physiotherapist */}
          <Route
            exact
            path="/patientdata/:usertype/doctor/:doctorId/:patientname/:patientId/:type/pain"
            element={<PatientPainUI />}
          />
          <Route
            exact
            path="/patientdata/:usertype/doctor/:doctorId/:patientname/:patientId/:type/view-exercise"
            element={<PatientExercise />}
          />
          <Route
            exact
            path="/patientdata/:usertype/doctor/:doctorId/:patientname/:patientId/:type/romInfo"
            element={<PatientRom />}
          />
          <Route
            exact
            path="/patientdata/:usertype/doctor/:doctorId/:patientname/:patientId/:type/ImageAI"
            element={<PatientImageAI />}
          />
          <Route
            exact
            path="/patientdata/:usertype/doctor/:doctorId/:patientname/:patientId/:type/result"
            element={<PatientFinalResult />}
          />
        </Route>
      </Routes>
    </Router>
    // </Fragment>
  );
};
export default RouterCmp;
// export const DoctorDashboard = [
//   { label: "Dashboard", icon: IconGauge,route:"/doctorDashboard" },
//   { label: "Patients", icon: IconGauge,route:"/doctorDashboard/patientlist" },

//   {
//       label: "Exercise",
//       icon: IconNotes,
//       initiallyOpened: true,
//       route:"/doctorDashboard/expercise",
//       links: [
//           { label: "Global Seeting Exercise", link: "/",route:"/doctorDashboard/expercise/globalseeting" },
//           { label: "Global Exercise", link: "/",route:"/doctorDashboard/expercise/globalexercise" },
//           { label: "Assign Exericse Patients", link: "/",route:"/doctorDashboard/expercise/assignexercise" },
//       ],
//   }
// ];
