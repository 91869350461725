import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Text,
  Badge,
  Card,
  Modal,
  Center,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Group, Title } from "@mantine/core";
import patientService from '../../services/patientService';
import loginService from '../../services/loginService';
import {PatientDailyworkoutContext} from '../../context/PatientDailyWorkoutContext';

import { BASEURL } from '../../constants/constants';
const PatientExerciseUI=()=>{
    const { surgerydata } = useContext(PatientDailyworkoutContext)
    const params=useParams()
    const navigate = useNavigate()
    const [opened, { open, close }] = useDisclosure(false);
    const [modelSrc, setModelSrc] = useState('')
    const [modelName, setModelNmae] = useState('')
  
    const [configData, setConfig] = useState([]);
  
    useEffect(() => {
      getPatientExercise();
    }, []);
  
  
    const getPatientExercise = async () => {
      try {
        let configData = await patientService.getExerciseByPatient(params.patientId);
  
        console.log("get exercise callled config", configData.data);
        setConfig(configData.data);
      } catch (err) {
        console.log(err);
        if (err?.response?.status && err?.response?.status === 401) {
          const path = loginService.checkRoute();
          navigate(path, { replace: true });
        }
      }
  
    }
    return(
        <>
        {/* <HeaderMenu /> */}
        <Center m={2}> <Title c={"#ffff"}>Exercises</Title></Center>
        <Center m={10} >
  
          <Container w={1000}>
            {/* <Paper h={400} shadow="xs" p="xl" style={{backgroundColor:'blue'}} > */}
            <Grid gutter="md">
              {configData.length > 0 ? configData.map((exercise, idx) => (
                <Grid.Col key={exercise.exerciseName} span={{ base: 12, md: 6, lg: 4 }} >
                  <Center>
                    <Card
                      p={10}
                      m={10}
                      shadow="sm"
                      padding="lg"
                      radius="md"
                      withBorder
                      style={{ width: "100%", backgroundColor: "#011D2c" }}
                    >
                      <Card.Section>
                        <Image
                          src={`${BASEURL}/showimage/images/${exercise?.exerciseImagePath}`}
                          height={250}
                          alt={exercise.exerciseName}
                        />
                      </Card.Section>
                      {/* <Modal opened={opened} onClose={close} title="AnklePumpV" centered>
                  <video src={`${BASEURL}/showimage/videos/${exercise.exerciseVideoPath}`} height={220} alt="hi" controls />
                </Modal> */}
                      <Group justify="space-between" mt="md" mb="xs">
                        <Text color="#fff" fw={500}>
                          {exercise.exerciseName}
                        </Text>
                        <Badge color="pink">New</Badge>
                      </Group>
  
                      <Text size="sm" c="dimmed">
                        Keep Doing this exercise to increase range of motion, flexibility, and to reduce pain
                      </Text>
  
                      <Button c={'black'} onClick={() => { open(exercise), setModelSrc(exercise.exerciseVideoPath), setModelNmae(exercise.exerciseName) }} color="blue" fullWidth mt="md" radius="md">
                        Click to see Demonstration
                      </Button>
                    </Card>
                  </Center>
  
                </Grid.Col>
              )) : (
                <Center style={{ width: '100%' }}>
                  <Text align="center" c={'#ffff'} size="xl" weight={700}>
                    No exercise data found for you. Contact your Doctor
                  </Text>
                </Center>
              )}
            </Grid>
            {/* </Paper> */}
           <Grid>
            <Grid.Col align='center'>
            <Button c={'black'} fw={600} w={'30%'} onClick={()=>{navigate(`/${surgerydata[2].route}`)}}>Skip Step</Button>&nbsp;&nbsp;
  
            <Button c={'black'} fw={600} w={'30%'} onClick={()=>{navigate(`/${surgerydata[2].route}`)}}>Next Step</Button>
            </Grid.Col>
           </Grid>
          </Container>
  
        </Center>
       
  
        <Modal opened={opened} onClose={close} title={modelName} centered>
          <video src={`${BASEURL}/showimage/videos/${modelSrc}`} height={220} alt="hi" controls />
        </Modal>
  
      </> 
    )
}

export default PatientExerciseUI;