import {useState} from 'react';
import { Grid } from "@mantine/core";
import PhysioTableList from "./PhysioTableList";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { useContext, useEffect } from "react";
import { routeCoordinatorDoctorTab } from "../../../constants/constants";
import BreadCamp from "../../../resuable/BreadCamps";
import ProviderService from '../../../services/providerService';
const PhysioDoctorOverviewBody = () => {
  const tableDataHeader = ["Sr No.", "Doctor Name", "Email", "Operations"];
  const { setBreadCampList } = useContext(BreadCampContext);

  // const tableRowData = [{}]
  const [list,setList]=useState([])
  useEffect(() => {
    setBreadCampList([{ title: "Doctor", route: routeCoordinatorDoctorTab }]);
  }, []);
  const getProvideListofphysiotherapist=async()=>{
    const apicall=await ProviderService.getProvideListofphysiotherapist()
    console.log(apicall)
    if(apicall.statusCode===200){
      setList(apicall.result)
    }
  }
  useEffect(()=>{
    getProvideListofphysiotherapist()
  },[])
  return (
    <Grid>
      <Grid.Col>{/* <BreadCamp /> */}</Grid.Col>
      <Grid.Col>
        <PhysioTableList list={list}  tableHeader={tableDataHeader} />
      </Grid.Col>
    </Grid>
  );
};

export default PhysioDoctorOverviewBody;
