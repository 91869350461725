import React, { useState } from 'react';
import { Container, Menu, UnstyledButton, Group, rem, Text, Image } from '@mantine/core';
import {
    IconLogout,
    IconSwitchHorizontal,
    IconChevronDown, IconUserCircle, IconLayoutDashboard
} from '@tabler/icons-react';
import cx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './HeaderMegaMenu.module.css';
import companyname from '../../assets/TrussLogo.png';
import loginService from '../../services/loginService';
import HookSteper from './Hooks/HookSteper';
const PatientHeader = () => {
    const {postSurgery}=HookSteper()
    const navigate = useNavigate();
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const [name, setName] = useState(localStorage.getItem('namefield'))

    const handleLogout = () => {
        loginService.dologout();
        navigate("/patientlogin", { replace: true });
    }
    return (
        <>
            <div className={classes.header} style={{ position: "fixed", width: '100%', top: '0px', zIndex: '2' }}>
                <Container fluid={true} className={classes.mainSection} size="md">
                    <Group justify="space-between">
                        <Image  width='100px' height='50px' src={companyname} />
                        <Menu
                            // width={260}
                            position="bottom-end"
                            transitionProps={{ transition: 'pop-top-right' }}
                            onClose={() => setUserMenuOpened(false)}
                            onOpen={() => setUserMenuOpened(true)}
                            withinPortal
                        >
                            <Menu.Target>
                                <UnstyledButton
                                    className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                                >
                                    <Group gap={7}>
                                        <IconUserCircle color='#B3F9FF' radius="xl" size={20} />
                                        <Text fw={500} size="sm" lh={1} mr={3} color='#B3F9FF'>
                                            {name ? name : "User Name"}
                                        </Text>
                                        <IconChevronDown style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                                    </Group>
                                </UnstyledButton>
                            </Menu.Target>
                            <Menu.Dropdown style={{ background: '' }}>
                                <Menu.Item
                                    leftSection={
                                        <IconSwitchHorizontal style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                    }
                                    onClick={() => { navigate(postSurgery[0].route) }}

                                >
                                    Patient Workflow
                                </Menu.Item>
                                <Menu.Item
                                    leftSection={
                                        <IconLayoutDashboard style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                    }
                                    onClick={() => { navigate(`/patientDashboard/${12345}`) }}

                                >
                                    Dashboard
                                </Menu.Item>
                                <Menu.Item
                                    leftSection={
                                        <IconLogout style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                    }
                                    onClick={handleLogout}>
                                    Logout
                                </Menu.Item>


                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </Container>
            </div>
        </>
    )
}

export default PatientHeader;