import { Container, Menu, LoadingOverlay, UnstyledButton, rem, Group, Image, Grid, Modal, Title, Paper, SimpleGrid, Text, Button, useSafeMantineTheme } from '@mantine/core';

import { Carousel } from '@mantine/carousel';
import { useContext, useEffect, useState } from "react";
import { DoctorDasboard } from "../../../constants/constants";
import { BreadCampContext } from "../../../context/BreadCampContext";
import dailyReportService from "../../../services/dailyReportService";
import PatientRomView from "./PatientRomView";
import StatsGrid from "./RiskLevelShowCard";
import ViewPainGraph from "./viewPainGraph";
import ViewRomGraph from "./viewRomGraph";
import moment from 'moment';
import { BASEURL } from '../../../constants/constants';
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';
import PatientDataFetch from '../Hooks/patientDataFetch';
import LoaderPage from '../../../resuable/loder';
import TrackerService from '../../../services/trackerService';
import ThermalService from '../../../services/thermalService';
import { each, get } from 'lodash';
import PatientHeader from '../PatientHeader';
import PatientDetailDashboardolds from './PatientDetailOverviewnamw';
const PatientDetailOverview=()=>{
    return (
        <>
        <PatientHeader />
        <PatientDetailDashboardolds />
        </>
    )
}
export default PatientDetailOverview;

