import Axios from "../helper/axiosHelper";
import { BASEURL } from "../constants/constants";

const axios = new Axios();
const baseUrl = BASEURL;

class ProviderService {
  getProviders() {
    const option = {
      url: `${baseUrl}/provider`,
    };

    return axios.get(option);
  }
  createProvider(data) {
    const option = {
      url: `${baseUrl}/provider`,
      data,
    };

    return axios.post(option);
  }

  // assign Physiotherepist for a Doctor
  assignphysicoforProvider(data) {
    const option = {
      url: `${baseUrl}/assignphysiotherapist/${data.doctorId}`,
      data,
    };
    return axios.post(option);
  }
  // get Doctor of Physiotherepist
  getProvideListofphysiotherapist(data) {
    const option = {
      url: `${baseUrl}/getProvideListofphysiotherapist`,
      data,
    };
    return axios.get(option);
  }

  deleteProvider(id) {
    const option = {
      url: `${baseUrl}/provider`,
      data: { id },
    };

    return axios.delete(option);
  }

  getProviderById(id) {
    console.log("getProviderBy is fun" , id);
    const option = {
      url: `${baseUrl}/provider/${id}`,
    };

    return axios.get(option);
  }

  updateProvider(doctorId, form) {
    console.log("updateProvider called", doctorId, form);
    // Log the structure of the form object
    console.log("Form structure:", JSON.stringify(form, null, 2));
    
    // Access the inActive property directly
    const inActive = form.inActive; // or form["inActive"]
    console.log("Form inActive:", inActive);
  
    const option = {
      url: `${baseUrl}/provider`,
      data: {
        DoctorId: doctorId,
        update: {
          ...form.values
        }
      }
    };
  
    return axios.put(option);
  }
  
  

   
  

  getProviderDataForFlow() {
    const option = {
      url: `${baseUrl}/providerdata`,
    };

    return axios.get(option);
  }

  postFirstProviderFlow(data, id) {
    const option = {
      url: `${baseUrl}/providerdata/${id}`,
      data,
    };

    return axios.post(option);
  }

  createProviderDailyLog(text, id, day) {
    const option = {
      url: `${baseUrl}/createProviderdailylog`,
      data: { text, id, day },
    };

    return axios.post(option);
  }
  getPatientsByDoctorId() {
    const option = {
      url: `${baseUrl}/patientsByDocId`,
    };

    return axios.get(option);
  }
}

const providerService = new ProviderService();
export default providerService;
