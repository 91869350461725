import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  Group,
  Text,
  Container,
  Center,
  useMantineTheme,
  Space,
  Loader,
} from "@mantine/core";
import axios from "axios";
import { BreadCampContext } from "../context/BreadCampContext";
import { useMediaQuery } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import ThermalService from "../services/thermalService";
import { BASEURL } from "../constants/constants";
import HookSteper from "./PatientDashBoard/Hooks/HookSteper";
const ThermalPage = () => {
  const {postSurgery}=HookSteper()
  const { setBreadCampList } = useContext(BreadCampContext);
  const [initialModalOpened, setInitialModalOpened] = useState(true);
  const [thermalCameraModalOpened, setThermalCameraModalOpened] =
    useState(false);
  const [showUploadButtons, setShowUploadButtons] = useState(false);
  const theme = useMantineTheme();
  const [thermalImage, setThermalImage] = useState(null);
  const [digitalImage, setDigitalImage] = useState(null);
  const [imagesResponse, setImagesResponse] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const [imageToShow, setImageToShow] = useState("");
  const [analysisStep, setAnalysisStep] = useState("");
  const [analysisModalOpened, setAnalysisModalOpened] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const thermalService = new ThermalService();
  const [thermal_id, setThermalId] = useState(null);
  const [showExit, setShowExit] = useState(false);
  const [uploadedThermalImage, setUploadedThermalImage] = useState(null);
  const [uploadedDigitalImage, setUploadedDigitalImage] = useState(null);
  const [patient_id, setPatientId] = useState(null);

  useEffect(() => {
    setBreadCampList([
      { title: "Patient Guidance", route: "/patientDashboard/guidance" },
      { title: "ThermalAI" },
    ]);
  }, []);

  const openThermalCameraModal = async () => {
    setShowExit(true);
    setInitialModalOpened(false);
    //setThermalCameraModalOpened(true);
    try {
      //type IRFLASHPRO
      const data = { camera: "ici" };
      const response = await thermalService.create(data);
      if (response.status === 201) {
        console.log("pending status gen");
        console.log(response);
        console.log(response.data.data.thermalId);
        setThermalId(response.data.data.thermalId);
        setPatientId(response.data.data.patientId);
        setThermalCameraModalOpened(true);

        const thermalId = response.data.data.thermalId;
        const patientId = response.data.data.patientId;
        const customUri = `trussWatcher://themal/${thermalId}/${patientId}`;
        window.location.href = customUri;
        setTimeout(() => {
          if (document.hasFocus()) {
            alert('TrussWatcher is not installed. Please install TrussWatcher to proceed.');
          }
        }, 2000); 
      } else {
        console.error("Failed to open thermal camera modal: ", response.status);
      }
    } catch (error) {
      console.error("Error making API request: ", error);
    }
  };
  const handleUseImagesClick = () => {
    setInitialModalOpened(false);
    setShowUploadButtons(true);
  };

  const handleThermalImageChange = (event) => {
    setThermalImage(event.target.files[0]);
    setUploadedThermalImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleDigitalImageChange = (event) => {
    setDigitalImage(event.target.files[0]);
    setUploadedDigitalImage(URL.createObjectURL(event.target.files[0]));
  };
  const handleSkip = () => {
    navigate(postSurgery[2].route);
  };
  const doneflashpro = async () => {
    setShowExit(true);
    setThermalCameraModalOpened(false);
    const startTime = Date.now();
    const timeout = 30000;
    const checkForResults = async () => {
      if (Date.now() - startTime > timeout) {
        setAnalysisStep("It's taking some time. Please try again later.");
        setAnalysisModalOpened(true);
        return;
      }
      try {
        const response = await thermalService.geticidata(thermal_id);
        console.log("data to display", response);
        const data = response.data;
        if (data.image) {
          const iciimage = data.image;
          console.log(iciimage)
          //setCurrentImage(`data:image/png;base64,${iciimage}`);
         // setCurrentImage(`${BASEURL}/showimage/thermal/${iciimage}`);
         setCurrentImage(`${iciimage}`);

          setAnalysisStep("Segmenting prominent body part...");
          setTimeout(() => {
            setAnalysisStep("No anomaly and ROI detected.");
            setAnalysisModalOpened(true);
          }, 5000);
        } else {
          console.log("checking ici data");
          setAnalysisStep("Waiting for results...Please be patient");
          setAnalysisModalOpened(true);
          setTimeout(checkForResults, 5000);
        }
      } catch (error) {
        console.error("Error fetching results:", error);
        setErrorMessage("Failed to fetch results.");
      }
    };
    checkForResults();
  };
  {
    /*
  function base64ToBlob(base64, mimeType) {
    try {
      const daras=`data:image/png;base64,${base64}`
      const splitDataURI = daras.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })



       
        //const raw = window.atob(parts[2]);
        // const raw = base64;
        // const rawLength = raw.length;
        // const uInt8Array = new Uint8Array(rawLength);

        // for (let i = 0; i < rawLength; ++i) {
        //     uInt8Array[i] = raw.charCodeAt(i);
        // }

        // return new Blob([uInt8Array], { type: mimeType });
    } catch (error) {
        console.error("Error converting base64 to blob:", error);
        return null;
    }
  */
  }
  function base64ToBlob(base64, mimeType) {
    try {
      const daras = `data:image/png;base64,${base64}`;
      const splitDataURI = daras.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);
      return new Blob([ia], { type: mimeString });
    } catch (error) {
      console.error("Error converting base64 to blob:", error);
      return null;
    }
  }
  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  {/*
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAnalysisModalOpened(true);
    setAnalysisStep("Detecting wound...");
    const formData = new FormData();
    formData.append("thermal_image", thermalImage);
    formData.append("digital_image", digitalImage);

    try {
      const response = await axios.post(
        "https://anomalyai-v2-vxdxefdmiq-uc.a.run.app/anomaly",
      // "http://192.168.0.176:5011/anomaly",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          responseType: "blob",
        }
      );
      if (response.headers["content-type"].includes("application/json")) {
        const data = response.data;
        const flirsave_temp = await response.data.text();
        const flirsave = JSON.parse(flirsave_temp);
        console.log("FLIR data", flirsave);

        const steps = ["overlay", "segement", "roi_img", "zone_roi"];
        const step_message = [
          "Wound Detection",
          "Segmenting Body Part",
          "ROI Intelligence",
          "Zonal Analysis",
        ];
        let stepIndex = 0;

        const processStep = () => {
          if (stepIndex < steps.length && flirsave[steps[stepIndex]]) {
            setAnalysisStep(
              `Processing: ${step_message[stepIndex].replace("_", " ")}`
            );
            setCurrentImage(
              `data:image/png;base64,${flirsave[steps[stepIndex]]}`
            );
            setTimeout(() => {
              stepIndex++;
              processStep();
            }, 5000);
          } else if (stepIndex >= steps.length) {
            setAnalysisStep(`Analysis Complete: ${flirsave.result}`);
            saveData(flirsave);
          } else {
            setAnalysisStep("No further analysis.");
          }
        };
        processStep();
      } else {
        const blob = new Blob([response.data], { type: "image/jpeg" });
        saveicidata(blob);
        handleBlobResponse(response.data);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      let errorMessage = "Failed to upload images.";
      if (error.response && error.response.data) {
        const errorData = new Blob([error.response.data]);
        const errorText = await errorData.text();
        try {
          const errorJSON = JSON.parse(errorText);
          if (errorJSON.error) {
            errorMessage = errorJSON.error;
          }
        } catch (parseError) {
          console.error("Error parsing the error message:", parseError);
        }
      }
      setErrorMessage(errorMessage);
      setAnalysisModalOpened(false);
    }
  };
*/}


const handleSubmit = async (e) => {
  e.preventDefault();
  setAnalysisModalOpened(true);
  setAnalysisStep("Processing...");


  const formData = new FormData();
  formData.append("thermal_image", thermalImage);
  formData.append("digital_image", digitalImage);

  try {
    const response = await axios.post(
      "https://anomalyai-v2-vxdxefdmiq-uc.a.run.app/anomaly",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        responseType: "blob",
      }
    );

    if (response.headers["content-type"].includes("application/json")) {
      const flirsave_temp = await response.data.text();
      const flirsave = JSON.parse(flirsave_temp);
      if (thermalImage) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCurrentImage(reader.result); 
        };
        reader.readAsDataURL(thermalImage);
      }    
      
      if (flirsave.result) {
        setAnalysisStep(`Analysis Complete: ${flirsave.result}`);
        saveData(flirsave);
      } else {
        setAnalysisStep("No further analysis.");
      }
    } else {
      const blob = new Blob([response.data], { type: "image/jpeg" });
      saveicidata(blob);
      handleBlobResponse(blob); 
    }
  } catch (error) {
    console.error("Error uploading images:", error);
    let errorMessage = "Failed to upload images.";
    if (error.response && error.response.data) {
      const errorText = await new Blob([error.response.data]).text();
      try {
        const errorJSON = JSON.parse(errorText);
        errorMessage = errorJSON.error || errorMessage;
      } catch (parseError) {
        console.error("Error parsing the error message:", parseError);
      }
    }
    setErrorMessage(errorMessage);
    setAnalysisModalOpened(false);
  }
};


  async function saveData(data) {
    const formData = new FormData();
    const steps = ["overlay", "segement", "roi_img", "zone_roi"];

    for (const step of steps) {
      if (data[step]) {
        //console.log("Step x : " , data[step])
       // const imageBlob = base64ToBlob(data[step], "image/png");
      //  const imageFile = new File([imageBlob], `${step}.png`, {
        //  type: "image/png",
        //});
        formData.append(step, data[step]);
        //console.log(data[step])
      }
    }
    formData.append("camera", "flir");
    formData.append("result", data.result);


    await thermalService
      .manualdata(formData)
      .then(() => {
        console.log("FLIR Data saved successfully");
      })
      .catch((error) => {
        console.error("Failed to save data", error);
      });
  }

  {
    /*
  function saveicidata(blob){
    const reader = new FileReader();
    reader.onloadend = () => {
    const base64data = reader.result; 
    const tosave = {
      camera: "ici",
      image: base64data,
      result: "No anomaly and ROI detected."
    };
    thermalService.manualdata(tosave).then(() => {
      console.log("ICI Data saved successfully");
    }).catch((error) => {
      console.error("Failed to save data", error);
    });
  }
  reader.readAsDataURL(blob);
}
*/
  }
  function saveicidata(blob){
    const reader = new FileReader();
    reader.onloadend = () => {
    const base64data = reader.result; 
    console.log(base64data)
    const tosave = {
      camera: "ici",
      image: base64data,
      result: "No anomaly and ROI detected."
    };
    thermalService.manualdata(tosave).then(() => {
      console.log("ICI Data saved successfully");
    }).catch((error) => {
      console.error("Failed to save data", error);
    });
  }
  reader.readAsDataURL(blob);
}

  function handleBlobResponse(blob) {
    const imageObjectURL = URL.createObjectURL(blob);
    setCurrentImage(imageObjectURL);
    setAnalysisStep("Segmenting prominent body part...");
    setTimeout(() => {
      setAnalysisStep("No anomaly and ROI detected.");
      setAnalysisModalOpened(true);
    }, 5000);
  }

  return (
    <Container fluid px={"sm"}>
      <Modal
        opened={initialModalOpened}
        onClose={() => setInitialModalOpened(false)}
        title={
          <Text
            component="span"
            style={{
              fontSize: "1.25rem",
              fontWeight: 500,
              color: theme.colors.blue[6],
            }}
          >
            Choose an option
          </Text>
        }
        centered
        size={isMobile ? "90%" : "l"}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        <Group
          direction={isMobile ? "column" : "row"}
          position="center"
          spacing="md"
          style={{ justifyContent: "center", width: "100%" }}
        >
          <Button
            onClick={openThermalCameraModal}
            style={{
              backgroundImage: `linear-gradient(135deg, ${theme.colors.pink[6]} 0%, ${theme.colors.orange[6]} 100%)`,
              minWidth: "220px",
              whiteSpace: "normal",
            }}
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan", deg: 60 }}
          >
            Use Thermal Camera
          </Button>
          <Button
            onClick={handleUseImagesClick}
            style={{
              backgroundImage: `linear-gradient(135deg, ${theme.colors.blue[6]} 0%, ${theme.colors.cyan[6]} 100%)`,
              minWidth: "220px",
              whiteSpace: "normal",
            }}
            variant="gradient"
            gradient={{ from: "pink", to: "orange", deg: 60 }}
          >
            Upload Images
          </Button>

          <Button
            onClick={handleSkip}
            style={{
              backgroundImage: `linear-gradient(135deg, ${theme.colors.yellow[6]} 0%, ${theme.colors.yellow[6]} 100%)`,
              minWidth: "100px",
              whiteSpace: "normal",
            }}
            variant="gradient"
          >
            Skip
          </Button>
        </Group>
      </Modal>

      <Modal
        opened={thermalCameraModalOpened}
        onClose={() => setThermalCameraModalOpened(false)}
        title={
          <Text
            component="span"
            style={{
              fontSize: "1.25rem",
              fontWeight: 500,
              color: theme.colors.blue[6],
            }}
          >
            Thermal Camera Usage
          </Text>
        }
        centered
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        <Text
          style={{
            margin: "1rem 0",
            fontSize: "1rem",
            color: theme.colors.gray[7],
            textAlign: "center",
          }}
        >
          Please go to your proprietary app to click the thermal image.
        </Text>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button
            style={{
              backgroundImage: `linear-gradient(135deg, ${theme.colors.green[3]} 0%, ${theme.colors.green[6]} 100%)`,
              minWidth: isMobile ? "150px" : "220px",
              whiteSpace: "normal",
              textAlign: "center",
              color: theme.white,
            }}
            onClick={() => doneflashpro()}
          >
            Done
          </Button>
        </div>
      </Modal>

      {showUploadButtons && (
        <Center
          style={{
            flexDirection: "column",
            marginTop: 20,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Group
            position="center"
            spacing="md"
            style={{
              marginBottom: 20,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Button
              component="label"
              variant="gradient"
              style={{
                backgroundImage: `linear-gradient(135deg, ${theme.colors.pink[6]} 0%, ${theme.colors.orange[6]} 100%)`,
                minWidth: isMobile ? "150px" : "220px",
                whiteSpace: "normal",
                textAlign: "center",
              }}
            >
              Upload Thermal Image
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleThermalImageChange}
              />
            </Button>
            {uploadedThermalImage && (
              <img
                src={uploadedThermalImage}
                alt="Thermal"
                style={{ width: 50, height: 50, marginLeft: 10 }}
              />
            )}
            <Button
              component="label"
              variant="gradient"
              style={{
                backgroundImage: `linear-gradient(135deg, ${theme.colors.blue[6]} 0%, ${theme.colors.cyan[6]} 100%)`,
                minWidth: isMobile ? "150px" : "220px",
                whiteSpace: "normal",
                textAlign: "center",
              }}
            >
              Upload Digital Image
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleDigitalImageChange}
              />
            </Button>
            {uploadedDigitalImage && (
              <img
                src={uploadedDigitalImage}
                alt="Digital"
                style={{ width: 50, height: 50, marginLeft: 10 }}
              />
            )}
          </Group>
          <Button
            color="black"
            style={{
              backgroundImage: `linear-gradient(135deg, ${theme.colors.green[6]} 0%, ${theme.colors.green[6]} 100%)`,
              minWidth: isMobile ? "150px" : "220px",
              whiteSpace: "normal",
              textAlign: "center",
            }}
            onClick={handleSubmit}
          >
            Submit Images
          </Button>
          {errorMessage && (
            <Text color="red" style={{ marginTop: 20 }}>
              {errorMessage}
            </Text>
          )}
          {imagesResponse.map((src, index) => (
            <img key={index} src={src} alt={`Response ${index}`} />
          ))}
          <Button
            style={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              minWidth: isMobile ? "150px" : "220px",
              textAlign: "center",
              backgroundColor: "#d9534f",
              color: "white",
              backgroundImage: `linear-gradient(135deg, ${theme.colors.blue[3]} 0%, ${theme.colors.blue[6]} 100%)`,
              zIndex: 9999,
            }}
            onClick={() => navigate("/patientDashboard/pain-data")}
          >
             Next Step
          </Button>
        </Center>
      )}
      <Modal
        opened={analysisModalOpened}
        //setAnalysisModalOpened(false)
        closeOnClickOutside={false}
        onClose={() => {setAnalysisModalOpened(false)}}
        title={
          <Text
            component="span"
            style={{
              fontSize: "1.5rem",
              fontWeight: 500,
              color: theme.colors.blue[6],
            }}
          >
            Thermal Analysis
          </Text>
        }
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        {analysisStep.includes("Processing") && <Loader />}
        <Text>{analysisStep}</Text>
        {currentImage && (
          <img
            src={currentImage}
            alt="Analysis Step"
            style={{
              width: "100%",
              marginTop: "20px",
              maxHeight: "400px",
              objectFit: "contain",
            }}
          />
        )}
      </Modal>
      {showExit && (
        <Button
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            minWidth: isMobile ? "150px" : "220px",
            textAlign: "center",
            backgroundColor: "#d9534f",
            color: "white",
            backgroundImage: `linear-gradient(135deg, ${theme.colors.blue[3]} 0%, ${theme.colors.blue[6]} 100%)`,
            zIndex: 9999,
          }}
          onClick={() => navigate("/patientDashboard/pain-data")}
        >
          Next Step
        </Button>
      )}
    </Container>
  );
};
export default ThermalPage;
