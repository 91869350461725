import {
  Box,
  Center,
  Container,
  Grid,
  Image,
  Text,
  Title,
} from "@mantine/core";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import clogo from "../../assets/TrussLogo.png";
import companyname from "../../assets/company-name.png";
import TopLeftSvg from "../../assets/left-top.svg";
import RightBottomSvg from "../../assets/right-bottom.svg";
import LoginForm from "../../sections/auth/login/LoginForm.jsx";
import "../ProviderLogin.scss";

// const OverlayContainer = styled.div`
//   position: relative;
// `;

// const OverlayImage = styled(Image)`
//   position: absolute;
//   top: 50%; /* Move to vertical center */
//   left: 50%; /* Move to horizontal center */
//   transform: translate(-50%, -50%); /* Center the image */
//   width: 70%;
//   height: 70%;
// `;
// const OverlayImageMobile = styled(Image)`
//   position: absolute;
//   left: 50%; /* Move to horizontal center */
//   transform: translate(-50%, -50%); /* Center the image */
//   width: 70%;
//   height: 70%;
// `;
// const OverlayImage2 = styled(Image)`
//   position: absolute;
//   top: 75%; /* Move to vertical center */
//   left: 50%; /* Move to horizontal center */
//   transform: translate(-50%, -50%); /* Center the image */
//   width: 70%;
//   height: 70%;
// `;
const OverlayContainerMobile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const OverlayContent = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ClinicalCoordinatorLogin = () => {
  return (
    <>
      <Helmet>
        <title> Clinical Cordinator Login | Truss Health </title>
      </Helmet>
      <Container p={0} fluid={true} className="main-container">
        <Grid p={0}>
          <Grid.Col
            visibleFrom="sm"
            span={6}
            style={{ position: "relative", height: "100vh" }}
          >
            <div style={{ position: "absolute", top: "0px", width: "100%" }}>
              <Image src={TopLeftSvg} />
            </div>
            <Center style={{ height: "100%", width: "100%" }}>
              <Box>
                <Image
                  src={clogo}
                  height="150px"
                  width="100px"
                  style={{ objectFit: "fill" }}
                />
                <Image mt={10} src={companyname} height="40px" />
                <Text size="xl" align="center" color="#B3F9FF" fw={600}>
                  Welcomes You
                </Text>
              </Box>
            </Center>
          </Grid.Col>
          {/* This below component is visble in Desktop Mode */}
          <Grid.Col visibleFrom="sm" fluid span={6}>
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                width: "",
              }}
            >
              <Image src={RightBottomSvg} />
            </div>
            <Center style={{ height: "100%", width: "100%" }}>
              <Box w={"70%"}>
                <Title c="#ffff" align="center" visibleFrom="sm">
                  Coordinator Login{" "}
                </Title>
                {/* <Title order={6}>Sign in</Title> */}
                <LoginForm userType="coordinator" />
              </Box>
            </Center>
          </Grid.Col>
          {/* This below component is visble in Mobile Mode */}
          <Grid.Col hiddenFrom="sm" fluid span={12} className="hello">
          <div style={{ position: "absolute", top: "0px", width: "100%" }}>
              <Image src={TopLeftSvg} />
            </div>
          <div style={{ position: "absolute", bottom: "0px", width: "" }}>
              <Image src={RightBottomSvg} />
            </div>
            <Center style={{ height: "100%", width: "100%" , marginTop: "50%" }}>
              
              <Box w={"70%"}>
               

                <Center>
                
                <Title c={'#ffff'} order={4}>TrussHealth</Title>
                </Center>
                <Center>
                
                <Title c={'#ffff'} order={5}>Coordinator Login </Title>
                </Center>
                <LoginForm userType="coordinator" />
              </Box>
            </Center>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default ClinicalCoordinatorLogin;
