import React, { useContext } from "react";
import { Breadcrumbs, Anchor } from "@mantine/core";
import { BreadCampContext } from "../context/BreadCampContext";
import { useNavigate } from "react-router-dom";
const BreadCamp = () => {
  const navigate = useNavigate();
  const { breadCampList } = useContext(BreadCampContext);
  // console.log(breadCampList)
  const handleclick = (item) => {
    if (item.route) {
      navigate(item.route);
    }
  };
  return (
    <>
      {breadCampList.length > 0 ? (
        <Breadcrumbs>
          {breadCampList.map((item, index) => (
            <Anchor c="#B3F9FF" onClick={() => handleclick(item)} key={index}>
              {item.title}
            </Anchor>
          ))}
        </Breadcrumbs>
      ) : (
        ""
      )}
    </>
  );
};
export default BreadCamp;
