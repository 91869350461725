
import { useContext, useEffect, useState } from 'react';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys, Grid, Button,Divider,Anchor
} from '@mantine/core';
import { IconSignLeft, IconCirclePlus, IconChevronDown, IconChevronUp, IconSearch, IconPencil, IconTrash, IconEye } from '@tabler/icons-react';
import ExerciseService from '../../../../services/exercise';
import ExerciseTable from './List/ExerciseTable';
import {exercisedetail} from '../../../../constants/constants';
import { useNavigate } from 'react-router-dom';
import {navbarContext} from '../../context/DoctorDashboard';
import BreadCamp from '../../../../resuable/BreadCamps';
import {BreadCampContext} from '../../../../context/BreadCampContext';
import {seetingExerciseRoute} from '../../../../constants/constants';
function formatDate(isoDateString) {
    const mongoDate = isoDateString;
const dateObj = new Date(mongoDate);
const day = dateObj.getDate().toString().padStart(2, '0');
const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
const year = dateObj.getFullYear();
const formattedDate = `${day}-${month}-${year}`;
return formattedDate
   }
function SettingExercise() {
    const { setBreadCampList} = useContext(BreadCampContext);
    const navigate=useNavigate()
    const {setNavActive}=useContext(navbarContext)
    const [sortedData, setSortedData] = useState([]);
    const [list, setList] = useState([]);
    const [rows, setRows] = useState([]);
    const showexerciseclick=(data)=>{
        console.log(data)
        navigate(`${exercisedetail}/${data.id}`)
    }       
    const FetchPatientList = async () => {
        const apiacll = await ExerciseService.getExercisesList()

        if (apiacll.statusCode === 200) {
            console.log(apiacll)
            const setdata = apiacll.data;
            const filterkeydata = []
            setdata.forEach(element => {
                filterkeydata.push({id:element._id, name: element.exerciseName, createDate: element.createdAt, status: element.inActive,global:element.globalstatus })
            });
            console.log(filterkeydata)
            setList(filterkeydata)
            setSortedData(filterkeydata)
            const rowdata = filterkeydata.map((row, index) => (
                <Table.Tr key={index}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>{row.name}</Table.Td>
                    <Table.Td>{formatDate(row.createDate)}</Table.Td>
                    <Table.Td>{row.status? 'Active':"Inactive"}</Table.Td>
                    <Table.Td>{row.global}</Table.Td>
                    <Table.Td>
                        <Group align=''>
                        {/* <IconPencil size={18} color='#7400b6' /> */}
                         <IconEye onClick={()=>showexerciseclick(row)} size={18} color='#90EE90' />
                        </Group>
                    </Table.Td>

                </Table.Tr>
            ))
            setRows(rowdata)
        }
    }
    useEffect(() => {
        // setBreadCampList([
        //     { title: "Exercise" },
        // ])
        // setNavActive('Setting Exercise')
        FetchPatientList()
    }, [])
    useEffect(() => {
        const rowdata = sortedData.map((row, index) => (
            <Table.Tr key={index}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td>{row.name}</Table.Td>
                    <Table.Td>{formatDate(row.createDate)}</Table.Td>
                    <Table.Td>{row.status? 'Inactive':"Active"}</Table.Td>
                    <Table.Td>{row.global? "Yes":"No"}</Table.Td>
                    <Table.Td>
                        <Group align=''>
                        {/* <IconPencil size={18} color='#7400b6' /> */}
                        <IconEye onClick={()=>showexerciseclick(row)} size={18} color='#90EE90' />
                        </Group>
                    </Table.Td>

                </Table.Tr>
        ))
        setRows(rowdata)
    }, [sortedData])

    return (
        <Grid>
            {/* <Grid.Col>
                <Group justify='space-between'>
                  <BreadCamp />
                </Group>
            </Grid.Col> */}
            <Grid.Col>
            <Divider my="md" size={5}  
             label={
                <Anchor c={'#b3f9ff'} fz='20px'  target="_blank" inherit>
                 Exercise List
                </Anchor>
              }
            c={'red'} labelPosition="left" />

            <ExerciseTable list={list} rows={rows} sortedData={sortedData} setSortedData={setSortedData} />
            </Grid.Col>
        </Grid>

    );
}
export default SettingExercise;