import React, { useEffect, useState } from "react";

const TextInput = ({ handleChange }) => {
  const [unit, setUnit] = useState("lb");
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value) handleChange(value + unit);
  }, [value, unit]);

  return (
    <div className="text-select-input">
      <input
        style={{color:'black'}}
        type="number"
        placeholder="Your Weight"
        value={value||""}
        onChange={(e) => setValue(e.target.value)}
      />
      <select
        name="unit"
        id="unit"
        value={unit}
        onChange={(e) => setUnit(e.target.value)}
      >
        <option value="lb">lbs</option>
        <option value="kg">kgs</option>
      </select>
    </div>
  );
};

export default TextInput;
