import { Text } from "@mantine/core";

const PageNotFound = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>
        <Text c={"white"} size="2xl">
          404: Page Not Found
        </Text>
      </h1>
      <Text c={"white"} size="xl">
        Sorry, the page you're looking for does not exist.
      </Text>
    </div>
  );
};

export default PageNotFound;
