import { Grid, Group, Table, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CoordinatorDashboard } from "../../../../constants/constants";
import { BreadCampContext } from "../../../../context/BreadCampContext";
import BreadCamp from "../../../../resuable/BreadCamps";
import PatientService from "../../../../services/patientService";
import { navbarContext } from "../../Context/CoordinatorDashboard";
import TableData from "./TableData";

function PatientDetailOverview(props) {
  // console.log(props.value);
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [patientListshow, setPatientListshow] = useState(true);
  const [createpatientshow, setCreatepatientshow] = useState(false);
  const navigate = useNavigate();
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "OverView", href: "#" },
    { title: "Patient List", href: "#" },
  ]);
  const { setBreadCampList } = useContext(BreadCampContext);

  const FetchPatientList = async () => {
    const apiacll = await PatientService.getPatients();

    if (apiacll.statusCode === 200) {
      // console.log(apiacll);
      const setdata = apiacll.data;
      const filterkeydata = [];
      setdata.forEach((element) => {
        filterkeydata.push({
          name: element.name,
          email: element.email,
          mobile: element.mobile,
          id: element._id,
        });
      });
      // console.log(filterkeydata);
      setList(filterkeydata);
      setSortedData(filterkeydata);
      const rowdata = filterkeydata.map((row, index) => (
        <Table.Tr key={index}>
          <Table.Td>{index + 1}</Table.Td>
          <Table.Td>{row.name}</Table.Td>
          <Table.Td>{row.email}</Table.Td>
          <Table.Td>{row.email}</Table.Td>

          <Table.Td>
            <Group align="">
              <Tooltip label="View Patient Detail">
                <IconEye size={18} color="#90EE90" />
              </Tooltip>
            </Group>
          </Table.Td>
        </Table.Tr>
      ));
      setRows(rowdata);
    }
  };

  useEffect(() => {
    setBreadCampList([
      { title: "Dashboard", route: CoordinatorDashboard },
      { title: "Patient Data" },
    ]);
    setNavActive("Patients");
    FetchPatientList();
  }, []);

  const dataObject = {
    key1: "value1",
    key2: "value2",
  };

  useEffect(() => {
    const rowdata = sortedData.map((row, index) => (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}</Table.Td>
        <Table.Td>{row.name}</Table.Td>
        <Table.Td>{row.email}</Table.Td>
        <Table.Td>{row.email}</Table.Td>
        <Table.Td>
          <Group align="">
            <Tooltip label="View Patient Detail">
              <IconEye size={18} color="#90EE90" />
            </Tooltip>
          </Group>
        </Table.Td>
      </Table.Tr>
    ));
    setRows(rowdata);
  }, [sortedData]);

  const ClickCreatePatient = () => {
    navigate("/doctorDashboard/add-patient");
    // setBreadcampitems([
    //   { title: "DashBoard", href: "#" },
    //   { title: "Patient List", href: "#" },
    //   { title: "Create Patient", href: "#" },
    // ]);
    // setCreatepatientshow(true);
    // setPatientListshow(false);
  };

  return (
    <Grid>
      <Grid.Col>
        <Group style={{ justifyContent: "space-between" }}>
          <BreadCamp />
          {/* {createpatientshow === true ? (
                        ""
                    ) : (
                        <Button
                            onClick={ClickCreatePatient}
                            fw={600}
                            leftSection={<IconCirclePlus size={20} />}
                            variant="default"
                        >
                            Create Patient
                        </Button>
                    )} */}
        </Group>
      </Grid.Col>
      <Grid.Col>
        {patientListshow === true ? (
          <TableData
            list={list}
            rows={rows}
            sortedData={sortedData}
            setSortedData={setSortedData}
          />
        ) : (
          ""
        )}
        {/* {createpatientshow === true ? <AddEditPatient /> : ""} */}
      </Grid.Col>
    </Grid>
  );
}
export default PatientDetailOverview;
