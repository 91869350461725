
import { useContext, useEffect, useState } from 'react';
import {
    IconUserCheck,
    IconMailOpened,
    IconShieldCheck,
    IconCircleCheck,
} from '@tabler/icons-react';
import { Stepper, rem } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { PatientSteperContext } from '../../context/PatientActionSteperContext';
function GuidanceSteper() {
    const { steperActive, setSteperActive, surgerydata, setSurgerydata } = useContext(PatientSteperContext)
    const location = useLocation()
    useEffect(() => {
        console.log(location)
        console.log("data 1:", surgerydata)
        const filterdata = surgerydata.filter((el) => {
            if (location.pathname === "/patientDashboard/romSession") {
                return el.route === surgerydata[3].route

            }
            if (location.pathname === "/patientDashboard/ThermalAI") {
                return el.route === surgerydata[2].route

            }

            else {
                return el.route === location.pathname

            }
        })
        console.log(filterdata)
        setSteperActive(filterdata[0].step)

    }, [])
    useEffect(() => {
        console.log(location)
        console.log("data 2: ", surgerydata)
        const filterdata = surgerydata.filter((el) => {
            // console.log("el",el)
            if (location.pathname === "/patientDashboard/romSession") {
                return el.route === surgerydata[3].route
            }
            if (location.pathname === "/patientDashboard/ThermalAI") {
                return el.route === surgerydata[2].route
            }
            else {
                return el.route === location.pathname

            }
        })

        console.log("filtered", filterdata)
        console.log("more filtered", filterdata[0].step)
        setSteperActive(filterdata[0].step)
    }, [location])
    return (
        <Stepper w={{ xs: "100%", sm: "100%", lg: "80%" }} m='auto'
            size={{xs:'xs',sm:25,lg:35,xl:50}}
            //  iconSize={32}
            // size={{xs:'xs',sm:"sm",lg:"xl"}}
            active={steperActive}
            //   onStepClick={setActive}
        
            completedIcon={<IconCircleCheck
            // style={{ width: rem(18), height: rem(18) }} 
            />
            }
            styles={{
                stepBody: {
                    display: 'none',
                }


                // step:{
                //     display:'block'
                // }

            }}
        >
            {
                surgerydata.map((item, index) => {
                    return (
                        <Stepper.Step
                            icon={<item.icon />}
                            label={`Step ${index + 1}`}
                            description={item.title}
                        >
                            {/* {item.cmp} */}
                        </Stepper.Step>
                    )
                })
            }


        </Stepper >
    );
}
export default GuidanceSteper;