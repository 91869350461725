import React, { useEffect } from 'react';
import cx from 'clsx';
import { useState } from 'react';
import {
    Container, useMantineTheme, Grid, Affix, Button, Text, Transition, rem
} from '@mantine/core';
import { Outlet, useLocation } from 'react-router';
import Guidance from './Guidance';
import { useNavigate } from 'react-router-dom';
import PatientHeader from './PatientHeader';
import VerifyPatientInitialType from './Hooks/verifyPatientInitialType';
import PatientPain from '../PatientPain';
import PatientExercises from '../patient/PatientExercise';
import RomSession from '../../components/ROM/romSession';
import ImageAIPage from '../ImageAIPage';
import PatientResult from './PatientResult';
import ThermalPage from "../ThermalPage";
import { IconGauge, IconArrowUp, IconHome, IconSeparator, IconHospital, IconFileAnalytics, IconLayersIntersect, IconMessageUp, IconCarGarage, IconNurse, IconUser, IconCookie, IconLock, IconMessage2, IconStretching, IconActivity, IconHelp, IconUserQuestion, IconPhotoScan, IconAccessPoint } from '@tabler/icons-react';
import LoaderPage from '../../resuable/loder';

import { useWindowScroll } from '@mantine/hooks';

// const postSurgery = [
//     {
//         icon: IconGauge,
//         title: 'Pain',
//         route: '/patientDashboard/pain-data',
//         cmp: <PatientPain />,
//         step: 1,
//         description:
//             'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
//     },
//     // {
//     //   icon: IconUser,
//     //   title: 'Tracker',
//     //   route:"",
//     //   description:
//     //     'People say it can run at the same speed as lightning striking, Its icy body is so cold, it will not melt even if it is immersed in magma',
//     // },
//     // {
//     //   icon: IconAccessPoint,
//     //   title: 'Community Access',
//     //   route:"",
//     //   description:
//     //     'They’re popular, but they’re rare. Trainers who show them off recklessly may be targeted by thieves',
//     // },
//     // {
//     //   icon: IconMessageUp,
//     //   title: 'Message your Doctor',
//     //   route:"",
//     //   description:
//     //     'Although it still can’t fly, its jumping power is outstanding, in Alola the mushrooms on Paras don’t grow up quite right',
//     // },

//     {
//         icon: IconStretching,
//         title: 'Exercise list',
//         route: '/patientDashboard/view-exercise',
//         cmp: <PatientExercises />,
//         step: 2,
//         description:
//             'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
//     },
//     {
//         icon: IconPhotoScan,
//         title: 'ThermalAI',
//         route: '/patientDashboard/ThermalAI',
//         cmp: <ThermalPage />,
//         step: 3,
//         description:
//             'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
//     },
//     {
//         icon: IconActivity,
//         title: 'Range of motion',
//         route: '/patientDashboard/romInfo',
//         cmp: <RomSession />,
//         step: 4,
//         description:
//             'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
//     },
//     // {
//     //   icon: IconHelp,
//     //   title: 'Help',
//     //   route:"",
//     //   description:
//     //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
//     // },
//     // {
//     //   icon: IconUserQuestion,
//     //   title: 'Frequently Asked Questions',
//     //   route:"",
//     //   description:
//     //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
//     // },

//     {
//         icon: IconPhotoScan,
//         title: 'ImageAI',
//         route: '/patientDashboard/ImageAI',
//         cmp: <ImageAIPage />,
//         step: 5,
//         description:
//             'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
//     },
//     {
//         icon: IconPhotoScan,
//         title: 'Patient Result',
//         route: '/patientDashboard/result',
//         cmp: <PatientResult />,
//         step: 6,
//         description:
//             'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
//     },
// ]
const postSurgery = [
    {
        icon: IconPhotoScan,
        title: 'ImageAI',
        route: '/patientDashboard/ImageAI',
        cmp: <ImageAIPage />,
        step: 1,
        description:
            'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
    },
    {
        icon: IconPhotoScan,
        title: 'ThermalAI',
        route: '/patientDashboard/ThermalAI',
        cmp: <ThermalPage />,
        step: 2,
        description:
            'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
    },
    {
        icon: IconGauge,
        title: 'Pain',
        route: '/patientDashboard/pain-data',
        cmp: <PatientPain />,
        step: 3,
        description:
            'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
    },
    // {
    //   icon: IconUser,
    //   title: 'Tracker',
    //   route:"",
    //   description:
    //     'People say it can run at the same speed as lightning striking, Its icy body is so cold, it will not melt even if it is immersed in magma',
    // },
    // {
    //   icon: IconAccessPoint,
    //   title: 'Community Access',
    //   route:"",
    //   description:
    //     'They’re popular, but they’re rare. Trainers who show them off recklessly may be targeted by thieves',
    // },
    // {
    //   icon: IconMessageUp,
    //   title: 'Message your Doctor',
    //   route:"",
    //   description:
    //     'Although it still can’t fly, its jumping power is outstanding, in Alola the mushrooms on Paras don’t grow up quite right',
    // },

    {
        icon: IconStretching,
        title: 'Exercise list',
        route: '/patientDashboard/view-exercise',
        cmp: <PatientExercises />,
        step: 4,
        description:
            'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
    },
    {
        icon: IconActivity,
        title: 'Range of motion',
        route: '/patientDashboard/romInfo',
        cmp: <RomSession />,
        step: 5,
        description:
            'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
    },
    // {
    //   icon: IconHelp,
    //   title: 'Help',
    //   route:"",
    //   description:
    //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
    // },
    // {
    //   icon: IconUserQuestion,
    //   title: 'Frequently Asked Questions',
    //   route:"",
    //   description:
    //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
    // },

    {
        icon: IconPhotoScan,
        title: 'Patient Result',
        route: '/patientDashboard/result',
        cmp: <PatientResult />,
        step: 6,
        description:
            'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
    },
]
function PatientDashBoard() {
    const location = useLocation();
    const [scroll, scrollTo] = useWindowScroll();
    const navigate = useNavigate();
    const { loading } = VerifyPatientInitialType(postSurgery)
    // useEffect(()=>{
    //     navigate('/patientDashboard/pain-data')
    // },[])
    return (
        <>
            {!loading ?
                <>
                    <PatientHeader />
                    <div style={{ marginTop: '100px' }}>
                        <Container fluid={true}>
                            <Grid>
                                {location.pathname === "/patientDashboard/patient-form" || location.pathname === "/patientDashboard/last-data" ? "" :
                                    <Grid.Col>
                                        <Guidance />
                                    </Grid.Col>
                                }
                            </Grid>
                        </Container>
                        <Outlet />
                    </div>
                    <Affix position={{ bottom: 20, right: 20 }}>
                        <Transition transition="slide-up" mounted={scroll.y > 0}>
                            {(transitionStyles) => (
                                <Button
                                    leftSection={<IconArrowUp style={{ width: rem(16), height: rem(16) }} />}
                                    style={transitionStyles}
                                    onClick={() => scrollTo({ y: 0 })}
                                    variant="gradient"
                                    gradient={{ from: 'cyan', to: 'indigo', deg: 90 }}
                                >
                                    Scroll to top
                                </Button>
                            )}
                        </Transition>
                    </Affix>
                </>
                : <LoaderPage />}
        </>

    );
}

export default PatientDashBoard;
