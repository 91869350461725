import React, { useState, createContext,useContext } from 'react'


 export const BreadCampContext = createContext();
const BreadCampProvider = ({children}) => {
    const [breadCampList, setBreadCampList] = useState([])

    return (
        <BreadCampContext.Provider value={{ breadCampList, setBreadCampList }}>
            {children}
        </BreadCampContext.Provider>
    )
};

export default BreadCampProvider;
