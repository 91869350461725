import { Box, Center, Flex, Space, Table, Tooltip } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PhysioLanding,
  routePhysioFeaturePatientPain,
  routePhysiotherapistDoctorTab,
} from "../../../constants/constants";
import { BreadCampContext } from "../../../context/BreadCampContext";
import BreadCamp from "../../../resuable/BreadCamps";
import coordinatorUsersService from "../../../services/CoordinatorUsersService";
import { navbarContext } from "../Context/PhysiotherapistDashboard";
import { IconEye, IconCirclePlus } from "@tabler/icons-react";
import PatientService from "../../../services/patientService";
import GuidanceMain from "./PatientDataCollection/GuidanceMain";

const PhysioPatientListOverview = () => {
  const tableDataHeader = ["Sr No.", "Patient Name", "Email", "Assignment"];
  const { setBreadCampList } = useContext(BreadCampContext);
  const { setNavActive } = useContext(navbarContext);
  const [patientList, setPatientList] = useState([]);
  const [elementsPatients, setelementsPatients] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  console.log("params patientlist", params);
  const { doctorId, patientId } = params;
  const [dataCollection, setDataCollection] = useState(false);
  const getPatientList = async () => {
    const apicall = await PatientService.getPatientListByDoctorId(
      params.doctorId
    );
    console.log(apicall);
    if (apicall.statusCode === 200) {
      setelementsPatients(apicall.data);
    }
  };

  useEffect(() => {
    getPatientList();
  }, []);

  useEffect(() => {
    setBreadCampList([
      {
        title: "Doctor",
        route: PhysioLanding + routePhysiotherapistDoctorTab,
      },
      {
        title: "Patient List",
        route: PhysioLanding + routePhysiotherapistDoctorTab + "/" + doctorId,
      },
    ]);
    setNavActive("Doctor");
  }, [setBreadCampList]);

  const handleClick = (e) => {
    console.log(e);
    // alert('dsj')
    navigate(`patient/${e._id}`);
  };

  const handleClickData = (e) => {
    // navigate(
    //   routePhysioFeaturePatientPain
    //     .replace(":doctorId", `${doctorId}`)
    //     .replace(":patientId", `${e._id}`)
    // );
    navigate(`/patientdata/physiotherapist/doctor/${doctorId}/${e.name}/${e._id}/pain`)
  //  navigate(`/physiotherapist-dashboard/physiotherapist/doctor/${doctorId}/${e.name}/${e._id}`)
    setDataCollection(true);
  };

  // const rows = elementsPatient.map((element, idx) => (
  //   <Table.Tr key={idx}>
  //     <Table.Td>{element.id}</Table.Td>
  //     <Table.Td>{element.name}</Table.Td>
  //     <Table.Td>{element.email}</Table.Td>
  //     <Table.Td>
  //       <Tooltip label="View All Patient Detail">
  //         <Center>
  //           <IconEye
  //             onClick={() => {
  //               handleClick(element);
  //             }}
  //             size={18}
  //             color="#90EE90"
  //           />
  //         </Center>
  //       </Tooltip>
  //       {/* <Tooltip label="view Patient Detail">
  //         <IconEye size={18} color="#90EE90" />
  //       </Tooltip> */}
  //     </Table.Td>
  //     {/* <Table.Td>{element.severity ?? "High"}</Table.Td> */}
  //   </Table.Tr>
  // ));

  // const handleClick = () => {
  //   navigate(`/CoordinatorDashboard/add-patient/${doctorId}`);
  // };

  return (
    <>
      {/* {dataCollection === false ? ( */}
      <>
        <Flex style={{ alignItems: "center", justifyContent: "space-between" }}>
          <div
            style={{
              paddingTop: "25px",
              paddingLeft: "7px",
              paddingBottom: "-10px",
            }}
          >
            <BreadCamp />
          </div>
          <div
            style={{
              paddingTop: "25px",
              paddingRight: "15px",
              paddingBottom: "-10px",
            }}
          >
            {/* <Button onClick={() => handleClick()} style={{ background: "black" }}>
            Create New Patient
          </Button> */}
          </div>
        </Flex>

        <Box
          style={{
            height: "70vh",
            overflow: "auto",
            margin: "20px",
            // border: "2px solid white",
          }}
        >
          <Space h={"md"} />
          <Table
            style={{ width: "100%" }}
            striped
            //   highlightOnHover
            //   highlightOnHoverColor={"black"}
            withColumnBorders
            stripedColor={"#00526A"}
            stickyHeader
            stickyHeaderOffset={-1}
          >
            <Table.Thead style={{ background: "black" }}>
              <Table.Tr>
                {tableDataHeader.map((items) => {
                  return (
                    <Table.Th c={"white"} key={items}>
                      {items}
                    </Table.Th>
                  );
                })}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody style={{ overflowY: "auto" }}>
              {elementsPatients.map((element, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td>{idx + 1}</Table.Td>
                  <Table.Td>{element.name}</Table.Td>
                  <Table.Td>{element.email}</Table.Td>
                  <Table.Td>
                    {/* <Center> */}
                    <Space h={"md"}>
                      <Tooltip label="View All Patient Detail">
                        <IconEye
                          onClick={() => {
                            handleClick(element);
                          }}
                          size={18}
                          color="#90EE90"
                        />
                      </Tooltip>

                      {/* add code to open a stepper */}
                      <Tooltip label="Update patient info">
                        <IconCirclePlus
                          size={18}
                          color="#90EE90"
                          onClick={() => {
                            handleClickData(element);
                          }}
                        />
                      </Tooltip>
                    </Space>
                    {/* </Center> */}
                  </Table.Td>
                  {/* <Table.Td>{element.severity ?? "High"}</Table.Td> */}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Box>
      </>
      {/* ) : ( */}

      {/* // <>wwd </> */}
      {/* )} */}
    </>
  );
};

export default PhysioPatientListOverview;
