import {
  Button,
  Center,
  Container,
  Grid,
  Space,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useLocation } from "react-router-dom";
import { PasswordInput } from "@mantine/core";
// import BreadCamp from "../../../resuable/BreadCamps";

const EditFile = () => {
  const location = useLocation();
  const dataCheck = location.state;
  console.log("dataCheck ", dataCheck);

  // const [breadcampitems, setBreadcampitems] = useState([
  //   { title: "DashBoard", href: "#" },
  //   { title: "Patient List", href: "#" },
  // ]);
  //   console.log("location.state ", location.state);

  // const { patientData } = location.state ? location.state : null;
  // console.log(patientData);
  // console.log("after a");
  //name, email,contact number, password reset
  const form = useForm({
    initialValues: {
      name: "Sahil",
      email: "sdshetty@trusshealth.ai",
      contact: "4496161",
      passwordReset: "Testing",
    },
  });

  const handleClick = () => {
    console.log("update data");
  };

  return (
    <Container c={"white"} h={"100vh"} my={"30"}>
      <Center>
        <Space h={"md"}>
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="Patient Name" {...form.getInputProps("name")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Email" {...form.getInputProps("email")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Contact" {...form.getInputProps("contact")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput label="Password Reset" />
            </Grid.Col>
          </Grid>
          <Space h={"lg"} />
          <Center>
            <Button onClick={() => handleClick()} c={"black"} bg={"#21A18E"}>
              Update
            </Button>
          </Center>
        </Space>
      </Center>
    </Container>
  );
};

export default EditFile;
