import  {  useEffect,useState ,useContext} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "react-phone-number-input/style.css";
import {
  Button,Group,
  Container,Box,
  TextInput,
  PasswordInput,
  Grid,
  Stack,
  Title,
} from "@mantine/core";
import "./AddEditProvider.scss";
import { Loader } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconX, IconCheck,IconCirclePlus } from "@tabler/icons-react";
import { Notification, rem } from "@mantine/core";
import adminService from "../services/adminService";
import {BreadCampContext} from '../context/BreadCampContext';
import { navbarContext } from "./AdminDashboard/Context/AdminDashboard";
import BreadCamp from "../resuable/BreadCamps";
import { AdminDashboard } from "../constants/constants";
export default function AddAdmin() {
  const navigate = useNavigate();
  const [configData, setConfig] = useState([]);
  const [Namerror, setNameError] = useState([]);
  const { setBreadCampList} = useContext(BreadCampContext);

  const location = useLocation();
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const myProps = location.state;
  const [notificationContent, setNotificationContent] = useState({}); // State to manage notification content
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "Add Admin", href: "/admindashboard/admins" },
    { title: "Add Admin", href: "#" },
]);
const { setNavActive } = useContext(navbarContext);

  const [disable, setDisable] = useState(false);
  const form = useForm({
    initialValues: {
      email: "" || myProps?.provider.email,
      password: "" || myProps?.provider.password,
      mobileNumber: myProps?.provider.mobile || "",
      name: myProps?.provider.name || "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length < 5 ? "Passwords must have at least 2 letters" : null,
      mobileNumber: (value) =>
        /^\d{10}$/.test(value) ? null : "Phone number must be 10 digits",
      name: (value) =>
        /^\S+(\s+\S+){1,}$/.test(value) && value.length >= 4
          ? null
          : "Full name must have at least 2 words and be 4 characters or longer",
    },
  });

  const handleBack = () => {
    navigate("/admindashboard/admins", { replace: true });
  };
  const handleChange = async (event) => {
    if (event?.target?.name && event?.target?.name !== "name") {
      setNameError("Full Name should contain first and last name");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (
        form.values.email &&
        form.values.password &&
        form.values.mobileNumber &&
        form.values.name
      ) {
        setLoading(true);
        setDisable(true);

        const adminResponse = await adminService.createAdmin(form);
        if (adminResponse) {
          setDisable(false);
          setLoading(false);
          if (adminResponse.status == 201) {
            setNotificationContent({
              icon: checkIcon,
              color: "teal",
              title: "Success!",
              message: "You have successfully created new Admin Profile",
            });
            setNotificationOpen(true);
            setTimeout(() => {
              navigate("/admindashboard/admins", { replace: true });
            }, 900);
          }
        }
      } else {
        setNotificationContent({
          icon: xIcon,
          color: "red",
          title: "Error!",
          message: "Fill out all Require fields",
        });
        setNotificationOpen(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDisable(false); setNotificationOpen(true);
      const error1 = error.response.data.message;
      setNotificationContent({
        icon: xIcon,
        color: "red",
        title: "Error!",
        message: `${error1}`,
      });
    }
  };
  useEffect(() => {
    setBreadCampList([
      { title: "Admins List", route: "/admindashboard/admins" },
      { title: "Add Admin", href: "#" },
  ])
    setNavActive("Admins");
   
  }, []);
  return (
    <>
      <Helmet>
        <title> Add Admin | Truss Health </title>
      </Helmet>
      <Container className="main-container">
      <Grid>
            <Grid.Col>
                <Group style={{ justifyContent: "space-between" }}>
                    <BreadCamp data={breadcampitems} />
                </Group>
            </Grid.Col>
            </Grid>
            <form onSubmit={form.onSubmit(console.log)} method="post">
 <Stack>
    <Grid>
      <Grid.Col span={8} md={6}>
        <Title c={'white'} order={2}>Add Admin </Title>
      </Grid.Col>
    </Grid>
    <Grid justify="center" align="center">
      <Grid.Col span={8} md={6} lg={6}>
        <TextInput
          withAsterisk
          name="Full Name"
          onChange={handleChange}
          label="Full Name"
          error={Namerror}
          placeholder="Full Name"
          {...form.getInputProps("name")}
          className="textInput"
          required
        />
      </Grid.Col>
      <Grid.Col span={8} md={6} lg={4} >
        <TextInput
          withAsterisk
          placeholder="+1"
          label="Phone Number"
          {...form.getInputProps("mobileNumber")}
          required
          className="passwordInput"
        />
      </Grid.Col>
    </Grid>
    <Grid justify="center" align="center">
      <Grid.Col span={8} md={6}>
        <TextInput
          withAsterisk
          name="email"
          onChange={handleChange}
          label="Email"
          placeholder="Email"
          {...form.getInputProps("email")}
          className="textInput"
        />
      </Grid.Col>
      <Grid.Col span={8} md={6}>
        <PasswordInput
          withAsterisk
          placeholder="Password"
          label="Password"
          {...form.getInputProps("password")}
          required
          className="passwordInput"
        />
      </Grid.Col>
      <Grid.Col span={8} md={12} >
        <Group>
        <Button
          variant="primary"
          fullwidth="true"
          disabled={disable}
          size="sm"
          mt="sm"
          type="submit"
          style={{ marginTop: "10%" }}
          onClick={handleBack}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          fullwidth="true"
          disabled={disable}
          size="sm"
          mt="sm"
          style={{ marginTop: "10%" }}
          loading={loading}
          onClick={handleSubmit}
        >
          {loading ? <Loader /> : "Submit"}
        </Button>
       
        </Group>
      
      </Grid.Col>
      </Grid>

 </Stack>
</form>
        {notificationOpen && (
          <Notification
            icon={notificationContent.icon}
            color={notificationContent.color}
            title={notificationContent.title}
            mt="md"
            onClose={() => setNotificationOpen(false)}
          >
            {notificationContent.message}
          </Notification>
        )}
      </Container>
    </>
  );
}
