import { useEffect, useState } from "react";
import PatientPain from '../../PatientPain';
import PatientExercises from '../../patient/PatientExercise';
import RomSession from '../../../components/ROM/romSession';
import ImageAIPage from '../../ImageAIPage';
import PatientResult from '.././PatientResult';
import ThermalPage from "../../ThermalPage";
import { IconGauge, IconArrowUp, IconHome, IconSeparator, IconHospital, IconFileAnalytics, IconLayersIntersect, IconMessageUp, IconCarGarage, IconNurse, IconUser, IconCookie, IconLock, IconMessage2, IconStretching, IconActivity, IconHelp, IconUserQuestion, IconPhotoScan, IconAccessPoint } from '@tabler/icons-react';

const HookSteper = () => {
    const [postSurgery,setPostsurgery] = useState([
        {
            icon: IconPhotoScan,
            title: 'ImageAI',
            route: '/patientDashboard/ImageAI',
            step: 1,
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
        {
            icon: IconPhotoScan,
            title: 'ThermalAI',
            route: '/patientDashboard/ThermalAI',
            step: 2,
            description:
                'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
        },
        {
            icon: IconGauge,
            title: 'Pain',
            route: '/patientDashboard/pain-data',
            step: 3,
            description:
                'This dust is actually a powerful poison that will even make a pro wrestler sick, Regice cloaks itself with frigid air of -328 degrees Fahrenheit',
        },
        // {
        //   icon: IconUser,
        //   title: 'Tracker',
        //   route:"",
        //   description:
        //     'People say it can run at the same speed as lightning striking, Its icy body is so cold, it will not melt even if it is immersed in magma',
        // },
        // {
        //   icon: IconAccessPoint,
        //   title: 'Community Access',
        //   route:"",
        //   description:
        //     'They’re popular, but they’re rare. Trainers who show them off recklessly may be targeted by thieves',
        // },
        // {
        //   icon: IconMessageUp,
        //   title: 'Message your Doctor',
        //   route:"",
        //   description:
        //     'Although it still can’t fly, its jumping power is outstanding, in Alola the mushrooms on Paras don’t grow up quite right',
        // },
    
        {
            icon: IconStretching,
            title: 'Exercise list',
            route: '/patientDashboard/view-exercise',
            step: 4,
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
        {
            icon: IconActivity,
            title: 'Range of motion',
            route: '/patientDashboard/romInfo',
            step: 5,
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
        // {
        //   icon: IconHelp,
        //   title: 'Help',
        //   route:"",
        //   description:
        //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        // },
        // {
        //   icon: IconUserQuestion,
        //   title: 'Frequently Asked Questions',
        //   route:"",
        //   description:
        //     'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        // },
        {
            icon: IconPhotoScan,
            title: 'Patient Result',
            route: '/patientDashboard/result',
            step: 6,
            description:
                'Rapidash usually can be seen casually cantering in the fields and plains, Skitty is known to chase around after its own tail',
        },
    ])
  
    return { postSurgery }
}

export default HookSteper;