import { ActionIcon, Button, Image, Modal } from "@mantine/core";
import { IconCamera, IconCameraRotate, IconCheck } from "@tabler/icons-react";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import Webcam from "react-webcam";

const CameraComponent = ({ opened, close, dataImg, setImg, checkUpdate }) => {
  const videoRef = useRef(null);

  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: "user",
  });
  return (
    <Modal.Root opened={opened} fullScreen centered onClose={close}>
      <Modal.Overlay />
      <Modal.Content bg={"black"}>
        <Modal.Header bg={"black"}>
          <Modal.Title></Modal.Title>
          <Modal.CloseButton c="#fff" />
        </Modal.Header>
        <Modal.Body
          p="0"
          maw="100%"
          display={"flex"}
          pos={"relative"}
          style={{ flexDirection: "column" }}
        >
          {!dataImg && (
            <Webcam
              videoConstraints={videoConstraints}
              ref={videoRef}
              width={"100%"}
              height={"100%"}
            />
          )}
          {dataImg && (
            <Image height="100%" w="100%" fit="contain" src={dataImg} />
          )}
        </Modal.Body>

        {dataImg && (
          <Button
            variant="filled"
            ml="xl"
            mb="xl"
            pos={"absolute"}
            bottom="0"
            left="0"
            onClick={() => {
              setImg("");
            }}
          >
            Retake
          </Button>
        )}
        {!dataImg && (
          <ActionIcon
            mb="xl"
            pos={"absolute"}
            bottom="0"
            left="50%"
            aria-label="Settings"
            bg="none"
            w="48px"
            h="48px"
            p="sm"
            radius="100%"
            style={{ border: "1px solid #fff" }}
            onClick={() => {
              if (videoRef.current) {
                const src = videoRef.current.getScreenshot();
                setImg(src);
                checkUpdate(1);
              }
            }}
          >
            <IconCamera style={{ width: "100%", height: "100%" }} />
          </ActionIcon>
        )}
        {dataImg && (
          <ActionIcon
            mb="xl"
            pos={"absolute"}
            bottom="0"
            left="50%"
            aria-label="Settings"
            bg="none"
            w="48px"
            h="48px"
            p="sm"
            radius="100%"
            style={{ border: "1px solid #fff" }}
            onClick={() => {
              close();
            }}
          >
            <IconCheck style={{ width: "100%", height: "100%" }} />
          </ActionIcon>
        )}
        <ActionIcon
          bg="none"
          mb="xl"
          mr="xl"
          pos={"absolute"}
          bottom="0"
          right="0"
          w="48px"
          h="48px"
          onClick={() => {
            if (videoConstraints.facingMode === "user") {
              setVideoConstraints({ facingMode: "environment" });
            }
            if (videoConstraints.facingMode === "environment") {
              setVideoConstraints({ facingMode: "user" });
            }
          }}
        >
          <IconCameraRotate
            style={{ width: "100%", height: "100%" }}
            stroke={0.8}
          />
        </ActionIcon>
      </Modal.Content>
    </Modal.Root>
  );
};

export default CameraComponent;
CameraComponent.propTypes = {
  opened: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  dataImg: PropTypes.string.isRequired,
  setImg: PropTypes.func.isRequired,
  checkUpdate: PropTypes.func,
};
