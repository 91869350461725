import { useState, useEffect } from 'react';
import cx from 'clsx';
import { Table, Checkbox, ScrollArea, Group, Avatar, Text, rem } from '@mantine/core';
import classes from './PatientRomView.module.css';
import moment from 'moment';

const data = [
    {
        id: '1',
        avatar:
            'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png',
        name: 'Robert Wolfkisser',
        job: 'Engineer',
        email: 'rob_wolf@gmail.com',
    },
    {
        id: '2',
        avatar:
            'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-7.png',
        name: 'Jill Jailbreaker',
        job: 'Engineer',
        email: 'jj@breaker.com',
    },
    {
        id: '3',
        avatar:
            'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-2.png',
        name: 'Henry Silkeater',
        job: 'Designer',
        email: 'henry@silkeater.io',
    },
    {
        id: '4',
        avatar:
            'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-3.png',
        name: 'Bill Horsefighter',
        job: 'Designer',
        email: 'bhorsefighter@gmail.com',
    },
    {
        id: '5',
        avatar:
            'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-10.png',
        name: 'Jeremy Footviewer',
        job: 'Manager',
        email: 'jeremy@foot.dev',
    },
];

function PatientRomView(props) {
    const { PatientRomdata } = props
    const [selection, setSelection] = useState(['1']);
    const toggleRow = (id) =>
        setSelection((current) =>
            current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
        );
    const toggleAll = () =>
        setSelection((current) => (current.length === data.length ? [] : data.map((item) => item.id)));

        const rows = PatientRomdata.map((item,index) => {
            // const selected = selection.includes(item.id);
            return (
              <Table.Tr key={index} 
            //   className={cx({ [classes.rowSelected]: selected })}
              >
                <Table.Td c={'black'}>
                    { moment(item.createddate).format('MMMM Do YYYY, h:mm A')}
                </Table.Td>
                <Table.Td c={'black'}>{item.activeMotion.toFixed(3)}</Table.Td>
                <Table.Td c={'black'}> {item.angleDifference.toFixed(3)}</Table.Td>
              </Table.Tr>
            );
          });
          return (
            <ScrollArea>
              <Table 
             // miw={800}
               verticalSpacing="sm">
                <Table.Thead>
                  <Table.Tr>
                    {/* <Table.Th style={{ width: rem(40) }}>
                      <Checkbox
                        onChange={toggleAll}
                        checked={selection.length === data.length}
                        indeterminate={selection.length > 0 && selection.length !== data.length}
                      />
                    </Table.Th> */}
                    <Table.Th>Date & Time</Table.Th>
                    <Table.Th>activeMotion</Table.Th>
                    <Table.Th>angleDifference</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
            </ScrollArea>
          );

}
export default PatientRomView;
