import {useContext} from 'react';
import { Center, Container } from "@mantine/core";
import PatientHeader from "../components/ImageAI/PatientHeader";
import PainBody from "../components/Pain/PainBody";
import {BreadCampContext} from '../context/BreadCampContext';
import { useEffect } from "react";
const PatientPain = () => {
  const { setBreadCampList} = useContext(BreadCampContext);
  useEffect(()=>{
    setBreadCampList([
      { title: "Patient Guidance", route: "/patientDashboard/guidance" },
      { title: "Pain", },
    ])
  },[])
  return (

    <Container fluid p="0">
        <Center>
          <PainBody />
        </Center>
    </Container>
  );
};

export default PatientPain;
