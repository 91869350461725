import Recat,{useContext} from 'react';
import PatientPain from '../../components/PainUI/index';
import { PatientDailyworkoutContext } from '../../context/PatientDailyWorkoutContext';

const PatientPainUI = () => {
    const { steperActive, setSteperActive, surgerydata, setSurgerydata } = useContext(PatientDailyworkoutContext)
    console.log(surgerydata)
    return (
        <>
                <PatientPain />
        </>
    )
}
export default PatientPainUI;