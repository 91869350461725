import { Grid, Group, Modal, Table, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PhysioLanding,
  routePhysiotherapistDoctorTab,DoctorDasboard
} from "../../../../constants/constants";
import BreadCamp from "../../../../resuable/BreadCamps";
import dailyReportService from "../../../../services/dailyReportService";
import { navbarContext } from "../../Context/PhysiotherapistDashboard";
// import { DoctorDasboard } from "../../../../constants/constants";
import { BreadCampContext } from "../../../../context/BreadCampContext";
import PatientRomView from "./PatientRomView";
import ViewPainGraph from "./viewPainGraph";
import ViewRomGraph from "./viewRomGraph";
import NotFoundComp from '../../../../resuable/NotFoundComp';
import StatsGrid from "../../../DoctorDashBoard/Overview/PatientDetailOverview/RiskLevelShowCard";

const data = [
  {
    date: "Mar 22",
    Apples: 2890,
    Oranges: 2338,
    Tomatoes: 2452,
  },
  {
    date: "Mar 23",
    Apples: 2756,
    Oranges: 2103,
    Tomatoes: 2402,
  },
  {
    date: "Mar 24",
    Apples: 3322,
    Oranges: 986,
    Tomatoes: 1821,
  },
  {
    date: "Mar 25",
    Apples: 3470,
    Oranges: 2108,
    Tomatoes: 2809,
  },
  {
    date: "Mar 26",
    Apples: 3129,
    Oranges: 1726,
    Tomatoes: 2290,
  },
];
function PatientDetailOverview(props) {
  const { setBreadCampList } = useContext(BreadCampContext);
  const params = useParams();
  const { setNavActive } = useContext(navbarContext);
  const [sortedData, setSortedData] = useState([]);
  const [list, setList] = useState([]);
  const [rows, setRows] = useState([]);
  const [patientListshow, setPatientListshow] = useState(true);
  const [createpatientshow, setCreatepatientshow] = useState(false);
  const navigate = useNavigate();
  const [noTransitionOpened, setNoTransitionOpened] = useState(false);
  const [graphOpen, setGraphOpen] = useState(false);
  const [graphData, setGraphData] = useState();
  const [graphPainData, setGrapPainhData] = useState();
  const [graphRiskData, setGrapRiskhData] = useState();

  const [graphPainOpen, setGraphPainopen] = useState();
  const [graphRiskOpen, setGraphRiskopen] = useState();

  const [PatientRomdata, setPatientRomdata] = useState();
  const [daysdata, setDaysdata] = useState([]);
  const [riskType, setRiskType] = useState();
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "OverView", href: "#" },
    { title: "Patient List", href: "#" },
  ]);
  const [imageAUrl, setImageAUrl] = useState();

  const fetchsignedurl = async (data, set) => {
    const apicall = await dailyReportService.getSignedUrl({ imageurl: data });

    if (apicall.status === 200) {
      // imagestick=apicall.data.url
      if (apicall.data.url) {
        setImageAUrl(apicall.data.url);
        set.signurl = apicall.data.url;
        // return apicall.data.url;
      }

      // set.signurl = apicall.data.url;

      // return apicall.data.url
    } else {
      // return ''
    }
  };
  const FetchPatientList = async () => {
    console.log(params)
    const apicall = await dailyReportService.getDailyReportbyPatientId(
      params.patientId
    );

    if (apicall.statusCode === 200) {
      const setdata = apicall.data.result;
      setDaysdata(apicall.data.days);
      setRiskType(apicall?.data?.risktype);
      const filterkeydata = [];

      setdata.forEach((element) => {
        filterkeydata.push({
          name: element.createddate,
          risklevel:
            element.risklevel === null ? 0 : element.risklevel.toFixed(2),
          mobile: element.createddate,
          id: element.patientId,
          romdata: element.patientrom,
          imageai: element.patientimageai,
          day: element.day,
          paindata: element.patientpain,
          daysname: element.daysname,
        });
      });

      if (filterkeydata?.length > 0) {
        filterkeydata?.forEach((edc) => {
          if (edc.imageai.length > 0) {
            edc.imageai.forEach((efv) => {
              fetchsignedurl(efv.topAngleImage, efv);
            });
          }
        });
      } else {
      }

      setList(filterkeydata);
      setSortedData(filterkeydata);
    }
  };
  useEffect(() => {
    setBreadCampList([
      { title: "Dashboard", route: DoctorDasboard },
      { title: "Patient Data" },
    ]);
    setNavActive("Dashboard");
    FetchPatientList();
  }, []);

  const dataObject = {
    key1: "value1",
    key2: "value2",
  };

  // useEffect(() => {
  //   const rowdata = sortedData.map((row, index) => (
  //     <Table.Tr key={index}>
  //       <Table.Td>
  //         {moment(row.name, "DD-MM-YYYY").format("MMM Do YY")}
  //       </Table.Td>
  //       <Table.Td>{row.risklevel} %</Table.Td>
  //       <Table.Td>{row.risklevel} %</Table.Td>
  //       <Table.Td align="center">
  //         {row.romdata.length > 0 ? (
  //           <Tooltip
  //             onClick={() => {
  //               setNoTransitionOpened(true), setPatientRomdata(row.romdata);
  //             }}
  //             label="view Patient ROM Data"
  //           >
  //             <IconEye size={18} color="#90EE90" />
  //           </Tooltip>
  //         ) : (
  //           "N/A"
  //         )}{" "}
  //       </Table.Td>
  //       <Table.Td align="center">
  //         <Group align="center" w={"100%"} maw={"100%"}>
  //           <Tooltip label="view Patient Detail">
  //             <IconEye size={18} color="#90EE90" />
  //           </Tooltip>
  //         </Group>
  //       </Table.Td>
  //       <Table.Td align="center">
  //         <Group maw={"100%"} w={"100%"} align="center">
  //           <Tooltip label="view Patient Image">
  //             <IconEye size={18} color="#90EE90" />
  //           </Tooltip>
  //         </Group>
  //       </Table.Td>
  //     </Table.Tr>
  //   ));
  //   setRows(rowdata);
  // }, [sortedData]);

  const ClickCreatePatient = () => {
    navigate("/doctorDashboard/add-patient");
  };
  const typereturnfunction = (index) => {
    if (index === 0) {
      return "Risk Level";
    } else if (index === 1) {
      return "Pain Level";
    } else if (index === 2) {
      return "Range Of Motion";
    } else if (index === 3) {
      return "ImageAI";
    }
  };
  const fetchrisklevelvalue = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });
    console.log(day, filter,list)
    return filter[0]?.risklevel ? filter[0].risklevel : "N/A";
  };
  const fetchRangeOfMotionlevelvalue = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });

    if (filter[0]?.romdata?.length > 0) {
      const rom = filter[0].romdata;
      const angledata = `${rom[rom.length - 1].maxrom?.toFixed(2)}`;
      return angledata;
    } else {
      return "N/A";
    }
    //  return  filter[0].risklevel ?filter[0].risklevel:"N/A"
  };
  const fetchImageAIimage = (day) => {
    const filter = list.filter((ex) => {
      return ex.daysname === day;
    });

    if (filter[0]?.imageai?.length > 0) {
      const imageurl =
        filter[0]?.imageai[filter[0]?.imageai?.length - 1].signurl;

      return <img style={{borderRadius:'5px',padding:'1px'}} width="150px" height="150px" src={imageAUrl} alt="imageai" />;
    } else {
      return "N/A";
    }
  };

  const returndatavaluefunction = (type, day) => {
    if (type === "Risk Level" || type === "Pain Level") {
      if (day === "day1") {
        console.log('Risk Level')
        return fetchrisklevelvalue("day1");
      } else if (day === "day2") {
        return fetchrisklevelvalue("day2");
      } else if (day === "day3") {
        return fetchrisklevelvalue("day3");
      } else if (day === "day4") {
        return fetchrisklevelvalue("day4");
      }
    } else if (type === "Range Of Motion") {
      if (day === "day1") {
        return fetchRangeOfMotionlevelvalue("day1");
      } else if (day === "day2") {
        return fetchRangeOfMotionlevelvalue("day2");
      } else if (day === "day3") {
        return fetchRangeOfMotionlevelvalue("day3");
      } else if (day === "day4") {
        return fetchRangeOfMotionlevelvalue("day4");
      }
    } else if (type === "ImageAI") {
      if (day === "day1") {
        return fetchImageAIimage("day1");
      } else if (day === "day2") {
        return fetchImageAIimage("day2");
      } else if (day === "day3") {
        return fetchImageAIimage("day3");
      } else if (day === "day4") {
        return fetchImageAIimage("day4");
      }
    }
  };
  const clickGraph = (index, item) => {
    if (index === 0) {
      setGraphRiskopen(true);
      setGrapRiskhData(list);
    } else if (index === 1) {
      setGraphPainopen(true);
      setGrapPainhData(list);
    } else if (index === 2) {
      setGraphOpen(true), setGraphData(list);
    } else if (index === 3) {
      return "ImageAI";
    }
  };
  return (
    <>
      {daysdata.length > 0 ? (
        <Grid>
          <Grid.Col display="flex" style={{ justifyContent: "space-between" }}>
            <Group style={{ justifyContent: "space-between" }}>
              <BreadCamp />
            </Group>
          </Grid.Col>
          <Grid.Col display="flex" style={{ justifyContent: "end" }}>
            <Group>
              <StatsGrid riskType={riskType} />
            </Group>
          </Grid.Col>
          <Grid.Col display="flex">
            <table
              className="detailcustomtable"
              style={{ width: "100%", borderTop: "1px solid #ffff" }}
            >
              <tr style={{ backgroundColor: "skyblue", color: "black" }}>
                <th style={{ backgroundColor: "skyblue", color: "black" }}>
                  {params.patientname}
                </th>
                {daysdata.map((el) => {
                  return (
                    <td style={{ textTransform: "capitalize", color: "black" }}>
                      {el.day}
                    </td>
                  );
                })}
                <th style={{ color: "black" }}>Graph</th>
              </tr>
              {["1", "2", "3", "4"].map((itend, indext) => {
                return (
                  <tr key={indext}>
                    <th className="tableshhet_0" style={{ width: "10%" }}>
                      {typereturnfunction(indext)}
                    </th>
                    {daysdata.map((el, index) => {
                      return (
                        <>
                          <td key={index} className={`tableshhet_${index + 1}`}>
                            {returndatavaluefunction(
                              typereturnfunction(indext),
                              `day${index + 1}`
                            )}
                          </td>
                        </>
                      );
                    })}
                    {indext === 3 ? (
                      ""
                    ) : (
                      <>
                        {indext === 2 ? (
                          <>
                            <td
                              className="graph_row"
                              style={{
                                width: "30%",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                clickGraph(indext);
                              }}
                            >
                              <div
                                style={{
                                  background: "#ffff",
                                  margin: "2%",
                                  padding: "2%",
                                  borderRadius: "10px",
                                }}
                              >
                                <ViewRomGraph graphData={list} />
                              </div>
                            </td>
                          </>
                        ) : (
                          <td
                            className="graph_row"
                            style={{
                              width: "30%",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              clickGraph(indext);
                            }}
                          >
                            <div
                              style={{
                                background: "#ffff",
                                margin: "2%",
                                padding: "2%",
                                borderRadius: "10px",
                              }}
                            >
                              <ViewPainGraph graphData={list} />
                            </div>
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}

              {/* </>
                              )
                        })} */}
            </table>
          </Grid.Col>
        </Grid>
      ) : (
        <NotFoundComp />
      )}
      <Modal
        size={"80%"}
        opened={noTransitionOpened}
        onClose={() => setNoTransitionOpened(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <PatientRomView PatientRomdata={PatientRomdata} />
      </Modal>
      <Modal
        size={"80%"}
        opened={graphOpen}
        onClose={() => setGraphOpen(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <ViewRomGraph graphData={graphData} />
      </Modal>
      <Modal
        size={"80%"}
        opened={graphPainOpen}
        onClose={() => setGraphPainopen(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <ViewPainGraph graphData={graphPainData} />
      </Modal>
      <Modal
        size={"80%"}
        opened={graphRiskOpen}
        onClose={() => setGraphRiskopen(false)}
        // title="Please consider this"
        transitionProps={{
          transition: "fade",
          duration: 600,
          timingFunction: "linear",
        }}
      >
        <ViewPainGraph graphData={graphRiskData} />
      </Modal>
    </>
  );
}
export default PatientDetailOverview;
