import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  TextInput,
  PasswordInput,
  Box,
  Stack,
} from "@mantine/core";
import moment from "moment";

import "./LoginForm.scss";
import { Loader } from "@mantine/core";
import loginService from "../../../services/loginService";
import patientService from "../../../services/patientService";
import { useForm } from "@mantine/form";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Notification, rem } from "@mantine/core";
import PropTypes from "prop-types";
import {
  DoctorDasboard,
  PhysioLanding,
  routeCoordinatorDoctorTab,
  routePhysiotherapistDoctorTab,
} from "../../../constants/constants";

LoginForm.propTypes = {
  userType: PropTypes.string,
};
export default function LoginForm({ userType }) {
  const navigate = useNavigate();
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const [notificationContent, setNotificationContent] = useState({}); // State to manage notification content
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [disable, setDisable] = useState(false);

  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length < 5 ? "Passwords must have at least 2 letters" : null,
    },
  });

  const handleClick = async (event) => {
    try {
      event.preventDefault();
      form.onSubmit((values) => console.log(values));
      event.preventDefault();
      setLoading(true);
      setDisable(true);
      if (form.values.email && form.values.password) {
        const loginRes = await loginService.loginUser(
          form.values.email,
          form.values.password,
          userType
        );

        loginService.doLogin(loginRes?.data.token);
        loginService.setUserType(userType);
        if (loginRes?.data.token) {
          console.log("loginRes?.data.token ", loginRes?.data.token);
          setNotificationContent({
            icon: checkIcon,
            color: "teal",
            title: "Success!",
            message: "You have successfully logged in!",
          });
          setNotificationOpen(true);
          if (userType === "doctor") {
            localStorage.setItem("namefield", loginRes.data.username);
            console.log(DoctorDasboard);
            navigate(DoctorDasboard);
          } else if (userType === "admin")
            setTimeout(() => {
              navigate("/admindashboard/providers", { replace: true });
            }, 900);
          // else if (userType !== 'patient')
          //   setTimeout(() => {
          //     navigate("/patients/list", { replace: true });
          //   }, 900);
          else if (userType === "patient") {
            localStorage.setItem("namefield", loginRes.data.data.username);
             navigate("/patientDashboard/last-data", {
              replace: true,
            });
            // navigate(`/patientdata/patient/doctor/${loginRes.data.data.doctorId}/${loginRes.data.data.username}/${loginRes.data.data.patientId}/pain/pain`)
            // const responsep = await patientService.getQuestionListbyPatientId()
            // console.log(responsep)
            // if (responsep.statusCode === 200 && responsep.data) {
            //   const dateOfSurgery = responsep.data.assignquestion[0]?.answer;
            //   const currentdate = moment()
            //   const surgeryDate = moment(dateOfSurgery);
            //   const day = surgeryDate.diff(currentdate, "days");
            //   const condition =
            //     day > 0 ? "preSurgery" : "postSurgery";
            //   if (day === 0) {
            //     // same day surgery condition
            //     navigate("/patientDashboard/guidance", {
            //       state: {
            //         condition
            //       },
            //       replace: true,
            //     });
            //   }
            //   else if (day > 0) {
            //     // post surgery condition
            //     navigate("/patientDashboard/guidance", {
            //       state: {
            //         condition
            //       },
            //       replace: true,
            //     });
            //   }
            //   else if (day < 0) {
            //     // pre surgery condition
            //     navigate("/patientDashboard/guidance", {
            //       state: {
            //         condition
            //       },
            //       replace: true,
            //     });
            //   }
            // }
            // else {
            //   navigate("/patientDashboard/patient-form")
            // }
            // const dateOfSurgery = responsep.data?.assignquestion[0]?.answer;
            // const date = moment();
            // const surgeryDate = moment(dateOfSurgery);
            // const day = surgeryDate.diff(date, "days");
            // if(dateOfSurgery == null || dateOfSurgery == undefined)
            // {
            //   navigate("/patient-form")
            // }
            // else if (day < 0)
            // {
            //   navigate("/guidance", {
            //     state: {
            //       condition: "postSurgery",
            //     },
            //     replace: true,
            //   });

            // }
            // else if (day >  0)
            // {
            //   navigate("/guidance", {
            //     state: {
            //       condition: "preSurgery",
            //     },
            //     replace: true,
            //   });

            // }
            // else if (day === 1) {
            //   navigate("/guidance", {
            //     state: {
            //       condition: "dayBeforeSurgery",
            //     },
            //     replace: true,
            //   });
            // }
          } else if (userType === "coordinator") {
            localStorage.setItem("namefield", loginRes.data?.username);
            navigate(routeCoordinatorDoctorTab);
          } else if (userType === "physiotherapist") {
            localStorage.setItem("namefield", loginRes.data?.username);
            navigate(PhysioLanding + routePhysiotherapistDoctorTab);
          }
        } else {
          setDisable(false);
          setLoading(false);
          console.log("Unsuccessful login");
        }
      } else {
        setDisable(false);
        setLoading(false);
        setNotificationContent({
          icon: xIcon,
          color: "red",
          title: "Error!",
          message: `Enter All Required Fields`,
        });
        setNotificationOpen(true);
      }
    } catch (err) {
      console.log("inside error a a", err);
      setLoading(false);
      setDisable(false);
      if (err.response?.request?.status == "500") {
        setNotificationContent({
          icon: xIcon,
          color: "red",
          title: "Error!",
          message: `Credential Not Found`,
        });
        setNotificationOpen(true);
      } else {
        const error = err.response?.data.message;
        setNotificationContent({
          icon: xIcon,
          color: "red",
          title: "Error!",
          message: `${error}`,
        });
        setNotificationOpen(true);
      }
      console.log(err);
    } finally {
      setDisable(false);
      setLoading(false);
    }
  };

  const textInputChange = (evt) => {
    const { name, value } = evt.target;
    form.setFieldValue(name, value);
    console.log("event is ", evt);
    setNotificationOpen(false);
  };

  return (
    <>
      <Container fluid={true}>
        <Box maw={"80%"} mx="auto">
          <form onSubmit={handleClick} method="post">
            <Stack gap={22}>
              <TextInput
                withAsterisk
                name="email"
                onChange={textInputChange}
                label="Email"
                placeholder="Enter Your Mail ID"
                {...form.getInputProps("email")}
                className="textInput"
                data-testid="LoginEmail"
                // description="Enter Your Mail ID"
              />

              <PasswordInput
                withAsterisk
                placeholder="Enter Password"
                label="Password"
                // description="Password must include at least one letter, number and special character"
                {...form.getInputProps("password")}
                required
                data-testid="LoginPassword"
              />
            </Stack>
            <Stack>
              <Button
                variant="primary"
                fullwidth="true"
                disabled={disable}
                size="sm"
                mt="sm"
                c="black"
                type="submit"
                style={{ marginTop: "10%" }}
                loading={loading}
                onClick={handleClick}
                data-testid="SubmitLogin"
              >
                {loading ? <Loader /> : "Login"}
              </Button>
              {notificationOpen && (
                <Notification
                  icon={notificationContent.icon}
                  color={notificationContent.color}
                  title={notificationContent.title}
                  mt="md"
                  onClose={() => setNotificationOpen(false)}
                >
                  {notificationContent.message}
                </Notification>
              )}
            </Stack>
          </form>
        </Box>
      </Container>
    </>
  );
}
