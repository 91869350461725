import { useState , useContext ,useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "react-phone-number-input/style.css";
import {
  Button,
  Container,Group,
  TextInput,
  PasswordInput,
  Grid,
  Stack,
  Title,
} from "@mantine/core";
import "./AddEditProvider.scss";
import { Loader } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Notification, rem } from "@mantine/core";
import coordinatorService from "../services/CoordinatorService";
import physioService from "../services/physioService";
import adminPhysioService from "../services/OrganisationAdmin/PhysiotherapistService/PhysiotherapistService";
import BreadCamp from "../resuable/BreadCamps";
import { BreadCampContext } from "../context/BreadCampContext";
import { navbarContext } from "./AdminDashboard/Context/AdminDashboard";

export default function AddEditPhysio() {
  const navigate = useNavigate();
  const [Namerror, setNameError] = useState([]);
  const location = useLocation();
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const myProps = location.state;
  const [notificationContent, setNotificationContent] = useState({}); // State to manage notification content
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [disable, setDisable] = useState(false);
  const [breadcampitems, setBreadcampitems] = useState([
    { title: "Coordinators", href: "/admindashboard/coordinators" },
    { title: "Add Coordinators", href: "#" },
]);
const { setNavActive } = useContext(navbarContext);
const { setBreadCampList} = useContext(BreadCampContext);
  const form = useForm({
    initialValues: {
      email: "" || myProps?.provider.email,
      password: "" || myProps?.provider.password,
      mobileNumber: myProps?.provider.mobile || "",
      name: myProps?.provider.name || "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length < 5 ? "Passwords must have at least 2 letters" : null,
      mobileNumber: (value) =>
        /^\d{10}$/.test(value) ? null : "Phone number must be 10 digits",
      name: (value) =>
        /^\S+(\s+\S+){1,}$/.test(value) && value.length >= 4
          ? null
          : "Full name must have at least 2 words and be 4 characters or longer",
    },
  });
  const handleBack = () => {
    navigate("/admindashboard/physiotherapist", { replace: true });
  };
  const handleChange = async (event) => {
    if (event?.target?.name && event?.target?.name !== "name") {
      setNameError("Full Name should contain first and last name");
    }
  };

  const handleSubmit = async (event) => {
    console.log("event ", event);
    console.log("Form data ", form);
    const formData = {
      name: form.values.name,
      email: form.values.email,
      password: form.values.password,
      mobile: form.values.mobileNumber,
    };

    await adminPhysioService.createPhysio(formData);
    navigate("/admindashboard/physiotherapist");

    // event.preventDefault();
    // if (myProps?.provider._id) {
    //   try {
    //     if (
    //       form.values.email &&
    //       form.values.password &&
    //       form.values.mobileNumber &&
    //       form.values.name
    //     ) {
    //       setLoading(true);
    //       setDisable(true);
    //       const providerReponse = await coordinatorService.updateUser(
    //         myProps.provider._id,
    //         form
    //       );
    //       if (providerReponse) {
    //         setDisable(false);
    //         setLoading(false);
    //         if (providerReponse.status == 200) {
    //           setNotificationContent({
    //             icon: checkIcon,
    //             color: "teal",
    //             title: "Success!",
    //             message: "You have successfully Updated Doctor's Info",
    //           });
    //           setNotificationOpen(true);
    //           setTimeout(() => {
    //             navigate("/physio/list", { replace: true });
    //           }, 900);
    //         }
    //       }
    //     } else {
    //       setNotificationContent({
    //         icon: xIcon,
    //         color: "red",
    //         title: "Error!",
    //         message: "Fill out all Require fields",
    //       });
    //       setNotificationOpen(true);
    //       setLoading(false);
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //     setNotificationContent({
    //       icon: checkIcon,
    //       color: "teal",
    //       title: "Success!",
    //       message: error,
    //     });
    //     setDisable(false);
    //     const error1 = error.response.data.message;
    //     setNotificationContent({
    //       icon: xIcon,
    //       color: "red",
    //       title: "Error!",
    //       message: `${error1}`,
    //     });
    //     setNotificationOpen(true);
    //     setNotificationOpen(true);
    //   }
    // } else {
    //   try {
    //     if (
    //       form.values.email &&
    //       form.values.password &&
    //       form.values.mobileNumber &&
    //       form.values.name
    //     ) {
    //       setLoading(true);
    //       setDisable(true);
    //       const providerReponse = await physioService.createPhysio(form);
    //       if (providerReponse) {
    //         setDisable(false);
    //         setLoading(false);
    //         if (providerReponse.status == 201) {
    //           setNotificationContent({
    //             icon: checkIcon,
    //             color: "teal",
    //             title: "Success!",
    //             message: "You have successfully logged in!",
    //           });
    //           setNotificationOpen(true);
    //           setTimeout(() => {
    //             navigate("/coordinator/list", { replace: true });
    //           }, 900);
    //         }
    //       }
    //     } else {
    //       alert("err");
    //       setNotificationContent({
    //         icon: xIcon,
    //         color: "red",
    //         title: "Error!",
    //         message: "Fill out all Require fields",
    //       });
    //       setNotificationOpen(true);
    //       setLoading(false);
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //     setNotificationContent({
    //       icon: checkIcon,
    //       color: "teal",
    //       title: "Success!",
    //       message: error,
    //     });
    //     setDisable(false);
    //     const error1 = error.response.data.message;
    //     setNotificationContent({
    //       icon: xIcon,
    //       color: "red",
    //       title: "Error!",
    //       message: `${error1}`,
    //     });
    //     setNotificationOpen(true);
    //     setNotificationOpen(true);
    //   }
    // }
  };
  useEffect(() => {
    setBreadCampList([
      { title: "Physiotherapists List", route: "/admindashboard/physiotherapist" },
      { title: "Add Physiotherapists", href: "#" },
  ])
    setNavActive("Admins");
   
  }, []);
  return (
    <>
      <Helmet>
        <title> Add Physiotherapist | Truss Health </title>
      </Helmet>

      <Container className="main-container">
      <Grid>
            <Grid.Col>
                <Group style={{ justifyContent: "space-between" }}>
                    <BreadCamp data={breadcampitems} />
                </Group>
            </Grid.Col>
            </Grid>
        <form onSubmit={form.onSubmit(console.log)} method="post">
          <Stack>
            <Grid>
              <Grid.Col span={10}>
                <Title c={"#ffff"} order={2}>
                  Add Physiotherapist{" "}
                </Title>
              </Grid.Col>
            </Grid>
            <Grid justify="center" align="center">
              <Grid.Col span={5}>
                <TextInput
                  withAsterisk
                  name="Full Name"
                  onChange={handleChange}
                  label="Full Name"
                  error={Namerror}
                  placeholder="Full Name"
                  {...form.getInputProps("name")}
                  className="textInput"
                  required
                  description="Enter Physiotherapist's Full Name"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  withAsterisk
                  placeholder="+1"
                  label="Phone Number"
                  description="Phone Number must be 10 digit"
                  {...form.getInputProps("mobileNumber")}
                  required
                  className="passwordInput"
                />
              </Grid.Col>
            </Grid>
            <Grid justify="center" align="center">
              <Grid.Col span={5}>
                <TextInput
                  withAsterisk
                  name="email"
                  onChange={handleChange}
                  label="Email"
                  placeholder="Email"
                  {...form.getInputProps("email")}
                  className="textInput"
                  description="Enter Physiotherapist's Mail ID"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <PasswordInput
                  withAsterisk
                  placeholder="Password"
                  label="Password"
                  description="Password must include at least one letter, number and special character"
                  {...form.getInputProps("password")}
                  required
                  className="passwordInput"
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <Button
                  variant="primary"
                  fullwidth="true"
                  disabled={disable}
                  size="sm"
                  mt="sm"
                  type="submit"
                  style={{ marginTop: "10%" }}
                  onClick={handleBack}
                >
                  Cancel
                </Button>
              </Grid.Col>
              <Grid.Col span={3}>
                <Button
                  variant="primary"
                  fullwidth="true"
                  disabled={disable}
                  size="sm"
                  mt="sm"
                  style={{ marginTop: "10%" }}
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {loading ? <Loader /> : "Submit"}
                </Button>
              </Grid.Col>
            </Grid>
          </Stack>
        </form>
        {notificationOpen && (
          <Notification
            icon={notificationContent.icon}
            color={notificationContent.color}
            title={notificationContent.title}
            mt="md"
            onClose={() => setNotificationOpen(false)}
          >
            {notificationContent.message}
          </Notification>
        )}
      </Container>
    </>
  );
}
