//for local
import {
  IconNotes,
  IconCalendarStats,
  IconGauge,
  IconUsers,
  IconPresentationAnalytics,
  IconFileAnalytics,
  IconAdjustments,
  IconLock,
  IconHealthRecognition,
  IconPhysotherapist,IconLogout
} from "@tabler/icons-react";
// export const BASEURL = "http://localhost:8000";

//for prod
// export const BASEURL = "https://truss-server-nosql-z3ikajrtza-uc.a.run.app";

//for staging
  export const BASEURL =
    "https://truss-server-nosql-staging-z3ikajrtza-uc.a.run.app";
// export const BASEURL = import.meta.env.REACT_APP_BASE_URL;

export const PREPROCEDUREOPTIONS = [
  { name: "Patient surgery Date", id: 1, seq: 1 },
  { name: "Weight Question", id: 2, seq: 2 },
  { name: "Gender", id: 3, seq: 3 },
  { name: "Existing Conditions", id: 4, seq: 4 },
];

export const PREPROCEDUREOLDOPTIONS = [
  { name: "Welcome Back", id: 1, seq: 1 },
  { name: "Knee Pain", id: 2, seq: 2 },
];

export const SAMEDAYOPTIONS = [{ name: "Description", id: 1, seq: 1 }];

export const POSTPROCEDUREOPTIONS = [
  { name: "Pain", id: 1, seq: 1 },
  { name: "Photo", id: 2, seq: 2 },
  { name: "Body", id: 3, seq: 3 },
  { name: "Mental Health", id: 4, seq: 4 },
  { name: "Blood Test", id: 5, seq: 5 },
  { name: "Other", id: 6, seq: 6 },
];

// const DoctorDashboard=[
//   {name:'DashBoard',route:"/doctorDashboard"},
//   {name:'Patient',route:"/doctorDashboard/patientlist"},
//   {name:'Exercise',route:"/doctorDashboard/expercise"}

// ]
// patient Route
export const patientGuidance = "/patientDashboard/guidance";
export const DoctorDasboard = "/doctorDashboard/overview";
export const DoctorLogin = "/";
export const exercisedetail = "/doctorDashboard/exercise";
export const Patientassignexersise = "/doctorDashboard/assignexercise";
export const PatientDetailoverview = "/doctorDashboard/overview";
export const seetingExerciseRoute = "/doctorDashboard/exercise/setting";
export const DoctorDashboardPatientlistRoute = "/doctorDashboard/patientlist";
export const DoctorDashboard = [
  { label: "Dashboard", icon: IconGauge, route: "/doctorDashboard/overview" },
  { label: "Patients", icon: IconGauge, route: "/doctorDashboard/patientlist" },

  // {
  //   label: "Exercise",
  //   icon: IconNotes,
  //   initiallyOpened: true,
  //   route: "/doctorDashboard/exercise",
  //   links: [
  //     {
  //       label: "Setting Exercise",
  //       link: "/",
  //       route: "/doctorDashboard/exercise/setting",
  //     },
  //     // { label: "Global Exercise", link: "/",route:"/doctorDashboard/exercise/globalexercise" },
  //     // { label: "Assign Exericse Patients", link: "/",route:"/doctorDashboard/exercise/assignexercise" },
  //   ],
  // },
];
export const DoctorDashboardmobileview = [
  { label: "Dashboard", icon: IconGauge, route: "/doctorDashboard/overview" },
  { label: "Patients", icon: IconGauge, route: "/doctorDashboard/patientlist" },
  { label: "Logout", icon: IconLogout, route: "/" },

  // {
  //   label: "Exercise",
  //   icon: IconNotes,
  //   initiallyOpened: true,
  //   route: "/doctorDashboard/exercise",
  //   links: [
  //     {
  //       label: "Setting Exercise",
  //       link: "/",
  //       route: "/doctorDashboard/exercise/setting",
  //     },
  //     // { label: "Global Exercise", link: "/",route:"/doctorDashboard/exercise/globalexercise" },
  //     // { label: "Assign Exericse Patients", link: "/",route:"/doctorDashboard/exercise/assignexercise" },
  //   ],
  // },
];


//coordinator routes
export const CoordinatorDashboard = "/CoordinatorDashboard/overview";
export const CoordinatorLogin = "/coordinatorlogin";
export const PatientDetailoverviewCoordinator =
  "/CoordinatorDashboard/overview";
export const coordinatorExerciseDetail = "/CoordinatorDashboard/exercise";
export const routeCoordinatorDoctorTab = "/CoordinatorDashboard/doctorlist";
export const PatientAssignExersiseCoordinator =
  "/CoordinatorDashboard/assignexercise";

export const coPhysioLanding = "/CoordinatorDashboard/physio-list";

export const ClinicalCoordinatorDashboard = [
  {
    label: "Doctor",
    icon: IconHealthRecognition,
    initiallyOpened: true,
    // route: "/CoordinatorDashboard/doctorlist",
    links: [
      {
        label: "Patient",
        link: "/",
        route: routeCoordinatorDoctorTab,
      },
      {
        label: "Physiotherapist",
        link: "/",
        route: coPhysioLanding,
      },
    ],
  },
  // {
  //   label: "Dashboard",
  //   icon: IconGauge,
  //   route: CoordinatorDashboard,
  // },
  // {
  //   label: "Patients",
  //   icon: IconGauge,
  //   route: "/CoordinatorDashboard/patientlist",
  // },

  {
    label: "Exercise",
    icon: IconNotes,
    initiallyOpened: true,
    route: coordinatorExerciseDetail,
    links: [
      {
        label: "Setting Exercise",
        link: "/",
        route: coordinatorExerciseDetail + "/setting",
      },
    ],
  },
];

//physiotherapist routes
export const PhysiotherapistLogin = "/physiotherapist-login";
export const PhysioLanding = "/physiotherapist-dashboard";
export const routePhysiotherapistDoctorTab = "/doctor-list";
export const routePhysioPatientOverview =
  routePhysiotherapistDoctorTab + "/:doctorId" + "/patient/:patientId";

export const physioGuidanceDefault =
  PhysioLanding + routePhysioPatientOverview + "/guidance";

export const routePhysioFeaturePatientPain = physioGuidanceDefault + "/pain";

export const routePhysioFeaturePatientRangeOfMotion =
  physioGuidanceDefault + "/range-of-motion";
export const routePhysioFeaturePatientImageAi =
  physioGuidanceDefault + "/image-ai";

export const PhysiotherapistSideNav = [
  {
    label: "Doctor",
    icon: IconHealthRecognition,
    route: PhysioLanding + routePhysiotherapistDoctorTab,
  },
];

export const AdminList = "/admindashboard/admins";
export const AdminLogin = "/adminlogin";
export const AdminDashboard = [
  {
    label: "Users",
    icon: IconUsers,
    initiallyOpened: true,
    route: "/admindashboard/providers",
    links: [
      {
        label: "Providers",
        icon: IconGauge,
        route: "/admindashboard/providers",
      },
      {
        label: "Admins",
        icon: IconGauge,
        route: "/admindashboard/admins",
      },
      {
        label: "Coordinators",
        link: "/",
        route: "/admindashboard/coordinators",
      },
      {
        label: "Physiotherapist",
        link: "/",
        route: "/admindashboard/physiotherapist",
      },
    ],
  },

  {
    label: "Exercise",
    icon: IconHealthRecognition,
    route: "/admindashboard/physiotherapist",
  },
];
