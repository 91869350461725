import { Box, Space, Table } from "@mantine/core";
import BreadCamp from "../../../resuable/BreadCamps";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { useContext, useEffect } from "react";
import { coPhysioLanding } from "../../../constants/constants";
import { useParams } from "react-router-dom";

const CoViewPhysio = () => {
  const { setBreadCampList } = useContext(BreadCampContext);
  const { params } = useParams();
  console.log(params);
  console.log("Checkin");

  const tableDataHeader = ["Sr No.", "Physiotherapist Name", "Email"];
  const elementsPhysio = [
    {
      id: 0,
      name: "Kathleen Rosales",
      email: "kathleenrosales@aeora.com",
    },
    {
      id: 1,
      name: "Burton Kent",
      email: "burtonkent@aeora.com",
    },
    {
      id: 2,
      name: "Chris Warner",
      email: "chriswarner@aeora.com",
    },
    {
      id: 3,
      name: "Gomez Rodgers",
      email: "gomezrodgers@aeora.com",
    },
    {
      id: 4,
      name: "Mercer Huber",
      email: "mercerhuber@aeora.com",
    },
    {
      id: 5,
      name: "Bertha Bass",
      email: "berthabass@aeora.com",
    },
    {
      id: 6,
      name: "Norma Harper",
      email: "normaharper@aeora.com",
    },
    {
      id: 7,
      name: "Cleo Cross",
      email: "cleocross@aeora.com",
    },
    {
      id: 8,
      name: "Lupe Jensen",
      email: "lupejensen@aeora.com",
    },
    {
      id: 9,
      name: "Mays Langley",
      email: "mayslangley@aeora.com",
    },
    {
      id: 10,
      name: "House Burton",
      email: "houseburton@aeora.com",
    },
    {
      id: 11,
      name: "Webster Foreman",
      email: "websterforeman@aeora.com",
    },
    {
      id: 12,
      name: "Daniel Zimmerman",
      email: "danielzimmerman@aeora.com",
    },
    {
      id: 13,
      name: "Parrish Ray",
      email: "parrishray@aeora.com",
    },
    {
      id: 14,
      name: "Dennis Hansen",
      email: "dennishansen@aeora.com",
    },
    {
      id: 15,
      name: "Gaines Curtis",
      email: "gainescurtis@aeora.com",
    },
    {
      id: 16,
      name: "Teresa Gilliam",
      email: "teresagilliam@aeora.com",
    },
    {
      id: 17,
      name: "Berry Hood",
      email: "berryhood@aeora.com",
    },
    {
      id: 18,
      name: "Darla Sweet",
      email: "darlasweet@aeora.com",
    },
    {
      id: 19,
      name: "Rosemarie Ray",
      email: "rosemarieray@aeora.com",
    },
    {
      id: 20,
      name: "Humphrey Green",
      email: "humphreygreen@aeora.com",
    },
    {
      id: 21,
      name: "Nielsen Parks",
      email: "nielsenparks@aeora.com",
    },
    {
      id: 22,
      name: "Dina Maxwell",
      email: "dinamaxwell@aeora.com",
    },
    {
      id: 23,
      name: "Robinson Small",
      email: "robinsonsmall@aeora.com",
    },
  ];

  useEffect(() => {
    setBreadCampList([
      {
        title: "Doctor",
        route: coPhysioLanding,
      },
      {
        title: "Physiotherapist List",
      },
    ]);
  }, []);
  const rows = elementsPhysio.map((element, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{idx + 1}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.email}</Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Space h={"md"} />
      <BreadCamp />
      <Space h={"md"} />
      <Box
        style={{
          height: "70vh",
          overflow: "auto",
          margin: "20px",
        }}
      >
        <Table
          style={{ width: "100%" }}
          striped
          withColumnBorders
          stripedColor={"#00526A"}
          stickyHeader
          stickyHeaderOffset={-1}
        >
          <Table.Thead style={{ background: "black" }}>
            <Table.Tr>
              {tableDataHeader.map((items) => {
                return (
                  <Table.Th key={items} c={"white"}>
                    {items}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody style={{ overflowY: "auto" }}>{rows}</Table.Tbody>
        </Table>
      </Box>
    </>
  );
};

export default CoViewPhysio;
