import { useEffect, useState,useContext } from "react";
import {
    Table,
    Group,
    Grid,
    Button, Tooltip
} from "@mantine/core";
import {
    IconSelector,
    IconCirclePlus,
    IconChevronDown,
    IconChevronUp,
    IconSearch,
    IconPencil,
    IconTrash,
    IconEye,IconHttpDelete,
} from "@tabler/icons-react";
import { ActionIcon } from "@mantine/core";
import ProviderTable from "./ProviderTable";
import BreadCamp from "../../../resuable/BreadCamps";
import { Link, useNavigate } from "react-router-dom";
import { Patientassignexersise } from '../../../constants/constants';
import {navbarContext} from '../Context/AdminDashboard';
import {BreadCampContext} from '../../../context/BreadCampContext';
import providerService from "../../../services/providerService";
import AddAdmin from "../../AddAdmin";

function ProviderList(props) {
    const { setBreadCampList} = useContext(BreadCampContext);
    const {setNavActive}=useContext(navbarContext)
    const [sortedData, setSortedData] = useState([]);
    const [list, setList] = useState([]);
    const [rows, setRows] = useState([]);
    const [patientListshow, setPatientListshow] = useState(true);
    const [createpatientshow, setCreatepatientshow] = useState(false);
    const navigate = useNavigate();
    const [breadcampitems, setBreadcampitems] = useState([
        { title: "Admin List", href: "#" },
    ]);
    const handleEdit = (e, provider) => {
        try {
          navigate("/admindashboard/providers/edit", {
            state: {
              provider,
            },
            replace: true,
          });
        } catch (err) {
          console.log(err);
        }
      };
      const addNewConfig = () => {
        navigate("/admindashboard/providers/add", { replace: true });
      };
      const disableUser = async (e, provider) => {
        try {
          const disableResponse = await providerService.updateProvider(
            provider._id,
            { inActive: true }
          );
        } catch (e) {
          console.log(e);
        }
      };

    const FetchProviders = async () => {
        const apiacll = await providerService.getProviders();

        if (apiacll.statusCode === 200) {
            console.log(apiacll);
            const setdata = apiacll.data;
            const filterkeydata = [];
            setdata.forEach((element) => {
                filterkeydata.push({
                    name: element.name,
                    email: element.email,
                    mobile: element.mobile,
                    id: element._id
                });
            });
            console.log(filterkeydata);
            setList(filterkeydata);
            setSortedData(filterkeydata);
            const rowdata = filterkeydata.map((row, index) => (
                <Table.Tr key={index}>
                    <Table.Td>{index + 1}</Table.Td>
                    <Table.Td> {row.name}</Table.Td>
                    <Table.Td>{row.email}</Table.Td>
                    <Table.Td>{row.mobile}</Table.Td>
                    <Table.Td>
                        <Group align="">
                            <Tooltip label='view Patient Detail'><IconPencil size={18} color="#90EE90" /></Tooltip>
                        </Group>
                    </Table.Td>
                </Table.Tr>
            ));
            setRows(rowdata);
        }
    };
    useEffect(() => {
        setBreadCampList([
            { title: "Providers List", href: "#" },
        ])
        setNavActive('Patients')
        FetchProviders();
    }, []);

    useEffect(() => {
        const rowdata = sortedData.map((row, index) => (
            <Table.Tr key={index}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>{row.name}</Table.Td>
                <Table.Td>{row.email}</Table.Td>
                <Table.Td>{row.mobile}</Table.Td>
                <Table.Td>
                    <Group align="">
                        {/* <Tooltip label='view Patient Detail'><IconTrash size={18} color="red" /></Tooltip> */}
                        <Tooltip label='Edit Provider Detail'><IconPencil              onClick={(e) => handleEdit(e, row)}
 size={18} color="#90EE90" /></Tooltip>
                       

                    </Group>
                </Table.Td>
            </Table.Tr>
        ));
        setRows(rowdata);
    }, [sortedData]);
    const ClickCreateProvider = () => {
        navigate('/admindashboard/providers/add')
        // setBreadcampitems([
        //   { title: "DashBoard", href: "#" },
        //   { title: "Patient List", href: "#" },
        //   { title: "Create Patient", href: "#" },
        // ]);
        // setCreatepatientshow(true);
        // setPatientListshow(false);
    };
    return (
        <>
       
        <Grid>
            <Grid.Col>
                <Group style={{ justifyContent: "space-between" }}>
                    <BreadCamp data={breadcampitems} />
                    {createpatientshow === true ? (
                        ""
                    ) : (
                        <Button
                            onClick={ClickCreateProvider}
                            fw={600}
                            leftSection={<IconCirclePlus size={20} />}
                            variant="default"
                        >
                            Create Provider
                        </Button>
                    )}
                </Group>
            </Grid.Col>
            <Grid.Col>
                {patientListshow === true ? (
                    <ProviderTable
                        list={list}
                        rows={rows}
                        sortedData={sortedData}
                        setSortedData={setSortedData}
                    />
                ) : (
                    ""
                )}
                {createpatientshow === true ? <AddAdmin /> : ""}
            </Grid.Col>
        </Grid>
        </>
    );
}
export default ProviderList;
