import { createSlice } from "@reduxjs/toolkit";

//set up rtk to monitor range of motion
const angleDetectedSlice = createSlice({
  name: "angleDetected",
  initialState: {
    currentROM: null, 
  },
  reducers: {
    updateAngle: (state, action) => {
      const { currentROM } = action.payload;
      if (currentROM !== null) {
          state.currentROM = currentROM;
      }
    },
  },
});

export const { updateAngle } = angleDetectedSlice.actions;
export default angleDetectedSlice.reducer;