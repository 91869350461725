import React from 'react';

import { useEffect, useState } from 'react';
import {
    Table,Skeleton,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys, Grid, Button
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconPencil, IconTrash, IconEye } from '@tabler/icons-react';
import classes from '../../../../../styles/TableSort.module.css';

function Th({ children, reversed, sorted, onSort }) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item["name"].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}
const ExerciseTable=({list,rows,sortedData,setSortedData})=>{
    const [search, setSearch] = useState('');
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(list, { sortBy: field, reversed, search }));
    };

    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(list, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    return (
        <ScrollArea>
        <TextInput
            placeholder="Search by any field"
            mb="md"
            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            value={search}
            onChange={handleSearchChange}
            style={{ width: '30%' }}
        />
        <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
            <Table.Tbody>
                <Table.Tr>
                    <Th
                    // sorted={sortBy === 'name'}
                    // reversed={reverseSortDirection}
                    // onSort={() => setSorting('name')}
                    >
                        Sr. No
                    </Th>
                    <Th
                        sorted={sortBy === 'name'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('name')}
                    >
                        Exercise's Name
                    </Th>
                    <Th
                        // sorted={sortBy === 'name'}
                        // reversed={reverseSortDirection}
                        // onSort={() => setSorting('name')}
                    >
                        Create Date
                    </Th>
                    <Th
                        // sorted={sortBy === 'mobile'}
                        // reversed={reverseSortDirection}
                        // onSort={() => setSorting('mobile')}
                    >
                     Status
                    </Th>
                    <Th
                    //   sorted={sortBy === 'company'}
                    //   reversed={reverseSortDirection}
                    //   onSort={() => setSorting('company')}
                    >
                        Global Status
                    </Th>
                    <Th
                    //   sorted={sortBy === 'company'}
                    //   reversed={reverseSortDirection}
                    //   onSort={() => setSorting('company')}
                    >
                        Action
                    </Th>
                </Table.Tr>
            </Table.Tbody>
            <Table.Tbody>
                {sortedData.length > 0 ? (
                    rows
                ) : (
                    <Table.Tr>
                        <Table.Td colSpan={sortedData.length}>            
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        <Skeleton height={8} mt={6} width="650%" radius="xl" />
                        </Table.Td>
                    </Table.Tr>
                )}
            </Table.Tbody>
        </Table>
    </ScrollArea>
    )
}

export default ExerciseTable;