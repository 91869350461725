import { AppShell, Burger, Group, Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Navbar from '../nav/Navbar';
import Header from '../header/Header';
import Router from '../../../routes';
import { Outlet } from "react-router-dom";

export default function DashboardLayout() {
  const [openedState, openedActions] = useDisclosure();
  const toggleMobile = openedActions.toggle;
  const [desktopOpenedState, desktopOpenedActions] = useDisclosure(true);
  const toggleDesktop = desktopOpenedActions.toggle;

  return (
    <div className="App" style={{ marginTop: '20px' }}>
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !openedState, desktop: !desktopOpenedState }, }}
        padding="md"

      >
        <Header toggleMobile={toggleMobile} mobileOpened={openedState} desktopOpened={desktopOpenedState} toggleDesktop={toggleDesktop} />
        <Navbar   />
        <AppShell.Main style={{ backgroundColor: '#042A35' }} >
          <Outlet />

        </AppShell.Main>

      </AppShell>
    </div>
  );
}
