import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";
import { Container, TextInput,Skeleton, Button, Table, Card } from '@mantine/core';
import { Group, Space,Stack , Title } from '@mantine/core';
import { IconSearch, IconEdit, IconTrash } from '@tabler/icons-react'; // Importing icons for edit and delete
import { Pagination } from '@mantine/core';
import adminService from "../services/adminService";
import loginService from "../services/loginService"
import { ActionIcon } from '@mantine/core';
import { IconPencil , IconHttpDelete  } from '@tabler/icons-react';
import coordinatorService from "../services/CoordinatorService";
import { useDebouncedState } from '@mantine/hooks';
import { filter } from "lodash";
const CoordinatorPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] =  useDebouncedState('', 150);

  const filterData = (query) => {
    console.log("q is ",query);
    if (query === "") {
      // If the query is empty, display the complete table with all data
      setConfig(configData);
    } else {
      const filteredData = filter(configData, (p) => {
        // Filter condition based on the search query
        // Modify this condition as needed based on your requirements
        return p.name.includes(query) || p.mobile.includes(query);
        
      });
      console.log("filter data is" , filteredData);
      setConfig(filteredData);
    }

  };
 
  const addNewConfig = () => {
    navigate("/coordinator/add", { replace: true });
  };
  const [configData, setConfig] = useState([]);

  useEffect(() => {
    getCoordinator();
  }, []);

  const getCoordinator = async () => {
    try {
      let configData = await coordinatorService.getUsers();
      console.log(" getAdmins configData", configData.data);
      setConfig(configData.data);
    } catch (err) {
      console.log(err);
      if (err?.response?.status && err?.response?.status === 401) {
        const path = loginService.checkRoute();
        navigate(path, { replace: true });
      }
    }
  };
  const handleEdit = (e, provider) => {
    try {
      console.log("id is a " , provider);
      navigate("/coordinator/edit", {
        state: {
         provider,
        },
        replace: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchChange = (event) => {
    console.log("query is  new repo",event.target.value);
    setSearchQuery(event.target.value);
    filterData(event.target.value)
  };
  const rows = configData.map((provider, index) => (
    <Table.Tr key={index+1} >
      <Table.Td   c={'black'} align="left" >{index+1}</Table.Td>
      <Table.Td  c={'black'}  >{provider.name}</Table.Td>
      <Table.Td c={'black'}>{provider.email}</Table.Td>
      <Table.Td> 
      <Group>    
      <ActionIcon id={provider.id} onClick={(e) => handleEdit(e, provider)} variant="filled" aria-label="Settings">
      <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
    </ActionIcon>
    <ActionIcon  style={{backgroundColor: "pink"}} variant="filled" aria-label="Settings" id="1">
      <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
    </ActionIcon>
    <ActionIcon  style={{backgroundColor: "red"}} variant="filled" aria-label="Settings" id="1">
      <IconHttpDelete style={{ width: '70%', height: '70%' }} stroke={1.5} />
    </ActionIcon>
    </Group>
      
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
     <Helmet>
        <title> Coordinators | Truss Health </title>
      </Helmet>
      <Container>
        <Group justify="flex-end" gap="xl">
          <Title order={3}> Clinical Coordinators</Title>
          <Space w="xl" />
          <Button variant="default"   onClick={addNewConfig} >Add Clinical Coordinator</Button>
        </Group>
      </Container>

      <Group>
      <TextInput
        placeholder="Search...."
      value={searchQuery}
      style={{ margin: '40px 10px' }}
      leftSection={<IconSearch size={16} />}
      onChange={handleSearchChange}
    />
      </Group>
      <Stack>
      <Card style={{ maxWidth: '2090px' }}> {/* Set maxWidth to null */}
    
        <Table fullWidth>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Sr. No</Table.Th>
              <Table.Th>Clinical
 Coordinator's Name</Table.Th>
              <Table.Th>Email Address</Table.Th>
              <Table.Th>Actions</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}
          {configData.length === 0 && (
    <tr>
      <td colSpan="4">
        <Skeleton height={50} circle mb="xl" />
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
      </td>
    </tr>
  )}</Table.Tbody>
        </Table>
      </Card>
      
      <Pagination total={5} />
      </Stack>
    </>
  );
};

export default CoordinatorPage;
