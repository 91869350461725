import {useState} from 'react';
import { Container, Grid } from "@mantine/core";
import { Outlet, useNavigate } from "react-router";
import DoctorHeader from "./HeaderModule/DoctorHeader";
import NavBar from "./NavbarLinksGroup/NavBar";
import { navbarContext } from './context/DoctorDashboard';
function DoctorDashboard() {
    const [navActive, setNavActive] = useState('Raju')
    return (
        <navbarContext.Provider value={{navActive, setNavActive}}>
            <Container
                fluid={true}
                px={0}
            >
                <Grid style={{ minHeight: "10vh" }} px={0}>
                    <Grid.Col px={0} pt={0} pb={0} style={{ height: "10vh" }}>
                        <div
                            style={{
                                height: "10vh",
                                position: "fixed",
                                width: "100%",
                                zIndex: 2,
                            }}
                        >
                            <DoctorHeader />
                        </div>
                    </Grid.Col>
                </Grid>
                <Grid
                >
                    <Grid.Col
                    visibleFrom='md'
                        span={{ base: 2, xs: 0, sm: 0, lg: 2, xl: 2, md: 2 }}
                        style={{ borderRight: "1px solid #B3F9FF", minHeight: "90vh" }}
                    >
                        <NavBar  />
                    </Grid.Col>
                    <Grid.Col
                        span={{ base: 12, xs: 12, sm: 12, lg: 10, xl: 10, md: 10 }}
                        style={{ height: "100%" }}
                    >
                        <Outlet />
                    </Grid.Col>
                </Grid>
            </Container>
        </navbarContext.Provider>

    );
}

export default DoctorDashboard;
