import React, { useState, useEffect,useContext } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import TrussLogo from "./truss-logo/TrussLogo"
import patientService from '../../services/patientService'
import Question from "./Components/Questions";
import UserInput from "./Components/UserInput";
import moment from "moment";
import ArrowRightIcon from "./arrow-right.svg";
import { HeaderMenu } from '../../components/Header/HeaderMenu';
import {BreadCampContext} from '../../context/BreadCampContext';

const PatientForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setBreadCampList} = useContext(BreadCampContext);
  const [questionNumber, setQuestionNumber] = useState(0);
  const myProps = location.state;
  const [questions, setQuestionList] = useState([])
  const [userAnswers, setUserAnswers] = useState([]);


  useEffect(() => {
    setBreadCampList([
      { title: "Patient Health Question" },
  ])
    const fetchQuestions = async () => {
      try {
        const response = await patientService.getQuestionList();
        setQuestionList(response.data[0].assignquestion);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);
  function nextQuestion() {
    if (questionNumber === questions.length - 1) {
      submitForm();
      return;
    }

    setQuestionNumber(Math.min(questionNumber + 1, 3));
  }

  function prevQuestion() {
    setQuestionNumber(Math.max(questionNumber - 1, 0));
  }

  async function submitForm() {
    try {
      console.log("SUBMITTED", userAnswers);
      if (userAnswers.length === 4) {
        const data = await patientService.postPatientQuestionAnswer(
          userAnswers
        );
        console.log(data)
        if (data.status == 201) {
          // show notification message Create Success and check surgery date condition
          const dateOfSurgery = userAnswers[0]?.answer;
          const currentdate = moment()
          const surgeryDate = moment(dateOfSurgery);
          const day = surgeryDate.diff(currentdate, "days");
          const condition =
            day > 0 ? "preSurgery" : "postSurgery";
          if (day === 0) {
            // same day surgery condition
            navigate("/guidance", {
              state: {
                condition
              },
              replace: true,
            });
          }
          else if (day > 0) {
            // post surgery condition
            // navigate("/guidance", {
            //   state: {
            //     condition
            //   },
            //   replace: true,
            // });
            navigate('/patientDashboard/ImageAI')
          }
          else if (day < 0) {
            // pre surgery condition
           // navigate("/patientDashboard/pain-data");
            navigate('/patientDashboard/ImageAI')
          }
        }
        //   const dateOfSurgery = userAnswers[0]?.answer;
        //   const currentDate = moment();
        //   const surgeryDate = moment(dateOfSurgery);
        //   const day = surgeryDate.diff(currentDate, "days");
        // const condition =
        //   day > 0 ? "preSurgery" : "postSurgery";
        // navigate("/guidance", {
        //   state: {
        //     condition
        //   },
        //   replace: true,
        // });
      }
      else {
        // enter all question first error show
      }

    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="truss-form-container">
      {/* <HeaderMenu /> */}
      <div className="question-div">
        <div
          className="question-wrapper"
          style={{
            transform: `
            translateX(calc(${questionNumber * -80}px + ${-100 * questionNumber
              }%))`,
          }}
        >
          {Object.keys(questions).map((questionKey, i) => {
            const question = questions[questionKey];
            const { ques, type } = question;
            return (
              <div key={ques}>
                <Question number={i + 1} question={ques} />
                <UserInput
                  type={type}
                  number={i}
                  setUserAnswers={setUserAnswers}
                  questionData={question}
                  options={question.options}
                />
              </div>
            );
          })}

        </div>
      </div>
      <div className="nav-btns">
        <button
          className={questionNumber ? "" : "disable"}
          type="button"
          onClick={prevQuestion}
        >
          <img className='icon-form' src={ArrowRightIcon} alt="Icon" />
        </button>

        <button type="button" onClick={nextQuestion}>
          {questionNumber == questions.length - 1 ? (
            "Submit"
          ) : (
            <img src={ArrowRightIcon} alt="Icon" />
          )}
        </button>
      </div>
    </div>
  )
}

export default PatientForm