import { Box, Table,Skeleton, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import propTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routeCoordinatorDoctorTab } from "../../../constants/constants";
import { BreadCampContext } from "../../../context/BreadCampContext";
import BreadCamp from "../../../resuable/BreadCamps";
import coordinatorUsersService from "../../../services/CoordinatorUsersService";
import PatientListOverview from "./PatientListOverview";

const TableList = ({ tableHeader }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // New loading state

  const [viewPatients, setViewPatients] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const { setBreadCampList } = useContext(BreadCampContext);
  useEffect(() => {
    setBreadCampList([
      {
        title: "Doctor",
        route: routeCoordinatorDoctorTab,
      },
    ]);
  }, [setBreadCampList]);

  const getDoctorList = async () => {
    setIsLoading(true)
    const data = await coordinatorUsersService.getDoctorList();
    setDoctorList(data.data);
    setIsLoading(false)
  };

  useEffect(() => {
    getDoctorList();
  }, []);

  const handleClick = (e) => {
    navigate(`${e.id}`);
    setViewPatients(true);
  };

  const rows = doctorList.map((element, idx) => (
    <Table.Tr key={idx}>
      <Table.Td>{idx + 1}</Table.Td>
      <Table.Td>{element.name}</Table.Td>
      <Table.Td>{element.email}</Table.Td>
      <Table.Td>
        <Tooltip label="View All Patient Detail">
          <IconEye
            onClick={() => {
              handleClick(element);
            }}
            size={18}
            color="#90EE90"
          />
        </Tooltip>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      {viewPatients === false ? (
        <>
          <BreadCamp />
          <Box
            style={{
              height: "70vh",
              overflow: "auto",
              margin: "20px",
            }}
          >
            <Table
              style={{ width: "100%" }}
              striped
              withColumnBorders
              stripedColor={"#00526A"}
              stickyHeader
              stickyHeaderOffset={-1}
            >
              <Table.Thead style={{ background: "black" }}>
                <Table.Tr>
                  {tableHeader.map((items) => {
                    return (
                      <Table.Th c={"white"} key={items}>
                        {items}
                      </Table.Th>
                    );
                  })}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody style={{ overflowY: "auto" }}> {isLoading ? (
                 <>
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                    <Skeleton height={8} mt={6} width="470%" radius="xl" />
                 </>
                ) : (
                 rows
                )}</Table.Tbody>
            </Table>
          </Box>
        </>
      ) : (
        <>
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          </>
      )}
    </>
  );
};

export default TableList;
TableList.propTypes = {
  tableHeader: propTypes.array.isRequired,
};
