import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
 Container,
 Input,
 Skeleton,
 Button,
 SimpleGrid,
 Grid, Select, MultiSelect,
 Text, Stack,
 Badge, Title,
 AspectRatio,
 TextInput,
 Table,
 Paper,
 Card,
 Modal,
 Box,
 Center,
 Image,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconX, IconCheck } from "@tabler/icons-react";
import { Notification, rem } from "@mantine/core";
import patientService from "../../services/patientService";

const AddexercisePatient = () => {
 const [patients, setPatients] = useState([]);
 const [exercises, setExercises] = useState([]);
 const [loading, setLoading] = useState(false);
 const [disable, setDisable] = useState(false);
 const navigate = useNavigate();
 const [notificationContent, setNotificationContent] = useState({}); // State to manage notification content
 const [notificationOpen, setNotificationOpen] = useState(false); 
 const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
 const getPatientData = async () => {
    try {
      const response = await patientService.getPatients();
      console.log("res", response.data);
      const responseData = response.data;
      setPatients(responseData);
    } catch (error) {
      console.log(error);
    }
 };

 const form = useForm({
    initialValues: { patientId: "", exerciseId: []  }, // Added exerciseId to initialValues
    validate: {
      patientId: (value) =>
        /^\d{10}$/.test(value) ? null : "Phone number must be 10 digits",
    },
 });

 const getExerciseData = async () => {
 try {
    const response = await patientService.getAllExercise();
    const responseData = response.data;
     setExercises(responseData);
     console.log("exercise data " , responseData);
   
 } catch (error) {
    console.log(error);
 }
};

 useEffect(() => {
    getPatientData();
    getExerciseData();
 }, []);

 const handleChangeSelectExercise = (values) => {
  console.log("Selected exercise ID:", values);
  const selectedExerciseObjs = values.map(_id => exercises.find(ex => ex._id === _id));
  // Filter out any undefined values in case some IDs don't match any exercises
  if(selectedExerciseObjs){
  // const validSelectedExerciseObjs = selectedExerciseObjs.filter(ex => ex !== undefined);
  console.log("Selected exercises:", selectedExerciseObjs);
  // const updatedExerciseIds = [...form.values]
  form.setFieldValue("exerciseId", values);
  // console.log("form is", form.values);

  } else {
    console.error("Selected exercise not found");
  }
};

 const handleChangeSelect = (value) => {
    console.log("value", value);
    const selectedPatientObj = patients.find(pt => pt._id === value);
    if (selectedPatientObj) {
      // console.log("selected obj is", selectedPatientObj);
      form.setFieldValue('patientId', selectedPatientObj._id);
      // console.log("form is", form.values);

    }
 };

 const handleSubmit = async(event) => {
  event.preventDefault();

  try{
      if(form.values.patientId && form.values.exerciseId)
      {
        setDisable(true)
        setLoading(true)
        const response = await patientService.createPatientExercise(form.values.patientId , form.values.exerciseId);
        if(response)
        {
          setDisable(false)
          setLoading(false)
          if(response.data.statusCode==200)
         {
        setNotificationContent({ icon: checkIcon, color: "teal", title: "Success!", message: "You have successfully Updated Doctor's Info" });
        setNotificationOpen(true);
        setTimeout(() => {
          navigate("/patient/list" , { replace: true} )
        },900)}
        if(response.status == 400)
        {
          setNotificationContent({ icon: checkIcon, color: "red", title: "Error!", message: response.message });
          setNotificationOpen(true);
        }
      }
      }
  }
  catch (error) {
            
    setNotificationContent({ icon: checkIcon, color: "teal", title: "Success!", message: error });
    setNotificationOpen(true);
    console.log(error);
  }
  
  
 }
 
 return (
  <Box>
    <Title order={3}>Select Patient From List to Assign All Exercise</Title>
    <Paper shadow="xs" p="xl">
      <Grid justify="center" align="center">
        <Grid.Col span={4}>
          {patients.length > 0 && (
            <Select
              data={patients.map(pt => ({
                value: pt._id,
                label: pt.name
              }))}
              value={form.values.patientId}
              onChange={handleChangeSelect}
              placeholder="Select a patient"
              label="Select Patient"
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          {exercises.length > 0 && (
            <MultiSelect
              data={exercises.map(pt => ({
                value: pt._id,
                label: pt.exerciseName
              }))}
              value={form.values.exerciseId}
              onChange={handleChangeSelectExercise}
              placeholder="Select an exercise"
              label="Select Exercise" searchable
            />
          )}
        </Grid.Col>
        <Grid.Col span={4}>
          <Button  onClick={handleSubmit} style={{ marginTop: "22px" }}>Submit</Button>
        </Grid.Col>
      </Grid>
    </Paper>
  </Box>
);

};

export default AddexercisePatient;
