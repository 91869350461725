import { Box, Group, Space, Table,Skeleton, Tooltip } from "@mantine/core";
import { IconCirclePlus, IconEye } from "@tabler/icons-react";
import propTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CoViewPhysio from "./CoViewPhysio";
import BreadCamp from "../../../resuable/BreadCamps";
import { BreadCampContext } from "../../../context/BreadCampContext";
import { coPhysioLanding } from "../../../constants/constants";
import PhysioService from '../../../services/physioService';
const CoordinatorPhysioDoc = ({ tableHead,rows,elementsDoctor }) => {
  const [viewPhysio, setViewPhysio] = useState(false);
  const navigate = useNavigate();
  const { setBreadCampList } = useContext(BreadCampContext);
  // const [elementsDoctor,setElementsDoctor]=useState([]);
  // const [rows,setRows]=useState(null)
  // const elementsDoctors = [
  //   {
  //     id: 0,
  //     name: "Kathleen Rosales",
  //     email: "kathleenrosales@aeora.com",
  //   },
  //   {
  //     id: 1,
  //     name: "Burton Kent",
  //     email: "burtonkent@aeora.com",
  //   },
  //   {
  //     id: 2,
  //     name: "Chris Warner",
  //     email: "chriswarner@aeora.com",
  //   },
  //   {
  //     id: 3,
  //     name: "Gomez Rodgers",
  //     email: "gomezrodgers@aeora.com",
  //   },
  //   {
  //     id: 4,
  //     name: "Mercer Huber",
  //     email: "mercerhuber@aeora.com",
  //   },
  //   {
  //     id: 5,
  //     name: "Bertha Bass",
  //     email: "berthabass@aeora.com",
  //   },
  //   {
  //     id: 6,
  //     name: "Norma Harper",
  //     email: "normaharper@aeora.com",
  //   },
  //   {
  //     id: 7,
  //     name: "Cleo Cross",
  //     email: "cleocross@aeora.com",
  //   },
  //   {
  //     id: 8,
  //     name: "Lupe Jensen",
  //     email: "lupejensen@aeora.com",
  //   },
  //   {
  //     id: 9,
  //     name: "Mays Langley",
  //     email: "mayslangley@aeora.com",
  //   },
  //   {
  //     id: 10,
  //     name: "House Burton",
  //     email: "houseburton@aeora.com",
  //   },
  //   {
  //     id: 11,
  //     name: "Webster Foreman",
  //     email: "websterforeman@aeora.com",
  //   },
  //   {
  //     id: 12,
  //     name: "Daniel Zimmerman",
  //     email: "danielzimmerman@aeora.com",
  //   },
  //   {
  //     id: 13,
  //     name: "Parrish Ray",
  //     email: "parrishray@aeora.com",
  //   },
  //   {
  //     id: 14,
  //     name: "Dennis Hansen",
  //     email: "dennishansen@aeora.com",
  //   },
  //   {
  //     id: 15,
  //     name: "Gaines Curtis",
  //     email: "gainescurtis@aeora.com",
  //   },
  //   {
  //     id: 16,
  //     name: "Teresa Gilliam",
  //     email: "teresagilliam@aeora.com",
  //   },
  //   {
  //     id: 17,
  //     name: "Berry Hood",
  //     email: "berryhood@aeora.com",
  //   },
  //   {
  //     id: 18,
  //     name: "Darla Sweet",
  //     email: "darlasweet@aeora.com",
  //   },
  //   {
  //     id: 19,
  //     name: "Rosemarie Ray",
  //     email: "rosemarieray@aeora.com",
  //   },
  //   {
  //     id: 20,
  //     name: "Humphrey Green",
  //     email: "humphreygreen@aeora.com",
  //   },
  //   {
  //     id: 21,
  //     name: "Nielsen Parks",
  //     email: "nielsenparks@aeora.com",
  //   },
  //   {
  //     id: 22,
  //     name: "Dina Maxwell",
  //     email: "dinamaxwell@aeora.com",
  //   },
  //   {
  //     id: 23,
  //     name: "Robinson Small",
  //     email: "robinsonsmall@aeora.com",
  //   },
  // ];
  // const FetchPhysioList=async()=>{
  //   const apicall= await PhysioService.getPhysioList()
  //   console.log(apicall)
  //   if(apicall.statusCode===200){
     
  //     const rowss = elementsDoctor.map((element, idx) => {
  //       return (
  //         <Table.Tr key={element.email}>
  //           <Table.Td>{idx + 1}</Table.Td>
  //           <Table.Td> {element.name}</Table.Td>
  //           <Table.Td>{element.email}</Table.Td>
  //           <Table.Td style={{ margin: "20px" }}>
  //             <Group>
  //               <Tooltip label="Assign Physio">
  //                 <IconCirclePlus
  //                   onClick={() => handleClick(element)}
  //                   size={15}
  //                   color="#90EE90"
  //                 />
  //               </Tooltip>
    
  //               <Tooltip label="View Physio">
  //                 <IconEye
  //                   size={15}
  //                   color="#90EE90"
  //                   onClick={() => {
  //                     handleViewPhysio(element);
  //                   }}
  //                 />
  //               </Tooltip>
  //             </Group>
  //           </Table.Td>
  //         </Table.Tr>
  //       );
  //     });
  //     setRows(rowss)
  //     setElementsDoctor(apicall.data)
  //   }
  //   else{

  //   }
  // }
  // useEffect(() => {
  //   FetchPhysioList()
  //   setBreadCampList([
  //     {
  //       title: "Doctor List",
  //       route: coPhysioLanding,
  //     },
  //   ]);
  // }, []);

  const handleClick = (e) => {
    navigate(`/CoordinatorDashboard/physio-list/${e._id}`);
  };
  // const handleViewPhysio = (e) => {
  //   setViewPhysio(true);
  //   navigate(`${e.id}/assigned-physio-list`);
  // };
  const rowss = elementsDoctor.map((element, idx) => {
    return (
      <Table.Tr key={element.email}>
        <Table.Td>{idx + 1}</Table.Td>
        <Table.Td> {element.name}</Table.Td>
        <Table.Td>{element.email}</Table.Td>
        <Table.Td style={{ margin: "20px" }}>
          <Group>
            <Tooltip label="Assign Physio">
              <IconCirclePlus
                onClick={() => handleClick(element)}
                size={15}
                color="#90EE90"
              />
            </Tooltip>

            <Tooltip label="View Physio">
              <IconEye
                size={15}
                color="#90EE90"
                onClick={() => {
                  handleViewPhysio(element);
                }}
              />
            </Tooltip>
          </Group>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <BreadCamp />
      <Space h={"md"} />
      <Box
        style={{
          height: "70vh",
          overflow: "auto",
          margin: "20px",
        }}
      >
        <Table
          style={{ width: "100%" }}
          striped
          withColumnBorders
          stripedColor={"#00526A"}
          stickyHeader
          stickyHeaderOffset={-1}
        >
          <Table.Thead style={{ background: "black" }}>
            <Table.Tr>
              {tableHead.map((items) => {
                return (
                  <Table.Th c={"white"} key={items}>
                    {items}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          {rows ?
          <Table.Tbody style={{ overflowY: "auto" }}>{rows}</Table.Tbody>
          : 
          <>
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          <Skeleton height={8} mt={6} width="515%" radius="xl" />
          </>
        }
        </Table>
      </Box>
    </>
  );
};

export default CoordinatorPhysioDoc;

CoordinatorPhysioDoc.propTypes = {
  tableHead: propTypes.array.isRequired,
};
