import {useContext,useEffect} from 'react';
import { Center, Container } from "@mantine/core";
import ImageBody from "../components/ImageAI/ImageBody";
import PatientHeader from "../components/ImageAI/PatientHeader";
import {BreadCampContext} from '../context/BreadCampContext';

const ImageAIPage = () => {
  const { setBreadCampList} = useContext(BreadCampContext);
  useEffect(()=>{
    setBreadCampList([
      { title: "Patient Guidance", route: "/patientDashboard/guidance" },
      { title: "ImageAI", },
    ])
  },[])
  return (
    <Container fluid px={"sm"}>
      {/* <PatientHeader data="Image AI"> */}
        <Center>
          <ImageBody />
        </Center>
      {/* </PatientHeader> */}
    </Container>
  );
};

export default ImageAIPage;
