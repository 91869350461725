import { useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  rem,
} from "@mantine/core";
import { IconCalendarStats, IconChevronRight } from "@tabler/icons-react";
import classes from "./NavbarLinksGroup.module.scss";
import { useNavigate } from "react-router-dom";
// interface LinksGroupProps {
//   icon: React.FC<any>;
//   label: string;
//   initiallyOpened?: boolean;
//   links?: { label: string; link: string }[];
// }

export function LinksGroup({
  icon: Icon,
  label,
  route,
  initiallyOpened,
  links,
  location,
}) {
  const navigate = useNavigate();
  // console.log(location)
  // console.log(links)
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const items = (hasLinks ? links : []).map((link) => (
    <Text
      component="a"
      href={link.link}
      key={link.label}
      onClick={(event) => {
        event.preventDefault(), navigate(link.route);
      }}
      className={`${classes.link} ${
        location.pathname === `${link.route}`
          ? `${classes.textroute} ${classes.activeroute}`
          : ""
      }`}
    >
      {link.label}
    </Text>
  ));

  return (
    <>
      <UnstyledButton
        onClick={() => {
          setOpened((o) => !o);
        }}
        className={`${classes.control} ${
          location.pathname === `${route}` ? `${classes.activeroute}` : ""
        }`}
      >
        <Group justify="space-between" gap={0}>
          {hasLinks ? (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <ThemeIcon variant="light" size={30}>
                <Icon
                  // className={`${location.pathname===`${route}`? `${classes.textroute}`:""}`}
                  style={{ width: rem(18), height: rem(18), color: "#B3F9FF" }}
                />
              </ThemeIcon>
              <Box
                ml="md"
                // className={`${location.pathname===`${route}`? `${classes.textroute}`:""}`}
                style={{ color: "#B3F9FF" }}
              >
                {label}
              </Box>
            </Box>
          ) : (
            <Box
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => {
                navigate(route);
              }}
            >
              <ThemeIcon variant="light" size={30}>
                <Icon
                  className={`${
                    location.pathname === `${route}`
                      ? `${classes.textroute}`
                      : ""
                  }`}
                  style={{ width: rem(18), height: rem(18), color: "#B3F9FF" }}
                />
              </ThemeIcon>
              <Box
                ml="md"
                className={`${
                  location.pathname === `${route}` ? `${classes.textroute}` : ""
                }`}
                style={{ color: "#B3F9FF" }}
              >
                {label}
              </Box>
            </Box>
          )}
          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              stroke={1.5}
              style={{
                width: rem(16),
                height: rem(16),
                transform: opened ? "rotate(-90deg)" : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

const mockdata = {
  label: "Releases",
  icon: IconCalendarStats,
  links: [
    { label: "Upcoming releases", link: "/" },
    { label: "Previous releases", link: "/" },
    { label: "Releases schedule", link: "/" },
  ],
};

// export function NavbarLinksGroup() {
//   return (
//     <Box mih={220} p="md">
//       <LinksGroup {...mockdata} />
//     </Box>
//   );
// }
