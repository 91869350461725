import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Container,
  Grid,
  Text,
  Badge,
  Card,
  Modal, Loader,
  Center,
  Image, SimpleGrid
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Group, Title } from "@mantine/core";
import patientService from '../../services/patientService';
import loginService from '../../services/loginService';
import { HeaderMenu } from '../../components/Header/HeaderMenu';
import { BreadCampContext } from '../../context/BreadCampContext';
import HookSteper from '../PatientDashBoard/Hooks/HookSteper';
import { BASEURL } from '../../constants/constants';
const PatientExercises = () => {
  const { setBreadCampList } = useContext(BreadCampContext);
  const { postSurgery } = HookSteper()
  const navigate = useNavigate()
  const [opened, { open, close }] = useDisclosure(false);
  const [modelSrc, setModelSrc] = useState('')
  const [modelName, setModelNmae] = useState('')
  const [loading, setLOading] = useState(true)
  const [configData, setConfig] = useState([]);
  const exerciseMediaMapping = {
    "Ankle Pumps": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Ankle-Pumps.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Ankle-Pumps.mp4"
    },
    "Bridging": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/bridging.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Bridging.mp4"
    },
    "Chair Pushups": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Chair-Ups.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Chair-Push-Ups.mp4"
    },
    "Gluteal Squeezes": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Gluteal-Squeezes.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Gluteal-Squeezes.mp4"
    },
    "Hamstring Set": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Hamstring-set.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Hamstring-Set.mp4"
    },
    "Heel Slides": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Heel-slides.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Heel-Slides.mp4"
    },
    "Hip Abduction": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Hip-abduction.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Hip-Abduction.mp4"
    },
    "Knee Extension": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Knee-extension.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Knee-Extension.mp4"
    },
    "Long arc Quads": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Long-arc-quads.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Long_Arc_Quads.mp4"
    },
    "Quad Sets": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Quad-sets.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Quad-Sets.mp4"
    },
    "Sitting Knee Extension": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Sitting-knee-extension.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Sitting-Knee-Extension.mp4"
    },
    "Sitting knee Flexion": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Sitting-knee-flexion.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Sitting-Knee-Flexion.mp4"
    },
    "Straight leg raises": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Straight-leg-raises.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Straight_Leg_Raises.mp4"
    },
    "Terminal knee extension": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Terminal-knee-extension.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Terminal-Knee-Extension.mp4"
    },
    "Toe up": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Toe-up.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Toe-up.mp4"
    },
    "Knee Slides": {
      imagePath: "https://storage.googleapis.com/exercise-repo/exercise-images/Knee-slides.png",
      videoPath: "https://storage.googleapis.com/exercise-repo/exercise-video/Knee-Slides.mp4"
    },

  };
  useEffect(() => {
    setBreadCampList([
      { title: "Patient Guidance", route: "/patientDashboard/guidance" },
      { title: "Exercise" },
    ])
    getPatientExercise();
  }, []);


  const getPatientExercise = async () => {
    try {
      let configData = await patientService.getExerciseByPatient();

      console.log("get exercise callled config", configData.data);
      setConfig(configData.data);
      setLOading(false)

    } catch (err) {
      setLOading(false)
      console.log(err);
      if (err?.response?.status && err?.response?.status === 401) {
        const path = loginService.checkRoute();
        navigate(path, { replace: true });
      }
    }
  }
  return (
    <>
      <Center m={10} >

        <Container w={1000}>
          <Grid>
              <Grid.Col >
                <Group justify='right'>
                  {/* <Button variant="gradient"
                    gradient={{ from: 'cyan', to: 'indigo', deg: 90 }} fw={600} onClick={() => { navigate(postSurgery[4].route) }}>Skip Step</Button> */}
                  <Button variant="gradient"
                    gradient={{ from: 'cyan', to: 'indigo', deg: 90 }} fw={600} onClick={() => { navigate(postSurgery[4].route) }}>Next Step</Button>
                </Group>
                {configData.length>0 ?
                <Center m={2}> <Title order={3} c={"#ffff"}>Exercises recommended by your Doctor</Title></Center>
                :""}
              </Grid.Col>
          </Grid>
          {loading === false ?
            <Grid gutter="md">
              {configData.length > 0 ?
                <SimpleGrid cols={{ base: 1, sm: 2, md: 3, lg: 3 }}>
                  {configData.map((exercise, idx) => {
                    return (
                      <Center>
                        <Card
                          p={10}
                          m={10}
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                          style={{ width: "100%", backgroundColor: "#011D2c" }}
                        >
                          <Card.Section>
                            <Image
                              //  src={`${BASEURL}/showimage/images/${exercise?.exerciseImagePath}`}
                              src={exerciseMediaMapping[exercise.exerciseName]?.imagePath}
                              height={"100%"}
                              alt={exercise.exerciseName}
                            />
                          </Card.Section>
                          <Group justify="space-between" mt="md" mb="xs">
                            <Text color="#fff" fw={500}>
                              {exercise.exerciseName}
                            </Text>
                            <Badge color="pink">New</Badge>
                          </Group>

                          <Text size="sm" c="dimmed">
                            Keep Doing this exercise to increase range of motion, flexibility, and to reduce pain
                          </Text>

                          <Button
                            variant="gradient"
                            gradient={{ from: 'cyan', to: 'indigo', deg: 90 }}
                            onClick={() => {
                              open(exercise),
                                // setModelSrc(exercise.exerciseVideoPath), 
                                setModelSrc(exerciseMediaMapping[exercise.exerciseName]?.videoPath),
                                setModelNmae(exercise.exerciseName)
                            }}
                            // onClick={() => { open(exercise), setModelSrc(exercise.exerciseVideoPath), setModelNmae(exercise.exerciseName) }} 
                            color="blue" fullWidth mt="md" radius="md">
                            Click to see Demonstration
                          </Button>
                        </Card>
                      </Center>
                    )
                  })}
                </SimpleGrid>
                :
                <>
                  <Center style={{ width: '100%', minHeight: '30vh' }}>
                    <Group>
                    <Text size='xl' fz={25} fw={600} variant="gradient"
                        gradient={{ from: 'indigo', to: 'red', deg: 289 }}>Exercise Data Not Found!  </Text>
      
                      <Text size='xl' fz={25} fw={600} variant="gradient"
                        gradient={{ from: 'indigo', to: 'red', deg: 289 }}>Consult Your Doctor!</Text>
                    </Group>
                  </Center>

                </>
              }

            </Grid>
            :
            <Grid>
              <Center style={{ width: '100%', minHeight: '30vh' }}>
                <Loader size={30} />;
              </Center>
            </Grid>
          }
        </Container>

      </Center>


      <Modal opened={opened} onClose={close} title={modelName} centered>
        {/* <video src={`${BASEURL}/showimage/videos/${modelSrc}`} height={220} alt="hi" controls /> */}
        <video src={modelSrc} controls />
      </Modal>

    </>
  )
}

export default PatientExercises;